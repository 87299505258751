import { FieldArray, Form, Formik } from 'formik';
import { Fragment, useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../../../SharedComponents/Notification';
import ChevronRight from '../../../../SharedComponents/Svg/ChevronRight';
import DeleteSection from '../DeleteSection';
import ToggleSectionStatus from '../ToggleSectionStatus';
import { editCollapse, addCollapseItems } from './ApiCollapse';
import CollapseItem from './CollapseItem';
import AddCollapseItem from './AddCollapseItem';
import EditSection from '../EditSection';
import { useTranslation } from 'react-i18next';
import DotsVertical from '../../../../SharedComponents/Svg/DotsVertical';
import SectionHeader from '../SectionHeader';
import LanguageList from '../../../../SharedComponents/LanguageList';
export default function Collapse(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { section, languages, provided } = props;
  const [languageId, setLanguageId] = useState(1);
  const queryClient = useQueryClient();
  const { pgSectionCollapse } = section;

  const { t, i18n } = useTranslation();
  const pgSectionCollapseLng = languages.data.data.map((language) => ({
    languageId: language.id,
  }));
  const addCollapseItemsMutation = useMutation(addCollapseItems, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.resetQueries(['sections']);
      }
    },
  });
  const editCollapseMutation = useMutation(editCollapse, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.resetQueries(['sections']);
      }
    },
  });
  useEffect(() => {
    setLanguageId(languages?.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  const onSaveSettings = async (values) => {
    try {
      await editCollapseMutation.mutate(values.pgSectionCard);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      {editCollapseMutation.isSuccess && <Notification message={t('Section Updated')} />}
      {editCollapseMutation.isError && <Notification error={true} message={t('Network Error')} />}
      <div className="block bg-white w-full shadow border mb-4">
        <SectionHeader
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section={section}
          languageId={languageId}
          provided={provided}
        />
        <Formik initialValues={section}>
          {({ values, setFieldValue, setValues }) => {
            return (
              <>
                <div className={`bg-white px-4  overflow-hidden ${isOpen ? 'py-3 h-full  ' : '  py-0 h-0'}`}>
                  <div className="">
                    <div className="flex justify-between relative items-center">
                      <div className="flex transform -translate-y-1 relative z-10">
                        {t('Section Type')} : {t('Collapse')}
                      </div>
                      <div>
                        <div>
                          <LanguageList
                            languages={languages.data?.data}
                            languageId={languageId}
                            setLanguageId={setLanguageId}
                            className="flex justify-end transform translate-y-1 relative z-10 mb-4"
                          />
                        </div>
                      </div>
                      <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />
                    </div>
                  </div>

                  <div className="">
                    {/* {values.pgSectionCollapse.pgSectionCollapseItems.map(() => (
                      <CollapseItem />
                    ))} */}
                    {/* {values &&
                      values?.pgSectionCollapse?.pgSectionCollapseItems &&
                      values?.pgSectionCollapse.pgSectionCollapseItems.map((pgSectionCollapseItem, index) => (
                        <FieldArray
                          key={index}
                          name="pgSectionCollapse.pgSectionCollapseItems"
                          render={(arrayHelpers) => (
                            <>
                              <CollapseItem
                                setFieldValue={setFieldValue}
                                languageId={languageId}
                                pgSectionCollapseItem={pgSectionCollapseItem}
                                pgSectionCollapseItemIndex={index}
                                pgSectionCollapseItemsHelper={arrayHelpers}
                              />
                            </>
                          )}
                        /> 
                      ))} */}

                    <FieldArray
                      name="pgSectionCollapse.pgSectionCollapseItems"
                      render={(arrayHelpers) => (
                        <>
                          {values.pgSectionCollapse.pgSectionCollapseItems.map((pgSectionCollapseItem, index) => {
                            return (
                              <Fragment key={index}>
                                <>
                                  <CollapseItem
                                    languageId={languageId}
                                    languages={languages}
                                    pgSectionCollapseItem={pgSectionCollapseItem}
                                    setFieldValue={setFieldValue}
                                    pgSectionCollapseItemIndex={index}
                                    pgSectionCollapseItemsHelper={arrayHelpers}
                                  />
                                </>
                              </Fragment>
                            );
                          })}
                        </>
                      )}
                    />
                    {/* {console.log('xxx', section.pgSectionCollapseId)} */}
                    <AddCollapseItem
                      pgSectionCollapseId={values.pgSectionCollapseId}
                      addCollapseItemsMutation={addCollapseItemsMutation}
                      orderNum={values.pgSectionCollapse.pgSectionCollapseItems.length}
                    />

                    {/* <AddCardItem pgSectionCollapseId={values.pgSectionCardId} addCardItemMutation={addCardItemMutation} /> */}
                    {/* <button
                      type="button"
                      onClick={() => {
                        onSubmit(values);
                      }}
                      className="bg-lightgreen py-2 px-2 flex justify-center text-center h-full text-sm font-bold text-white items-center hover:bg-lightgreenhover text-centerhover shadow  rounded transition duration-300 ease-in-out"
                    >
                      Save Section
                    </button> */}
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
        {addCollapseItemsMutation.isSuccess && <Notification message="Collapse Item Added" />}
      </div>
    </>
  );
}
