import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import LanguageList from '../../SharedComponents/LanguageList';
import Loading from '../../SharedComponents/Loading';
import Notification from '../../SharedComponents/Notification';
import LoadingCircle from '../../SharedComponents/Svg/LoadingCircle';
import { AddAnswerList, GetAllForms, getLanguages, GetRequestId } from './ApRequests';
import FormContainer from './FormContainer';

export default function CreateRequest() {
  const { status, purposeOfRequest } = useParams();
  const formID = +status === 0 ? 1017 : 1;
  const { t } = useTranslation();
  const [val, setVal] = useState({});
  const history = useHistory();
  const [languageId, setLanguageId] = useState(1);
  const body = {
    formId: formID,
  };
  const { data, isLoading, isError } = useQuery(['GetAllForms', body], GetAllForms);
  const getRequestIdMutation = useMutation(GetRequestId, {
    onSuccess: (data) => {
      addAnswerList(data.data.outId);
    },
  });
  const addAnswerListMutation = useMutation(AddAnswerList);

  const inputs = {
    1: 'text',
    2: 'radio',
    3: 'checkbox',
    4: 'file',
    5: 'tel',
    6: 'url',
    7: 'number',
    8: 'select',
    9: 'textarea',
  };

  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  if (isLoading)
    return (
      <div
        className="relative bg-white"
        style={{
          minHeight: '500px',
        }}
      >
        <Loading />
      </div>
    );
  if (isError) return 'error';

  const getInitialValues = () => {
    const arrayOfKeys = data.data.map((item) => item.inputName);
    const object = {};

    for (let index = 0; index < arrayOfKeys.length; index++) {
      const key = arrayOfKeys[index];
      object[key] = '';
    }
    return object;
  };

  const getDateHandler = (date, time) => {
    const day = date ? new Date(date) : new Date();
    return `${day.getFullYear()}-${day.getMonth() + 1}-${day.getDate()}`;
  };

  const initialValues = getInitialValues();
  const addAnswerList = (outId) => {
    const userData = localStorage.getItem('token');
    const userId = userData ? JSON.parse(userData).userId : 0;
    const entries = Object.entries(val);
    const newArr = entries?.map((e) => e);
    const newData = newArr.map((x, index) => {
      const questionId = data.data.find((e) => e.inputName === `${x[0]}`).id;
      let l = {
        questionId: questionId,
        answer: x[1],
        userId: userId,
        formId: formID,
        creationDateString: getDateHandler(),
        createDate: new Date(),
        requestId: outId,
        request: {
          requestStatus: +status,
        },
      };
      return l;
    });
    console.log(newData, 'newData');
    addAnswerListMutation.mutate(newData);
  };

  const SubmitHandler = (values) => {
    console.log('values', values);
    setVal(values);
    setTimeout(() => {
      getRequestIdMutation.mutate();
    }, 100);
  };

  const steps = [
    { id: 1, title: 'Add Data' },
    { id: 2, title: 'Generate user name' },
    { id: 3, title: 'Identical Card' },
  ];
  return (
    <>
      {addAnswerListMutation.isSuccess && <Notification message={t('Request Created Successfully')} />}
      <header className="flex justify-between items-center">
        <BreadCrumbs to="/requests/0" name={t('Create Requests')} />
      </header>
      <div className="bg-white mt-4 pt-4 px-6 flex justify-between border-b">
        <h2 className="text-xl font-bold px-4 ">{t('ADD NEW VISITOR')}</h2>
        <div>
          <div className="">
            <div>
              {languages.data && languages.data?.data && (
                <LanguageList
                  className="flex justify-end transform translate-y-1 relative z-10 mb-1 "
                  languages={languages.data?.data}
                  languageId={languageId}
                  setLanguageId={setLanguageId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-4">
        <div className="flex space-x-12 mx-6 border-b">
          {steps.map((step, index) => {
            return (
              <div className={`flex  relative py-4 px-2`}>
                <span
                  className={` ${
                    step.id <= 1 ? 'bg-move text-white' : 'text-gray-400'
                  } text-xl rounded  w-8 h-8 flex items-center justify-center`}
                >
                  {step.id}
                </span>
                <span className={`text-lg px-2 ${step.id <= 1 ? 'text-move' : 'text-gray-400'} `}>{t(step.title)}</span>
                {}
                <hr
                  className={`absolute bottom-0 left-0 w-full  border ${step.id <= 1 ? 'border-darkblue' : 'hidden'} `}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div className="bg-white ">
        <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />

        <FormContainer languageId={languageId} formID={1017} requestStatus={0} purposeOfRequest={purposeOfRequest} />
      </div>
    </>
  );
}
