import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../SharedComponents/Modal';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../SharedComponents/Loading';
import { addFaq, addFormRole, getLanguages } from './ApiRol';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../SharedComponents/LanguageList';
import i18next from 'i18next';
import FormsList from './FormsList';
import RolesList from './RolesList';

export default function AddFormRole(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props.id ? props : 1;
  const { typeId } = props;
  const { t, i18n } = useTranslation();
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`bg-darkblue space-x-1 ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } text-sm font-bold text-white flex items-center hover:bg-darkbluehover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <PlusCircle className="w-5 h-5" /> <span>{t('Add Form Role')}</span>
      </button>

      {isOpen && <ModalContainer id={id} typeId={typeId} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, id, typeId } = props;
  const [languageId, setLanguageId] = useState(1);
  const [uploadSucess, setUploadSucess] = useState(false);
  const { t } = useTranslation();
  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [data]);

  // create a loop for localisation
  const faqLngs = data?.data?.map((language) => ({
    languageId: language.id,
    question: '',
    answer: '',
  }));
  const initialValues = {
    create: true,
    update: true,
    get: true,
    delete: true,
    frmFormId: 0,
    roleName: '',
  };
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const mutation = useMutation(addFormRole, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['formRoles']);

        setIsOpen(false);
      }
    },
  });

  const onSubmit = (e) => {
    try {
      mutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {data && data.data && (
        <Formik onSubmit={onSubmit} validateOnBlur={false} validateOnChange={false} initialValues={initialValues}>
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Modal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onSubmit={onSubmit}
                  title={t('Create Faq')}
                  isLoading={mutation.isLoading}
                  add={true}
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}

                  <LanguageList
                    className="flex transform translate-y-1 relative z-10 mb-4"
                    languages={data?.data}
                    languageId={languageId}
                    setLanguageId={setLanguageId}
                  >
                    <hr className="border w-full absolute bottom-0 left-0 z-0" />
                  </LanguageList>

                  {/* Submit Form */}
                  <div className="mb-4">
                    {/* FormsList */}

                    <FormsList setFieldValue={setFieldValue} frmFormId={values.frmFormId} languageId={languageId} />
                  </div>
                  {/* Submit Form */}
                  <div className="mb-4">
                    {/* FormsList */}

                    <RolesList setFieldValue={setFieldValue} roleName={values.roleName} languageId={languageId} />
                  </div>
                  <div className="flex space-x-8 my-8">
                    <label
                      className={`flex items-center space-x-2 ${
                        i18next.language === 'en' ? '' : 'space-x-reverse'
                      } mb-2 text-sm font-bold text-gray-600`}
                    >
                      <Field type="checkbox" name="create" className="w-3 h-3" />
                      <span>{t('Create')}</span>
                    </label>
                    <label
                      className={`flex items-center space-x-2 ${
                        i18next.language === 'en' ? '' : 'space-x-reverse'
                      } mb-2 text-sm font-bold text-gray-600`}
                    >
                      <Field type="checkbox" name="update" className="w-3 h-3" />
                      <span>{t('update')}</span>
                    </label>
                    <label
                      className={`flex items-center space-x-2 ${
                        i18next.language === 'en' ? '' : 'space-x-reverse'
                      } mb-2 text-sm font-bold text-gray-600`}
                    >
                      <Field type="checkbox" name="get" className="w-3 h-3" />
                      <span>{t('get')}</span>
                    </label>
                    <label
                      className={`flex items-center space-x-2 ${
                        i18next.language === 'en' ? '' : 'space-x-reverse'
                      } mb-2 text-sm font-bold text-gray-600`}
                    >
                      <Field type="checkbox" name="delete" className="w-3 h-3" />
                      <span>{t('delete')}</span>
                    </label>
                  </div>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
