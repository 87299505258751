import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Loading from '../../../SharedComponents/Loading';
import Calender from '../../../SharedComponents/Svg/Calender';
import Eye from '../../../SharedComponents/Svg/Eye';
import PecnilAlt from '../../../SharedComponents/Svg/PencilAlt';
import Trash from '../../../SharedComponents/Svg/Trash';
import { deleteGalleryAlbumItem } from '../ApiGallery';
import EditGalleyImage from './EditGalleyImage';
import ViewGalleryImage from './ViewGalleryImage';
// import EditAsset from './EditAsset';
// import ViewAsset from './ViewAsset';

export default function GalleryBlock(props) {
  const { albumItem, languageId } = props;
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const deleteButton = useMutation(deleteGalleryAlbumItem, {
    onSuccess: () => {
      queryClient.invalidateQueries(['galleryAlbum']);
      queryClient.invalidateQueries(['galleryAlbums']);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    },
  });

  const onDelete = async (e) => {
    setLoading(true);
    try {
      deleteButton.mutate(albumItem.id);
    } catch (error) {
      console.log('error', error);
    }
  };

  if (loading)
    return (
      <div className="relative border">
        <Loading message="Deleting" />
      </div>
    );
  const regEx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;

  const url = albumItem.imageUrl.match(regEx);
  return (
    <>
      <div key={albumItem.id} className="border bg-gray-100 ">
        <div className="relative h-64 bg-white">
          {(albumItem.isVideo === 'true' || albumItem.isVideo === true) && url && (
            <iframe
              style={{ width: '100%' }}
              className="h-full"
              src={`https://www.youtube.com/embed/${url[1]}`}
              title="video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
          {(albumItem.isVideo === 'false' || albumItem.isVideo === false) && (
            <img
              className="object-cover h-full w-full"
              alt="alt"
              src={`${process.env.REACT_APP_IMAGE_PATH}${albumItem.imageUrl}`}
            />
          )}
          <div className="absolute bottom-0 left-0 w-full p-2">
            <div className="absolute z-0  bottom-0 left-0 w-full h-full bg-black opacity-60"></div>
            <div className={`flex justify-end  relative space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
              <EditGalleyImage albumItem={albumItem} />{' '}
              <ViewGalleryImage image={`${process.env.REACT_APP_IMAGE_PATH}${albumItem.imageUrl}`} />
              <button
                onClick={onDelete}
                className="bg-pink hover:bg-pinkhover transition duration-500 text-white rounded p-1"
              >
                <Trash className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
        {albumItem.glAlbumItemLngs.map((e) => {
          return (
            <Fragment key={e.id}>
              {languageId === e.languageId && (
                <div key={e.id} className="bg-gray-100 p-4">
                  <b className="text-xl block mb-1">{e.title}</b>
                  <small className="flex items-center text-gray-400  space-x-1 text-xs mb-2">
                    <Calender className="w-3 h-3" />
                    <span>15/12/2020 02.30 PM</span>
                  </small>
                  <span className="text-sm text-gray-500 block">{e.description}</span>
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    </>
  );
}
