import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Modal from '../../../SharedComponents/Modal';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import { addRole } from './ApiRoles';

export default function AddRole(props) {
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { mutate, data } = useMutation(addRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(['roles']);
      setIsOpen(false);
    },
  });
  const onSubmit = async (e) => {
    try {
      await mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={`bg-lightgreen space-x-1 ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <PlusCircle className="w-5 h-5" /> <span>{t('Add Role')}</span>
      </button>
      <Formik
        initialValues={{ name: '' }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          resetForm();
        }}
      >
        <Form>
          <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={t('Add Role')} add={true}>
            <label>
              <b className={`font-semibold block text-gray-900 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                {t('Name')}
              </b>
              <Field
                style={{ textIndent: '10px' }}
                placeholder={t('EnterName')}
                className="border rounded  w-full mb-8 p-1"
                name="name"
                type="text"
              />
            </label>
          </Modal>
        </Form>
      </Formik>
    </>
  );
}
