import axios from 'axios';

export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;

  return { data, isDone, paginationResult };
};

export const addLanguage = async (e) => {
  const { data } = await axios.post('/Language/Add', e);
  return data;
};

export const editLanguage = async (e) => {
  const { data } = await axios.post('/Language/Edit', e);
  return data;
};
export const deleteLanguage = async (id) => {
  const mutation = await axios.post(`/Language/Delete?Id=${id}`);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};
