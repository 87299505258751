import { useQuery } from 'react-query';
import { useState, useEffect } from 'react';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import PecnilAlt from '../../SharedComponents/Svg/PencilAlt';
import Loading from '../../SharedComponents/Loading';
import { getLanguages, getPolls } from './ApiPolls';
import { DateTime } from 'luxon';
import TogglePollStatus from './TogglePollStatus';
import DeletePoll from './DeletePoll';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import TablePagination from '../../SharedComponents/TablePagination';
import TableSearch from '../../SharedComponents/TableSearch';
import LanguageList from '../../SharedComponents/LanguageList';

export default function Polls() {
  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [languageId, setLanguageId] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLanguage, setSearchlanguage] = useState(0);
  const { t, i18n } = useTranslation();
  const onSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    setSearchlanguage(languageId);
  };

  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  let initialState = {
    pageNumber: pageNumber,
    pageSize: entries,
    isPaging: true,
    langId: 0,
    isSearch: searchTerm ? true : false,
    searchFilter: {
      langId: searchLanguage,
      term: searchTerm,
    },
  };
  const { data, isLoading, isError } = useQuery(['polls', initialState], getPolls, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      pagination(data);
    },
  });
  const [paginationCount, setPaginationCount] = useState([]);

  function pagination(data) {
    let paginationCount = [];
    for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }

  // to control how many rows to show in table
  const onChangeEntries = (e) => {
    setEntries(e.target.value);
  };

  const tabltHeader = [
    { title: 'Title' },
    { title: 'Creation Date' },
    { title: 'Deadline' },
    { title: 'Questions' },
    { title: 'Answers' },
    { title: 'Users' },
    { title: 'Active' },
    { title: 'Optioms', className: 'w-64' },
  ];

  return (
    <isLoading>
      <header className="flex justify-between items-center">
        <BreadCrumbs to="/polls" name={t('Polls')} />

        {/* <div className="flex items-center bg-white rounded border shadow">
          <div className="pl-4 pr-12 py-4 relative overflow-hidden">
            <a href="/">
              <Home />
            </a>
            <div className="text-gray-100">
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform -rotate-45 right-0 bottom-0 mb-3" />
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform rotate-45 right-0 top-0 mt-3" />
            </div>
          </div>
          <div className="pl-4 pr-12 py-4 relative overflow-hidden">
            <a href="/dashboard">Dashboard</a>
            <div className="text-gray-100">
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform -rotate-45 right-0 bottom-0 mb-3" />
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform rotate-45 right-0 top-0 mt-3" />
            </div>
          </div>

          <div className="pl-4 pr-12 py-4">
            <a>Polls</a>
          </div>
        </div> */}
        <div>
          {/* <AddUser /> */}
          <Link
            to={`./polls/create`}
            className="bg-lightgreen space-x-1 text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out"
          >
            <PlusCircle className="w-5 h-5" /> <span>{t('Create Poll')}</span>
          </Link>
        </div>
      </header>
      <div className="my-6">
        <h2 className="text-2xl font-bold">{t('Polls')}</h2>
      </div>
      {/* Table */}
      <div className="bg-white rounded border shadow-md">
        <div className="flex justify-between relative items-center px-6 py-6">
          <TableSearch onChangeEntries={onChangeEntries} entries={entries} onSearch={onSearch} />
          <div className="flex transform  relative z-10 ">
            {data && languages && (
              <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
            )}
          </div>{' '}
          <hr className="border w-full absolute top-0 left-0 z-0 mt-16" />
        </div>
        {isLoading && (
          <div className="relative bg-white h-72">
            <Loading />
          </div>
        )}
        {isError && 'loading'}
        {data && (
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y-2 divide-gray-200">
                    <thead className=" text-sm font-bold text-black">
                      <tr>
                        {tabltHeader.map((e) => {
                          return (
                            <th
                              key={e.title}
                              scope="col"
                              className={`px-6 py-3 ${
                                i18n.language === 'en' ? 'text-left' : 'text-right'
                              }   uppercase  ${e.className}`}
                            >
                              {t(e.title)}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
                      {data &&
                        data?.data &&
                        data?.data?.map((poll) => {
                          return (
                            <tr key={poll.id} className="">
                              <td className="px-6 py-4 whitespace-nowrap">
                                {poll.pollLngs.map((x) => {
                                  return <span key={x.id}>{languageId === x.languageId && <> {x.title} </>}</span>;
                                })}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className=" ">{DateTime.fromISO(poll.creationDate).toLocaleString('en-GB')}</div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className=" ">{DateTime.fromISO(poll.deadLine).toLocaleString('en-GB')}</div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div>
                                  <span className="bg-yellow-100 text-yellow-500 px-2 py-1 rounded">
                                    {poll.questionsCount}
                                  </span>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div>
                                  <span className="">{poll.answersCount}</span>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div>
                                  <span className="">{poll.isForAllUsers ? 'For All' : 'Members'}</span>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div>
                                  <span className="">
                                    <TogglePollStatus pollStatus={poll.isActive} id={poll.id} />
                                  </span>
                                </div>
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap flex items-center space-x-2 ${
                                  i18n.language === 'en' ? '' : 'space-x-reverse'
                                }`}
                              >
                                {poll.isActive === false && (
                                  <>
                                    <a
                                      href={`./polls/${poll.id}`}
                                      className="bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out"
                                    >
                                      <PecnilAlt className="w-5 h-5" />
                                    </a>
                                    <DeletePoll id={poll.id} />
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  {/* pagination */}
                  <TablePagination
                    data={data}
                    paginationCount={paginationCount}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </isLoading>
  );
}
