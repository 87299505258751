import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../SharedComponents/Modal';
import Eye from '../../../SharedComponents/Svg/Eye';

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, video } = props;
  // regEx to Filter Youtube Url
  const regEx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const url = video.match(regEx);
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={t('View Asset')} add={true} hideSubmit={true}>
      <div className="text-center">
        <iframe
          style={{ width: '100%' }}
          height="315"
          src={video}
          title="video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </Modal>
  );
};

export default function ViewVimeo(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { video } = props;
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-orange hover:bg-orangehover transition duration-500 text-white rounded p-1"
      >
        <Eye className="w-5 h-5" />
      </button>

      {isOpen && <ModalContainer video={video} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}
