import axios from 'axios';
import { useQuery } from 'react-query';
export default function NotificationsCount(props) {
  const { data, isloading, isError } = useQuery(['GetNotificationsCount', { id: 1 }], GetNotificationsCount);
  if (isloading) return '';
  if (isError) return '';
  return (
    <>
      {data?.data > 0 && (
        <span
          style={{
            fontSize: 8,
          }}
          className={` absolute top-0 left-0 -mt-2 -ml-2 bg-red-500 text-white text-xs w-4  h-4 flex items-center justify-center rounded-full  `}
        >
          {data.data}
        </span>
      )}
    </>
  );
}

export const GetNotificationsCount = async (e) => {
  const { id } = e.queryKey[1];
  const { data } = await axios.get(`/Notifications/GetNotificationsCount?id=${id}`);
  return data;
};
