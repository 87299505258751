// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Roles: 'صلاحيات',
  'User Role': 'صلاحية المستخدم',
  Deactivate: 'تعطيل',
  'Deactivate account': 'تعطيل الحساب',
  'Add Role': 'إضافة قاعده',
  'Are you sure you want to deactivate account? All of your data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد من أنك تريد إلغاء تنشيط الحساب ؟ سيتم إزالت جميع بياناتك بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Edit Role Name': 'تحرير اسم الصلاحية',
  'Edit Role': 'تحرير الصلاحية',
  Permissions: 'أذونات',
  'Search here': 'ابحث هنا',
  'Current Permissions': 'الأذونات الحالية',
  'All Permissions': 'جميع الأذونات',
};
