import axios from 'axios';

export const GetAllEmployee = async (e) => {
  const { data } = await axios.post('/UserAccount/GetAllEmployee', e.queryKey[1]);
  return data;
};

export const AddEmployee = async (e) => {
  const { data } = await axios.post('/UserAccount/AddEmployee', e);
  return data;
};
