import React from 'react';
import { useTranslation } from 'react-i18next';
export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  // ${document.dir === 'ltr' ? 'space-x-4' : ' -space-x-4'} flex items-center
  return (
    <div
      className={`${
        i18n.language === 'en' ? '' : 'space-x-reverse'
      } bg-gray-100 text-xs text-black space-x-2 rounded-full flex p-1`}
    >
      <span
        onClick={() => {
          i18n.changeLanguage('en');
          localStorage.setItem('lang', 'en');
        }}
        className={`${
          i18n.language === 'en' ? 'bg-pink cursor-pointer text-white ' : 'bg-gray-300 cursor-pointer text-black '
        } rounded-full p-1`}
      >
        EN
      </span>
      <span
        onClick={() => {
          i18n.changeLanguage('ar');
          localStorage.setItem('lang', 'ar');
        }}
        className={`${
          i18n.language !== 'en' ? 'bg-pink cursor-pointer text-white ' : 'bg-gray-300 cursor-pointer text-black '
        } rounded-full p-1`}
      >
        AR
      </span>
    </div>
  );
}
