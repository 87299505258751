import axios from 'axios';

export const GetFormAnswer = async ({ queryKey }) => {
  const { data } = await axios.get(`/FrmAnswer/GetAnswersByFormIdWithSubmit?formId=${queryKey[1]}`);
  return data;
};

export const getFormQuestions = async (e) => {
  const query = await axios.post('/FrmQuestion/GetAllBy', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

export const getUserInfo = async (id) => {
  const query = await axios.post(`/UserAccount/GetUser?id=${id}`);
  return query.data.data;
};
