import React from 'react';
import { Field } from 'formik';
import { useQuery } from 'react-query';
// import { useLanguage } from '../../../../context/language';
import { getFormQuestionOption } from '..//../../Views/Forms/ApiForms';
import FormError from '../FormError';

export default function RadioButton(props) {
  const { item, errors, languageId } = props;

  let initialState = {
    typeId: item.qoptionTypeId,
    paginationFilter: {
      pageNumber: 1,
      pageSize: 99999,
      isPaging: false,
      langId: 0,
    },
  };

  const { data, paginationResult, isDone, isLoading, isError, refetch } = useQuery(
    ['formQuestionOption', initialState],
    getFormQuestionOption,
    {
      staleTime: 99900,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );
  return (
    <div key={item.id} className="mb-3 w-full">
      <label htmlFor={item.inputName} className="text-[#687068] block mb-5">
        {item.frmQuestionLngs.filter((item) => item.languageId === languageId)[0]?.title}
        <div className="py-4 w-full bg-white rounded-md outline-none flex space-x-2">
          {data?.data?.map((option) => (
            <div key={option.id} className="flex items-center space-x-2 ">
              <Field
                type="radio"
                name={item.inputName}
                value={option.frmQoptionLngs.filter((item) => item.languageId === languageId)[0]?.title}
              />
              <span>{option.frmQoptionLngs.filter((item) => item.languageId === languageId)[0]?.title}</span>
            </div>
          ))}
        </div>
        <FormError name={item?.inputName} errors={errors} />

        {/* <Field
          required
          className="border-[#f9c7e1] px-3 py-4 w-full bg-white border rounded-md outline-none"
          as="select"
         
          // defaultValue={data?.data?.option?.frmQoptionLngs[0].title}
          // onChange={(e) => {
          //   setFieldValue(`{item.inputName}`, parseInt(e.target.value))
          //   onCategoryChange(e)
          // }}
        >
          <>
            <option disabled selected>
              {item.frmQuestionLngs[0].title}
            </option>
         
          </>
        </Field> */}
      </label>
    </div>
  );
}
