import React from 'react';
import { Field } from 'formik';
import { useQuery } from 'react-query';
// import { useLanguage } from '../../../../context/language';
import { getFormQuestionOption } from '..//../../Views/Forms/ApiForms';
import { useParams } from 'react-router';
import FormDropDown from './FormDropDown';

export default function Select(props) {
  const { item, values, errors, setFieldValue, languageId } = props;
  const params = useParams();
  //   const { defaultLang } = useLanguage();
  //   const initialValues = {
  //     typeId: item.qoptionTypeId,
  //     paginationFilter: { pageNumber: 1, pageSize: 10, isPaging: true, langId:defaultLang },
  //   }
  //   const { data, isLoading } = useQuery(['questionOptions', initialValues], getFormQuestionOptions)
  let initialState = {
    typeId: item.qoptionTypeId,
    paginationFilter: {
      pageNumber: 1,
      pageSize: 99999,
      isPaging: false,
      langId: 0,
    },
  };

  const { data, paginationResult, isDone, isLoading, isError, refetch } = useQuery(
    ['formQuestionOption', initialState],
    getFormQuestionOption,
    {
      staleTime: 99900,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );
  if (isLoading) return 'loading';
  if (isError) return 'error';
  return (
    <>
      {/* <div key={item.id} className="mb-3 w-full">
        <label htmlFor={item.inputName} className="text-[#687068] block mb-5">
          {item.frmQuestionLngs[0].title}
          <Field
            required
            className="border-[#f9c7e1] px-3 py-4 w-full bg-white border rounded-md outline-none"
            as="select"
            name={item.inputName}
         
          >
            <>
              <option disabled selected>
                {item.frmQuestionLngs[0].title}
              </option>
              {data?.data?.map((option) => (
                <option value={option.frmQoptionLngs[0].title} key={option.id}>
                  {option.frmQoptionLngs[0].title}
                </option>
              ))}
            </>
          </Field>
        </label>
      </div> */}
      {/* {console.log(item.frmQuestionLngs.reduce((e) => languageId === e.languageId && e.title))} */}
      {/* {item.frmQuestionLngs.filter((e) => languageId === e.languageId && e.title )} */}
      <FormDropDown
        list={data?.data}
        title={item.frmQuestionLngs.filter((item) => item.languageId === languageId)[0]?.title}
        placeholder="Select Employee status"
        FieldName={item.inputName}
        disabled={true}
        values={values}
        FieldValue={values[item.inputName]}
        errors={errors}
        setFieldValue={setFieldValue}
      />
    </>
  );
}
