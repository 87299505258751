import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../SharedComponents/Modal';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../../SharedComponents/Loading';
import { addGalleryAlbum, getLanguages } from './ApiGalleryAlbums';
import * as yup from 'yup';
import AddImage from '../Components/AddImage';
import AddYoutube from '../Components/AddYoutube';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../../SharedComponents/LanguageList';

export default function CreateGalleryAlbum(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props.id ? props : 1;
  const { typeId, orderNumber } = props;
  const { t, i18n } = useTranslation();
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`bg-lightgreen ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } space-x-1 text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <PlusCircle className="w-5 h-5" /> <span>{t('Create Gallery Album')}</span>
      </button>

      {isOpen && (
        <ModalContainer orderNumber={orderNumber} id={id} typeId={typeId} isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, id, typeId, orderNumber } = props;
  const [languageId, setLanguageId] = useState(1);
  const [uploadSucess, setUploadSucess] = useState(false);
  const [isVideo, setIsVideo] = useState('false');
  const { t, i18n } = useTranslation();
  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [data]);

  // create a loop for localisation
  const glAlbumLngs = data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
    description: '',
  }));
  const initialValues = {
    orderNum: orderNumber,
    imageId: 0,
    typeId: 1,
    statusId: 1,
    glAlbumLngs: glAlbumLngs,
    isVideo: `${isVideo}`,
  };
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const mutation = useMutation(addGalleryAlbum, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['galleryAlbums']);

        setIsOpen(false);
      }
    },
  });

  const onSubmit = async (e) => {
    try {
      mutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };

  const CreateGalleryAlbumValidationSchema = yup.object().shape({
    orderNum: yup.number().required().positive().integer(),
    imageId: yup.number().required('This Field is Required').positive().integer(),
  });
  return (
    <>
      {data && data.data && (
        <Formik
          onSubmit={onSubmit}
          // validationSchema={CreateGalleryAlbumValidationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={initialValues}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Modal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  // onSubmit={onSubmit}
                  title={t('Create Gallery Album')}
                  isLoading={mutation.isLoading}
                  add={true}
                  // submitType="button"
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  {data && (
                    <LanguageList
                      className="flex transform translate-y-1 relative z-10 mb-4"
                      languages={data?.data}
                      languageId={languageId}
                      setLanguageId={setLanguageId}
                    >
                      <hr className="border w-full absolute bottom-0 left-0 z-0" />
                    </LanguageList>
                  )}
                  {mutation.isError && (
                    <small className="text-sm bg-red-100 block p-2 text-red-500 mb-2">
                      {mutation?.error?.response.data.Message === 'NotFoundForeignKey' &&
                        t('Kindly Add an Image/Video')}
                    </small>
                  )}

                  <div className="grid grid-cols-3 mb-2">
                    <label className={`flex items-center ${i18n.language === 'en' ? '' : 'space-x-reverse'} space-x-2`}>
                      <Field type="radio" name="isVideo" value="false" className="w-4 h-4" />
                      <span>{t('Image')}</span>
                    </label>
                    <label className={`flex items-center ${i18n.language === 'en' ? '' : 'space-x-reverse'} space-x-2`}>
                      <Field type="radio" name="isVideo" value="true" className="w-4 h-4" />
                      <span>{t('Video')}</span>
                    </label>
                  </div>
                  {/* Submit Form */}
                  <label className=" mb-4 block hidden">
                    <b className="font-semibold block text-gray-700">{t('Image Id')}</b>
                    <Field
                      className={`border rounded  w-full p-1 ${errors.imageId ? 'border-red-500' : ''}`}
                      name={`imageId`}
                      type="number"
                    />
                  </label>
                  {errors.imageId && <small className="text-xs text-red-500">Please Chose an image</small>}

                  {values.isVideo === 'false' && (
                    <div className="h-64 focus:outline-none group rounded border-dashed border-2 mb-4 relative">
                      {(values.imageUrl && (
                        <>
                          <img
                            className="object-fit h-64 w-full"
                            alt="img"
                            src={`${process.env.REACT_APP_IMAGE_PATH}${values.imageUrl}`}
                          />
                          <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                            <AddImage edit={true} setField={setFieldValue} />
                          </div>
                        </>
                      )) || (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <AddImage setField={setFieldValue} />
                        </div>
                      )}
                    </div>
                  )}
                  {values.isVideo === 'true' && (
                    <div className="h-64 focus:outline-none group rounded border-dashed border-2 mb-4 relative">
                      {(values.videoUrl && (
                        <>
                          <img
                            className="object-fit h-64 w-full"
                            alt="img"
                            src={`${process.env.REACT_APP_IMAGE_PATH}${values.videoUrl}`}
                          />
                          {t('videoUrl')}
                          <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                            <AddYoutube edit={true} setField={setFieldValue} />
                          </div>
                        </>
                      )) || (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <AddYoutube setField={setFieldValue} />
                        </div>
                      )}
                    </div>
                  )}
                  {values &&
                    values.glAlbumLngs &&
                    values?.glAlbumLngs.map((asset, index) => (
                      <FieldArray
                        key={index}
                        name="glAlbumLngs"
                        render={(arrayHelpers) => (
                          <>
                            {languageId === asset.languageId && (
                              <>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Title')}
                                  </b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    type="text"
                                    name="title"
                                    name={`glAlbumLngs[${index}].title`}
                                  />
                                </label>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Description')}
                                  </b>
                                  <Field
                                    className={`border rounded  w-full mb-4 p-1`}
                                    name={`glAlbumLngs[${index}].description`}
                                    type="text"
                                  />
                                </label>
                              </>
                            )}
                          </>
                        )}
                      />
                    ))}

                  <label>
                    <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                      {t('Order Number')}
                    </b>
                    <Field className="border rounded  w-full mb-4 p-1" name={`orderNum`} type="number" />
                  </label>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
