import { Formik, Form, Field } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Modal from '../../SharedComponents/Modal';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import { dir } from '../../utils/localisation';
import { AddCalenderVisit, EditCalenderVisit, GetAllEmployee, GetVisitorsNames } from './ApiCalender';
import Notification from '../../SharedComponents/Notification';
import { useHistory } from 'react-router';
import EmployeesList from './EmployeesList';
import VisitorsList from './VisitorsList';

export default function EditVisit(props) {
  const { eventInfo } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const eventData = JSON.parse(eventInfo.event.title);
  const { data } = eventData;
  console.log('dataewq', data.calenderStatus === 3);
  return (
    <>
      <button
        onClick={() => {
          setIsOpen(true);
        }}
        className={`${dir('', 'space-x-reverse')}  ${data.calenderStatus === 1 && 'bg-[#84cc16]'} ${
          data.calenderStatus === 3 && 'bg-red-500'
        } ${data.calenderStatus === 0 && 'bg-[#4f46e5]'}
          py-2 text-sm px-2   items-start text-left mb-1  w-full text-white xl:min-h-[3rem]  lg:h-20  md:h-16  h-14 `}
      >
        <span className="text-xs lg:text-base md:text-sm">{eventInfo.timeText}</span>
        <span className="px-2 block break-words text-xs lg:text-base md:text-sm">
          {dir(data.employeeEnName, data.employeeArName)}
        </span>
        <span className="block break-words w-full text-xs lg:text-base md:text-sm">
          {dir(data.visitorEnName, data.visitorArName)}
        </span>
      </button>
      {isOpen && <ModalContainer data={data} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}
const ModalContainer = (props) => {
  const { isOpen, setIsOpen, data } = props;
  const [languageId, setLanguageId] = useState(1);
  const history = useHistory();

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const EditCalenderVisitMutation = useMutation(EditCalenderVisit, {
    onSuccess: async (data) => {
      toast(<Notification message={t('Edited, Successfully')} />);
      queryClient.invalidateQueries(['GetAllCalenders']);
      setIsOpen(false);
      window.location.reload();
    },
  });
  const onSubmit = (e) => {
    EditCalenderVisitMutation.mutate(e);
  };
  const initialValues = {
    id: data.id,
    visitorId: data.visitorId,
    userId: data.userId,
    visitReson: data.visitReson,
    visitTime: data.visitTime,
    visitDate: data.visitDate,
  };
  const GetVisitorsNamesQuery = useQuery(
    ['GetVisitorsNames', { id: languageId === 2 ? 1029 : 1030 }],
    GetVisitorsNames,
    {
      staleTime: 99900,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  let getAllEmployeesInitialState = {
    pageNumber: 0,
    pageSize: 0,
    numberOfPages: 0,
    isPaging: false,
    isFilter: false,
    langId: 0,
    isSearch: true,
    newsnumber: 0,
    parentId: 0,
    checkIsRequest: false,
    isRequest: true,
    filter: [
      {
        filterBy: 'string',
        filterType: 'string',
        dateFilteration: {
          from: 'string',
          to: 'string',
        },
        searchTerm: [
          {
            langId: 0,
            term: 'string',
          },
        ],
      },
    ],
    searchFilter: {
      langId: 0,
      term: 'string',
    },
    optionId: 'string',
  };

  const GetAllEmployeeQuery = useQuery(['GetAllEmployee', getAllEmployeesInitialState], GetAllEmployee, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({ values, errors, setFieldValue }) => {
        return (
          <Form>
            <Modal className="sm:max-w-3xl" isOpen={isOpen} setIsOpen={setIsOpen} title={t('Edit Visit')} add={true}>
              <label className={`mb-3 block ${dir('', 'text-right')} `}>
                <h3 className="text-[#3d4151] text-base ">{t('Select Day')}</h3>
                <Field
                  type="date"
                  name="visitDate"
                  className="border rounded  w-full  p-2 text-sm focus:outline-none"
                />
              </label>
              <label className={`mb-3 block ${dir('', 'text-right')} `}>
                <h3 className="text-[#3d4151] text-base ">{t('Select time')}</h3>
                <Field
                  type="time"
                  name="visitTime"
                  className="border rounded  w-full  p-2 text-sm focus:outline-none"
                />
              </label>
              <label className={`mb-3 block ${dir('', 'text-right')} `}>
                <h3 className="text-[#3d4151] text-base ">{t('Reason')}</h3>
                <Field
                  as="textarea"
                  name="visitReson"
                  className="border rounded  w-full  p-2 text-sm focus:outline-none"
                />
              </label>
              <label className={`mb-3 block ${dir('', 'text-right')} `}>
                <h3 className="text-[#3d4151] text-base ">{t('Location')}</h3>
                <Field type="text" name="location" className="border rounded  w-full  p-2 text-sm focus:outline-none" />
              </label>
              {GetAllEmployeeQuery.data && (
                <EmployeesList
                  languageId={languageId}
                  setFieldValue={setFieldValue}
                  FieldName="userId"
                  FieldValue={values.userId}
                  list={GetAllEmployeeQuery.data.data}
                  errors={errors}
                />
              )}
              {GetVisitorsNamesQuery.data && (
                <VisitorsList
                  languageId={languageId}
                  setFieldValue={setFieldValue}
                  FieldName="visitorId"
                  FieldValue={values.visitorId}
                  list={GetVisitorsNamesQuery.data.data}
                  errors={errors}
                />
              )}
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
