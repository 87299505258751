import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from '../../SharedComponents/OutsideClickHandler';
import { dir } from '../../utils/localisation';

export default function EmployeesList(props) {
  const { errors, values, loading, error, list, FieldValue, setFieldValue, FieldName, disabled, languageId } = props;
  const newList = list.map((e) => {
    return {
      id: e.id,
      userId: e.userId,
      languages: [
        {
          languageId: 1,
          title: e.arabicName,
        },
        {
          languageId: 2,
          title: e.englishName,
        },
      ],
    }; 
  });

  let defaultValue = newList?.find((e) => e.id === FieldValue);
  console.log('defaultValue', newList);

  const [value, setValue] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchParam, setSearchParam] = useState(
    defaultValue ? defaultValue?.languages.filter((item) => item.languageId === languageId)[0]?.title : ''
  );
  const onSearchParam = (e) => {
    setSearchParam(e.target.value);
  };
  const { t, i18n } = useTranslation();
  const onClick = (data) => {
    setFieldValue(FieldName, data.id);
    setSearchParam(data.languages.filter((item) => item.languageId === languageId)[0]?.title);
    setIsOpen(false);
  };
  useEffect(() => {
    if (FieldValue?.length === 0) {
      setSearchParam(FieldValue);
    }
  }, [FieldValue]);

  return (
    <label className="relative col-span-2 lg:col-span-1 mb-4">
      <OutsideClickHandler setIsOpen={setIsOpen}>
        <b className={`font-semibold block text-gray-700 ${dir('', 'text-right')}`}>{t('Employee')} *</b>
        <input
          disabled={disabled}
          autoComplete="off"
          name="groupId"
          placeholder={t('Select Employee')}
          type="text"
          className={`border rounded  w-full mb-4 p-1`}
          // defaultvalue={defaultValue.name}
          value={searchParam}
          onChange={onSearchParam}
          onFocus={() => {
            setIsOpen(true);
          }}
        />

        {/* <p className="pt-1 text-red-500">{errors[name]}</p> */}
        <div className={` bottom-0 z-10 left-0 ${isOpen ? 'block' : 'hidden'}   pl-0 w-full bg-white transform  `}>
          <ul
            style={{
              direction: dir('rtl', 'ltr'),
            }}
            className="mt-2 max-h-64 border rounded divide-y overflow-y-scroll"
          >
            {console.log(
              'newList',
              languageId,
              newList.filter((e) => e.id)
            )}
            {newList
              .filter((e) =>
                e?.languages
                  .filter((item) => item.languageId === languageId)[0]
                  ?.title?.toLowerCase()
                  .includes(searchParam?.toLowerCase())
              )
              .map((e) => {
                return (
                  <button
                    key={e.id}
                    onClick={(x) => {
                      onClick(e);
                    }}
                    className={`block p-2 w-full hover:bg-gray-50 ${dir('text-left', 'text-right')} `}
                    type="button"
                  >
                    {e.languages.filter((item) => item.languageId === languageId)[0]?.title}
                  </button>
                );
              })}
          </ul>
        </div>
      </OutsideClickHandler>
    </label>
  );
}
