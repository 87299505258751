import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import LanguageList from '../../SharedComponents/LanguageList';
import Loading from '../../SharedComponents/Loading';
import Home from '../../SharedComponents/Svg/Home';
import { getLanguages, getMenus } from './ApiMenus';
import CreateMenu from './CreateMenu';
import DeleteMenu from './DeleteMenu';
import Menu from './Menu/Menu';
import ToggleMainMenu from './ToggleMainMenu';

export default function Menus(props) {
  const [languageId, setLanguageId] = useState(1);
  const [activeMenu, setActiveMenu] = useState(0);
  const { t, i18n } = useTranslation();
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  let initialState = {
    pageNumber: pageNumber,
    pageSize: entries,
    isPaging: true,
    langId: 0,
  };
  const { data, isLoading, isError } = useQuery(['Menus', initialState], getMenus, {
    staleTime: 0,
    onSuccess: (data) => {
      setActiveMenu(data.data[0].id);
    },
  });
  if (isLoading)
    return (
      <div className="relative">
        <Loading />
      </div>
    );
  if (isError) return 'error';

  return (
    <>
      <header className="flex justify-between items-center mb-4">
        <BreadCrumbs to="/menus" name={t('Menus')} />

        <div>
          <CreateMenu />
        </div>
      </header>
      <div className="bg-white px-4 pt-4 rounded shadow-lg border">
        <div className="flex justify-between relative items-center mb-4">
          <div className="flex transform  relative z-10">
            <span className="font-bold text-xl pb-3">{t('Menus List')}</span>
          </div>
          <div className="flex transform  relative z-10 ">
            {languages && (
              <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
            )}
          </div>{' '}
          <hr className="border w-full absolute top-0 left-0 z-0 mt-10" />
        </div>

        <div className="grid grid-cols-4 gap-6">
          <div className=" col-span-1 bg-gray-100 border">
            {data.data.map((e) => {
              return (
                <div
                  key={e.id}
                  className={` ${
                    activeMenu === e.id
                      ? 'text-white bg-blue-500'
                      : 'bg-white text-gray-500 hover:bg-blue-500 hover:text-white '
                  }  px-4 border-b  cursor-pointer focus:outline-none transition duration-300 block w-full text-left text-sm font-bold 
                      flex justify-between items-center ${i18n.language === 'en' ? '' : 'flex-row-reverse'}`}
                >
                  <span
                    className="flex-grow py-4"
                    onClick={() => {
                      setActiveMenu(e.id);
                    }}
                  >
                    {e.muMenuTypeLngs.map((x) => {
                      return <Fragment key={x.id}>{languageId === x.languageId && <> {x.title} </>}</Fragment>;
                    })}
                  </span>
                  <div className={`flex space-x-2  ${i18n.language === 'en' ? '' : 'flex-row-reverse'}`}>
                    {data.data.find((item) => item.isMainMenu) ? (
                      ''
                    ) : (
                      <ToggleMainMenu isActive={e.isMainMenu} id={e.id} />
                    )}
                    {e.isMainMenu ? <ToggleMainMenu isActive={e.isMainMenu} id={e.id} /> : ''}
                    <DeleteMenu id={e.id} />
                  </div>
                </div>
              );
            })}
          </div>
          <div className=" col-span-3 relative">
            <div>
              {activeMenu !== 0 && (
                <Menu
                  languageId={languageId}
                  menuType={data.data.find((e) => e.id === activeMenu)}
                  activeMenu={activeMenu}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
