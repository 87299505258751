import React, { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import Print from '../../../SharedComponents/Svg/Print';
import { dir } from '../../../utils/localisation';
import { getFormQuestionOption } from '../ApiForms';

const TableComponentToPrint = React.forwardRef((props, ref) => {
  const { languageId } = props;
  const { t, i18n } = useTranslation();
  const { typeId, name, qOptionName } = useParams();

  let initialState = {
    typeId: typeId,
    paginationFilter: {
      pageNumber: 0,
      pageSize: 0,
      isPaging: false,
      langId: 0,
    },
  };

  let govQueryinitialState = {
    typeId: 1011,
    paginationFilter: {
      pageNumber: 1,
      pageSize: 99999,
      isPaging: false,
      langId: 0,
    },
  };

  const govQuery = useQuery(['formQuestionOption', govQueryinitialState], getFormQuestionOption, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  let ocuppationCategoryQueryinitialState = {
    typeId: 1014,
    paginationFilter: {
      pageNumber: 1,
      pageSize: 99999,
      isPaging: false,
      langId: 0,
    },
  };

  const ocuppationCategoryQuery = useQuery(
    ['formQuestionOption', ocuppationCategoryQueryinitialState],
    getFormQuestionOption,
    {
      staleTime: 99900,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  const { data, paginationResult, isDone, isLoading, isError, refetch } = useQuery(
    ['formQuestionOption', initialState],
    getFormQuestionOption,
    {
      staleTime: 99900,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );
  if (isLoading) return '';
  const oldTh = [
    { title: t(name) },
    name === 'Areas' ? { title: 'Governorate' } : undefined,
    name === 'Occupations' ? { title: 'Category' } : undefined,
  ];
  const tableHeader = oldTh.filter((e) => e != null);

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" ref={ref}>
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table
            style={{
              direction: dir('ltr', 'rtl'),
            }}
            className="min-w-full divide-y-2 divide-gray-200"
          >
            <thead className=" text-sm font-bold text-black">
              <tr>
                {tableHeader.map((e) => {
                  return (
                    <th
                      key={e.title}
                      scope="col"
                      className={`px-6 py-3 ${dir('text-left', 'text-right')} uppercase  ${e.className}`}
                    >
                      {t(e.title)}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
              {data.data.map((form) => {
                return (
                  <tr key={form.id} className="">
                    <td className="px-6 py-4 whitespace-nowrap">
                      {form.frmQoptionLngs.map((e) => {
                        return (
                          <Fragment key={e.id}>
                            {languageId === e.languageId && (
                              <>
                                <div className="flex items-center space-x-2">
                                  <b>{e.title}</b>
                                </div>
                              </>
                            )}
                          </Fragment>
                        );
                      })}
                    </td>
                    {name === 'Areas' && (
                      <>
                        {/* {console.log(form.isParent)} */}

                        <td className="px-6 py-4 whitespace-nowrap">
                          {name === 'Areas' &&
                            govQuery.data?.data
                              ?.filter((e) => form.isParent === e.id)
                              ?.map((e) => {
                                return e.frmQoptionLngs[0].title;
                              })}
                        </td>
                      </>
                    )}
                    {name === 'Occupations' && (
                      <>
                        {/* {console.log(form.isParent)} */}

                        <td className="px-6 py-4 whitespace-nowrap">
                          {name === 'Occupations' &&
                            ocuppationCategoryQuery.data?.data
                              ?.filter((e) => form.isParent === e.id)
                              ?.map((e) => {
                                return e.frmQoptionLngs[0].title;
                              })}
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
export default function DownloadPdf(props) {
  const { languageId } = props;
  const { i18n, t } = useTranslation();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <button
      onClick={handlePrint}
      className={` py-2 rounded px-5 flex text-white  bg-[#7e22ce] space-x-2 items-center text-sm font-bold ${
        i18n.language === 'en' ? '' : 'space-x-reverse'
      }`}
    >
      <div className="hidden">
        <TableComponentToPrint ref={componentRef} languageId={languageId} />
      </div>

      <Print />
      <span>{t('Print')}</span>
    </button>
  );
}
