import axios from 'axios';

export const addList = async (e) => {
  const { data } = await axios.post('/PgSection/AddList', e);
  return data;
};
export const editList = async (e) => {
  const { data } = await axios.post('/PgSection/EditList', e);
  return data;
};
export const deleteList = async (e) => {
  const { data } = await axios.post('/PgSection/DeleteList', e);
  return data;
};
export const addListParent = async (e) => {
  const { data } = await axios.post('/PgSection/AddListParent', e);
  return data;
};
export const editListParent = async (e) => {
  const { data } = await axios.post('/PgSection/EditListParent', e);
  return data;
};
export const deleteListParent = async (e) => {
  const { data } = await axios.post('/PgSection/DeleteListParent', e);
  return data;
};
export const addListItem = async (e) => {
  const { data } = await axios.post('/PgSection/AddListItem', e);
  return data;
};
export const editListItem = async (e) => {
  const { data } = await axios.post('/PgSection/EditListItem', e);
  return data;
};
export const deleteListItem = async (e) => {
  const { data } = await axios.post('/PgSection/DeleteListItem', e);
  return data;
};
