import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../SharedComponents/Modal';
import Eye from '../../../SharedComponents/Svg/Eye';

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, video } = props;
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={t('View Asset')} add={true} hideSubmit={true}>
      <div className="text-center">
        <video style={{ width: '100%' }} height="240" controls>
          <source src={video} type="video/mp4" />
          {t('Your browser does not support the video tag.')}
        </video>
      </div>
    </Modal>
  );
};

export default function ViewVideo(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { video } = props;
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-orange hover:bg-orangehover transition duration-500 text-white rounded p-1"
      >
        <Eye className="w-5 h-5" />
      </button>

      {isOpen && <ModalContainer video={video} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}
