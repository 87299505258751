import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import Print from '../../SharedComponents/Svg/Print';
import Barcode from './Barcode';

const TableComponentToPrint = React.forwardRef((props, ref) => {
  const { data } = props;
  return (
    <div className="p-8" ref={ref}>
      <span className="text-gray-500 mb-2 block">Visitor ID</span>
      <div className=" rounded border-black p-8 border-2">
        <div className="flex font-bold space-x-4 mb-2">
          <span>System ID:</span> <span>{data.systemId}</span>
        </div>
        <div className="flex font-bold space-x-4 mb-2">
          <span>Arabic Name:</span> <span>{data.arabicName}</span>
        </div>
        <div className="flex font-bold space-x-4 mb-2">
          <span>English Name:</span> <span>{data.englishName}</span>
        </div>
        <div>
          <span className=" font-bold mb-4 block">ID Number:</span>
          <div>
            <Barcode value={data.systemId} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default function PrintUserCard(props) {
  const { languageId, data } = props;
  const { i18n, t } = useTranslation();
  const componentRef = useRef();
  const pageStyle = `
    @page {
      size: 980mm 350mm;
    }
`;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // pageStyle: pageStyle,
  });

  return (
    <button
      onClick={handlePrint}
      className={` py-2 rounded px-5 flex text-white  bg-[#7e22ce] space-x-2 items-center text-sm font-bold ${
        i18n.language === 'en' ? '' : 'space-x-reverse'
      }`}
    >
      <div className="hidden">
        <TableComponentToPrint ref={componentRef} data={data} />
      </div>

      <Print />
      <span>{t('Print')}</span>
    </button>
  );
}
