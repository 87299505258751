import axios from 'axios';

export const getCategories = async (e) => {
  const query = await axios.post('/PgPage/GetAllCategory', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};

export const addCategory = async (e) => {
  const { data } = await axios.post('/PgPage/AddCategory', e);
  return data;
};

export const editCategory = async (e) => {
  const { data } = await axios.post('/PgPage/EditCategory', e);
  return data;
};
export const deleteCategory = async (id) => {
  const mutation = await axios.post(`/PgPage/DeleteCategory`, { id });
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};
