import { Field, FieldArray, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useMutation, useQueryClient } from 'react-query';
import AddSliderImage from './AddSliderImage';
import DeleteSliderItem from './DeleteSliderItem';
import SliderPagesList from './SliderPagesList';
import { editSliderItem } from './ApiSlider';
import Notification from '../../../../SharedComponents/Notification';
import { useTranslation } from 'react-i18next';

export default function SliderItem(props) {
  const { pgSliderItem, pgSliderItemIndex, pgSliderItemsHelper, languageId, setFieldValue } = props;
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const editSliderItemMutation = useMutation(editSliderItem, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['sections']);
      }
    },
  });

  const onSubmit = async (values) => {
    setFieldValue(`pgSlider.pgSliderItems[${pgSliderItemIndex}]`, values);
    try {
      await editSliderItemMutation.mutate(values);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className="mt-4 shadow p-4 border rounded ">
      {editSliderItemMutation.isSuccess && <Notification message={t('Slider Item Updated')} />}
      <Formik
        initialValues={{
          ...pgSliderItem,
          isExternalLink:
            pgSliderItem.isExternalLink === true || pgSliderItem.isExternalLink === 'true' ? 'true' : 'false',
        }}
      >
        {({ values, setFieldValue, setValues }) => {
          values.date = values.date ? DateTime.fromISO(values?.date).toISODate() : null;
          return (
            <Form>
              <div>
                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <div className="h-52 focus:outline-none group rounded border-dashed border-2 mb-2 relative">
                      {(values.imageUrl && (
                        <>
                          <div className="flex items-center justify-center h-full p-2">
                            <img
                              className="w-full h-full object-contain"
                              alt="img"
                              src={`${process.env.REACT_APP_IMAGE_PATH}${values.imageUrl}`}
                            />{' '}
                          </div>
                          <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                            <AddSliderImage edit={true} setField={setFieldValue} fieldName="imageUrl" />
                          </div>
                        </>
                      )) || (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <AddSliderImage fieldName="imageUrl" setField={setFieldValue} />
                        </div>
                      )}
                    </div>
                    <div className="mt-4">
                      {values &&
                        values.pgSliderItemLng &&
                        values?.pgSliderItemLng.map((pgSliderItemLng, index) => (
                          <FieldArray
                            key={index}
                            name="pgSliderItemLng"
                            render={(arrayHelpers) => (
                              <>
                                {languageId === pgSliderItemLng.languageId && (
                                  <>
                                    <label>
                                      <Field
                                        className="border rounded  w-full  p-2"
                                        type="text"
                                        placeholder={t('Button Title')}
                                        name={`pgSliderItemLng[${index}].btnTitle`}
                                      />
                                    </label>
                                  </>
                                )}
                              </>
                            )}
                          />
                        ))}
                    </div>
                    <div className="mt-4 relative">
                      <SliderPagesList setFieldValue={setFieldValue} languageId={languageId} />
                    </div>
                  </div>
                  <div>
                    {values &&
                      values.pgSliderItemLng &&
                      values?.pgSliderItemLng.map((pgSliderItemLng, index) => (
                        <FieldArray
                          key={index}
                          name="pgSliderItemLng"
                          render={(arrayHelpers) => (
                            <>
                              {languageId === pgSliderItemLng.languageId && (
                                <>
                                  <label>
                                    <Field
                                      className="border rounded  w-full mb-4  p-2"
                                      type="text"
                                      placeholder={t('Add Title')}
                                      name={`pgSliderItemLng[${index}].title`}
                                    />
                                  </label>
                                  <label>
                                    <Field
                                      className="border rounded  w-full mb-4  p-2"
                                      type="text"
                                      placeholder={t('Subtitle')}
                                      name={`pgSliderItemLng[${index}].subTitle`}
                                    />
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        />
                      ))}

                    {values &&
                      values.pgSliderItemLng &&
                      values?.pgSliderItemLng.map((pgSliderItemLng, index) => (
                        <FieldArray
                          key={index}
                          name="pgSliderItemLng"
                          render={(arrayHelpers) => (
                            <>
                              {languageId === pgSliderItemLng.languageId && (
                                <>
                                  <label>
                                    <Field
                                      className="border rounded  w-full  p-2"
                                      as="textarea"
                                      rows="3"
                                      placeholder={t('Add Description')}
                                      name={`pgSliderItemLng[${index}].text`}
                                    />
                                  </label>
                                </>
                              )}
                            </>
                          )}
                        />
                      ))}
                    <div className="grid grid-cols-2 space-x-2 mt-4 ">
                      <label
                        className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                      >
                        <Field type="radio" name="isExternalLink" value="true" className="w-4 h-4" />
                        <span>{t('External Link')}</span>
                      </label>
                      <label
                        className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                      >
                        <Field type="radio" name="isExternalLink" value="false" className="w-4 h-4" />
                        <span>{t('Pages')}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <label className="mt-2 block">
                  <Field
                    type="number"
                    name="orderNum"
                    placeholder="Order Number"
                    className="border rounded  w-full  p-2"
                  />
                </label>
              </div>
              <div className={`flex justify-end space-x-2 py-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                <button
                  type="button"
                  onClick={() => {
                    onSubmit(values);
                  }}
                  className="bg-lightgreen py-2 px-2 flex justify-center text-center h-full text-sm font-bold text-white items-center hover:bg-lightgreenhover text-centerhover shadow  rounded transition duration-300 ease-in-out"
                >
                  {t('Update Item')}
                </button>
                <DeleteSliderItem
                  id={values.id}
                  pgSliderItemsHelper={pgSliderItemsHelper}
                  pgSliderItemIndex={pgSliderItemIndex}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
