import axios from 'axios';

export const getFaqs = async (e) => {
  const query = await axios.post('/FAQ/GetAll', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};

export const addFaq = async (e) => {
  const { data } = await axios.post('/FAQ/Add', e);
  return data;
};

export const editFaq = async (e) => {
  const { data } = await axios.post('/FAQ/Edit', e);
  return data;
};

export const deleteFaq = async (id) => {
  const mutation = await axios.post(`/FAQ/Delete`, { id });
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};
