import { Field, FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ChevronRight from '../../../SharedComponents/Svg/ChevronRight';
import DeleteSection from '../../Settings/DeleteBranch';
import { editSection } from './ApiSections';
import Card from './Card/Card';
import Item from './Item';
import Tab from './Tab/Tab';
import Slider from './Slider/Slider';
import ToggleSectionStatus from './ToggleSectionStatus';
import { Draggable } from 'react-beautiful-dnd';
import Collapse from './Collapse/Collapse';
import List from './List/List';
import Statistics from './Statistics/Statistics';
import ModalSection from './Modal/ModalSection';
import FormSection from './Form/FormSection';
import GalleryAlbumSection from './GallaryAlbumSection/GalleryAlbumSection';

export default function Section(props) {
  const { section, languages, index } = props;
  // SECTIONS ENUM DO NO DELETE
  //  Card = 0, Done
  //  Tabs = 1, Done
  //  Collapse = 2, Done
  //  Banner = 3,
  //  Slider = 4, Done
  //  Statistics=5, Done
  //  List=6, Done
  //  Modal =7, Done
  //  Form =8, Done
  //  Gallery Album = 9 Processing
  // SECTIONS ENUM DO NO DELETE
  const queryClient = useQueryClient();
  const editSectionMutation = useMutation(editSection, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['sections']);
      }
    },
  });
  return (
    <>
      <Draggable draggableId={`${section.id}`} index={index}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            {section.sectionTypeId === 0 && (
              <Card provided={provided} id={section.id} section={section} languages={languages} />
            )}
            {section.sectionTypeId === 1 && (
              <Tab provided={provided} id={section.id} section={section} languages={languages} />
            )}
            {section.sectionTypeId === 2 && (
              <Collapse provided={provided} id={section.id} section={section} languages={languages} />
            )}
            {section.sectionTypeId === 4 && (
              <Slider provided={provided} id={section.id} section={section} languages={languages} />
            )}
            {section.sectionTypeId === 5 && (
              <Statistics
                provided={provided}
                editSectionMutation={editSectionMutation}
                id={section.id}
                section={section}
                languages={languages}
              />
            )}
            {section.sectionTypeId === 6 && (
              <List provided={provided} id={section.id} section={section} languages={languages} />
            )}
            {section.sectionTypeId === 7 && (
              <ModalSection
                provided={provided}
                editSectionMutation={editSectionMutation}
                id={section.id}
                section={section}
                languages={languages}
              />
            )}
            {section.sectionTypeId === 8 && (
              <FormSection
                provided={provided}
                editSectionMutation={editSectionMutation}
                id={section.id}
                section={section}
                languages={languages}
              />
            )}
            {section.sectionTypeId === 9 && (
              <GalleryAlbumSection
                provided={provided}
                editSectionMutation={editSectionMutation}
                id={section.id}
                section={section}
                languages={languages}
              />
            )}
          </div>
        )}
      </Draggable>
    </>
  );
}
