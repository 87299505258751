import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getQuestionTypes } from '../ApiForms';

export default function FieldType(props) {
  const { fieldName } = props;
  const { t, i18n } = useTranslation();
  const { data, isLoading, isError } = useQuery(['questionTypes'], getQuestionTypes);
  if (isLoading) return 'loading';
  if (isError) return 'error';
  return (
    <label className={`relative`}>
      <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>{t('Type')}</b>
      <Field className="border rounded  w-full mb-2 p-2 text-sm focus:outline-none" name={fieldName} as="select">
        {data.data.map((e) => {
          return <option value={parseInt(e.id)}>{e.title}</option>;
        })}
      </Field>
      {/* <button className='absolute bottom-0 right-0 text-white -mb-2 border rounded bg-black p-1'>
    <DocumentDuplicate />
  </button> */}
    </label>
  );
}
