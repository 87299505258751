import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import BreadCrumbs from '../../../SharedComponents/BreadCrumbs';
import FormPagination from '../../../SharedComponents/FormPagination';
import LanguageList from '../../../SharedComponents/LanguageList';
import Loading from '../../../SharedComponents/Loading';
import Eye from '../../../SharedComponents/Svg/Eye';
import PaperClip from '../../../SharedComponents/Svg/PaperClip';
import { getLanguages } from '../ApiForms';
import { GetFormAnswer, getFormQuestions } from './ApiFormAnswer';
import DownloadFormAnswer from './DownloadFormAnswer';
import FormUser from './FormUser';
export default function FormAnswer() {
  const { formId } = useParams();

  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();
  const [entries, setEntries] = useState(10);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const { data, isLoading, isError } = useQuery(['formAnswer', formId], GetFormAnswer, {
    onSuccess: ({ data }) => {
      setNumberOfPages(uniqBySubmitId(data, (it) => it.submitId).length / entries);
    },
  });

  const uniqBySubmitId = (data, key) => {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  };
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationCount, setPaginationCount] = useState([]);
  function pagination() {
    let paginationCount = [];
    for (var i = 1; i <= numberOfPages + 0.8; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }
  useEffect(() => {
    pagination();
  }, [numberOfPages]);
  useEffect(() => {
    !isLoading && setNumberOfPages(uniqBySubmitId(data?.data, (it) => it.submitId)?.length / entries);
  }, []);
  const body = {
    formId: formId,
    paginationFilter: {
      pageNumber: 0,
      pageSize: 0,
      numberOfPages: 0,
      isPaging: false,
      isFilter: false,
      langId: 0,
      isSearch: false,
      newsnumber: 0,
      parentId: 0,
    },
  };

  const formQuestions = useQuery(['formQuestions', body], getFormQuestions);

  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  if (isLoading || formQuestions.isLoading) return <Loading />;
  if (isError || formQuestions.isError) return 'error';
  const tabltHeader = [
    ...formQuestions.data.data,
    {
      id: 'date',
      frmQuestionLngs: [
        {
          description: '',
          frmQuestionId: 1017,
          id: 0,
          languageId: 1,
          title: 'التاريخ',
          valMessage: null,
        },
        {
          title: 'Date',
          frmQuestionId: 1017,
          id: 0,
          languageId: 2,
          valMessage: null,
        },
        {
          description: '',
          frmQuestionId: 1017,
          id: 0,
          languageId: 3,
          title: '',
          valMessage: null,
        },
      ],
    },
    {
      id: 0,
      frmQuestionLngs: [
        {
          description: '',
          frmQuestionId: 1017,
          id: 0,
          languageId: 1,
          title: 'اسم المستخدم',
          valMessage: null,
        },
        {
          title: 'User Name',
          frmQuestionId: 1017,
          id: 0,
          languageId: 2,
          valMessage: null,
        },
        {
          description: '',
          frmQuestionId: 1017,
          id: 0,
          languageId: 3,
          title: '',
          valMessage: null,
        },
      ],
    },
    {
      id: 'options',
      frmQuestionLngs: [
        {
          title: 'التفاصيل',
          frmQuestionId: 1017,
          id: 0,
          languageId: 1,
          valMessage: null,
        },
        {
          title: 'Details',
          frmQuestionId: 1017,
          id: 0,
          languageId: 2,
          valMessage: null,
        },
        {
          description: '',
          frmQuestionId: 1017,
          id: 0,
          languageId: 3,
          title: '',
          valMessage: null,
        },
      ],
    },
  ];

  return (
    <>
      <header className="flex justify-between items-center">
        <BreadCrumbs
          to="/forms"
          name={t('Forms')}
          morePages={[{ name: data?.data[0]?.formName, to: `/formAnswer/${formId}` }]}
          backTo={`/forms`}
        />
      </header>
      <div className="my-6">
        <h2 className="text-2xl font-bold">{data?.data[0]?.formName}</h2>
      </div>

      {/* Table */}
      <div className="bg-white rounded border shadow-md ">
        <div className="flex justify-between relative items-center mb-4 p-6">
          <div className="flex transform  relative z-10 ">
            <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
          </div>
          <div className="flex  items-center transform -translate-y-3  relative z-10 ">
            <DownloadFormAnswer
              fileName={data?.data[0]?.formName}
              fileHeaders={tabltHeader.filter((e) => e.id !== 'options')}
              data={uniqBySubmitId(data.data, (it) => it.submitId)}
              languageId={languageId}
            />
          </div>
          <hr className="border w-full absolute top-0 left-0 z-0 mt-16" />
        </div>

        <div className="flex flex-col overflow-x-auto">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y-2 divide-gray-200">
                  <thead className=" text-sm font-bold text-black">
                    <tr>
                      {tabltHeader.map((e) => {
                        return (
                          <th
                            key={e.title}
                            scope="col"
                            className={`px-6 py-3 ${
                              i18n.language === 'en' ? 'text-left' : 'text-right'
                            }    uppercase  ${e.className}`}
                          >
                            {e.frmQuestionLngs.filter((e) => e.languageId === languageId)[0].title}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
                    {uniqBySubmitId(data.data, (it) => it.submitId)
                      .reverse()
                      .slice((pageNumber - 1) * entries, pageNumber * entries)
                      .map((item, index) => (
                        <tr key={index}>
                          {tabltHeader.map((th) => (
                            <td key={th.id} className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center max-w-xs overflow-hidden">
                                {th.id === 0 ? (
                                  <FormUser userName={item.createdByName} userId={item.userId} />
                                ) : th.id === 'options' ? (
                                  <>
                                    <Link
                                      to={`/formAnswer/${formId}/${item.submitId}`}
                                      className="bg-orange hover:bg-orangehover transition duration-500 text-white rounded p-1"
                                    >
                                      <Eye className="w-5 h-5" />
                                    </Link>
                                  </>
                                ) : th.id === 'date' ? (
                                  DateTime.fromISO(item?.createDate).toISODate()
                                ) : (
                                  <>
                                    {item?.answer?.filter((e) => e.questionId === th.id)[0]?.question.qtypeId === 4 ? (
                                      <a
                                        href={`https://rate2020cdn.key-frame.cf/images/${
                                          item?.answer?.filter((e) => e.questionId === th.id)[0]?.answer
                                        }`}
                                        download={item?.answer?.filter((e) => e.questionId === th.id)[0]?.answer}
                                        target="_blank"
                                        className="bg-green-100 text-green-800 hover:bg-green-200 transition duration-500 text-white rounded p-1"
                                      >
                                        <PaperClip className="w-5 h-5" />
                                      </a>
                                    ) : (
                                      item?.answer?.filter((e) => e.questionId === th.id)[0]?.answer
                                    )}
                                  </>
                                )}
                              </div>
                            </td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <FormPagination
          data={uniqBySubmitId(data.data, (it) => it.submitId)}
          paginationCount={paginationCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </div>
    </>
  );
}
