import { Field, FieldArray, Form, Formik, isEmptyArray } from 'formik';
import { useEffect, useState } from 'react';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import React, { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DocumentDuplicate from '../../../SharedComponents/Svg/DocumentDuplicate';
import Loading from '../../../SharedComponents/Loading';
import Modal from '../../../SharedComponents/Modal';
import {
  addForm,
  addFormQuestionOptions,
  addFormQuestionOptionsType,
  getFormQuestionOption,
  getLanguages,
} from '../ApiForms';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../../SharedComponents/LanguageList';
import { toast } from 'react-toastify';
import Notification from '../../../SharedComponents/Notification';
import QoptionsTypeGoverorate from './Type/QoptionsTypeGoverorate';
import { useParams } from 'react-router';
import QoptionsTypeOccupationCategory from './Type/QoptionsTypeOccupationCategory';
import { dir } from '../../../utils/localisation';
import * as Yup from 'yup';
import FormError from '../../Requests/FormError';
export default function CreateFormQuestionOptionsType(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props.id ? props : 1;
  const { typeId } = props;
  const { name } = useParams();
  const { t, i18n } = useTranslation();
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`${dir(
          '',
          'space-x-reverse'
        )} bg-lightgreen space-x-1   text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <PlusCircle className="w-5 h-5" /> <span>{t('Add New')}</span>
      </button>

      {isOpen && <ModalContainer id={id} typeId={typeId} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, id, typeId } = props;
  const { name } = useParams();

  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();

  let govQueryinitialState = {
    typeId: 1011,
    paginationFilter: {
      pageNumber: 1,
      pageSize: 99999,
      isPaging: false,
      langId: 0,
    },
  };

  const govQuery = useQuery(['formQuestionOption', govQueryinitialState], getFormQuestionOption, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  let ocuppationCategoryQueryinitialState = {
    typeId: 1014,
    paginationFilter: {
      pageNumber: 1,
      pageSize: 99999,
      isPaging: false,
      langId: 0,
    },
  };

  const ocuppationCategoryQuery = useQuery(
    ['formQuestionOption', ocuppationCategoryQueryinitialState],
    getFormQuestionOption,
    {
      staleTime: 99900,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [data]);

  const frmQoptionLngs = data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
  }));
  const initialValues = {
    typeId: typeId,
    frmQoptionLngs: frmQoptionLngs,
    isParent: null,
  };
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const mutation = useMutation(addFormQuestionOptions, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['formQuestionOption']);
        toast(<Notification message={t('Added, Successfully')} />);
        setIsOpen(false);
      }
    },
  });

  const onSubmit = async (e) => {
    try {
      await mutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {data && data.data && (
        <Formik
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            // frmQoptionLngs: Yup.array(Yup.object())
            //   .length(1)
            //   .required(JSON.stringify({ en: 'This Field Is Required', ar: 'هذا الحقل مطلوب' }))
            //   .max(500, ''),
            // frmQoptionLngs: Yup.string().required().max(500, '')
            frmQoptionLngs: Yup.array().of(
              Yup.object().shape({
                title: Yup.string().required().max(500, ''),
              })
            ),
          })}
          initialValues={initialValues}
        >
          {({ values, setFieldValue, errors, touched }) => {
            console.log('x', values);
            return (
              <Form>
                <Modal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onSubmit={onSubmit}
                  title={t('Add / Edit') + ' ' + t(name)}
                  add={true}
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  {data && (
                    <LanguageList
                      className="flex transform translate-y-1 relative z-10 mb-4"
                      languages={data?.data}
                      languageId={languageId}
                      setLanguageId={setLanguageId}
                    >
                      <hr className="border w-full absolute bottom-0 left-0 z-0" />
                    </LanguageList>
                  )}

                  {/* Submit Form */}

                  {values &&
                    values.frmQoptionLngs &&
                    values?.frmQoptionLngs.map((asset, index) => (
                      <FieldArray
                        key={index}
                        name="frmQoptionLngs"
                        render={(arrayHelpers) => (
                          <>
                            {languageId === asset.languageId && (
                              <>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Title')}
                                  </b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    type="text"
                                    name={`frmQoptionLngs[${index}].title`}
                                  />
                                </label>
                              </>
                            )}
                          </>
                        )}
                      />
                    ))}
                  {errors && touched.frmQoptionLngs && (
                    <span className={`${dir('text-left', 'text-right')} pt-1 text-xs font-base text-red-500`}>
                      {dir('All Languages are Required', 'برجاء إضافة بيانات جميع اللغات')}
                    </span>
                  )}
                  {name === 'Areas' && govQuery.data && (
                    <QoptionsTypeGoverorate
                      setFieldValue={setFieldValue}
                      FieldName="isParent"
                      FieldValue={values.parentId}
                      list={govQuery.data.data}
                      errors={errors}
                    />
                  )}
                  {name === 'Occupations' && ocuppationCategoryQuery.data && (
                    <QoptionsTypeOccupationCategory
                      setFieldValue={setFieldValue}
                      FieldName="isParent"
                      FieldValue={values.parentId}
                      list={ocuppationCategoryQuery.data.data}
                      errors={errors}
                    />
                  )}
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
