import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import Modal from '../../SharedComponents/Modal';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import PecnilAlt from '../../SharedComponents/Svg/PencilAlt';
import { editLanguage } from './ApiLanguage';
import Notification from '../../SharedComponents/Notification';
import { useTranslation } from 'react-i18next';

export default function EditLanguage(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props.id ? props : 1;
  const { lang } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  // Mutate Data to Create New Category
  const editLangMutation = useMutation(editLanguage, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['getlanguages']);
        setIsOpen(false);
      }
    },
  });

  return (
    <>
      {editLangMutation.isSuccess && <Notification message={t('Language Edit Successfully')} />}
      {editLangMutation.isError && <Notification message={t('Language Order Already Exists')} error />}

      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <PecnilAlt className="w-5 h-5" />
      </button>

      {isOpen && (
        <ModalContainer id={id} editLangMutation={editLangMutation} isOpen={isOpen} setIsOpen={setIsOpen} lang={lang} />
      )}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, editLangMutation, lang } = props;
  const { t, i18n } = useTranslation();
  const initialValues = lang;

  const onSubmit = async (e) => {
    try {
      editLangMutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Formik onSubmit={onSubmit} validateOnBlur={false} validateOnChange={false} initialValues={initialValues}>
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onSubmit={onSubmit}
                title={t('Create Language')}
                isLoading={editLangMutation.isLoading}
                add={true}
              >
                {/* Submit Form */}
                <label>
                  <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                    {t('Name')}
                  </b>
                  <Field className="border rounded  w-full mb-4 p-1" type="text" name={'name'} />
                </label>
                <label>
                  <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                    {t('Code')}
                  </b>
                  <Field className="border rounded  w-full mb-4 p-1" type="text" name={'code'} />
                </label>
                <label>
                  <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                    {t('Order Number')}
                  </b>
                  <Field className="border rounded  w-full mb-4 p-1" type="text" name={'orderNum'} />
                </label>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
