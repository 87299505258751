import React from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import DeleteListItem from './DeleteListItem';
import { useTranslation } from 'react-i18next';

export default function ListItem(props) {
  const { ListItem, mainIndex, languageId, setParentFieldValue } = props;
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Formik initialValues={ListItem}>
        {({ values }) => {
          return (
            <Form>
              {/* Submit Form */}
              <div className="grid grid-cols-2 gap-6 ">
                <div className="col-span-2">
                  <div className="mb-2">
                    {values &&
                      values.pgSectionListItemLngs &&
                      values?.pgSectionListItemLngs.map((pgSectionCollapseItemsLng, index) => (
                        <FieldArray
                          key={index}
                          name="pgSectionListItemLngs"
                          render={(arrayHelpers) => (
                            <>
                              {languageId === pgSectionCollapseItemsLng.languageId && (
                                <div className="flex items-center">
                                  <label className="w-full">
                                    <Field
                                      className="border rounded  w-full  p-2"
                                      type="text"
                                      placeholder={t('Add Title')}
                                      onBlur={(e) => {
                                        setParentFieldValue(`pgSectionListItems[${mainIndex}]`, values);
                                      }}
                                      name={`pgSectionListItemLngs[${index}].title`}
                                    />
                                  </label>
                                  <DeleteListItem listArrayHelpers={arrayHelpers} index={index} id={ListItem.id} />
                                </div>
                              )}
                            </>
                          )}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
