// eslint-disable-next-line import/no-anonymous-default-export
export default {
  'Visitors Reports': 'تقارير الزائرين',
  Governorate: 'المحافظة',
  'Age Group.': 'الفئة العمرية.',
  'Reasons of visit': 'أسباب الزيارة',
  'Registration Method': 'طريقة التسجيل',
  'Date frome': 'التاريخ من',
  to: 'لي',
  'Level of Education': 'مستوى التعليم',
};
