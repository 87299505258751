import React, { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';
import Print from '../../../SharedComponents/Svg/Print';
import { dir } from '../../../utils/localisation';

import { getUsers } from './ApiUser';

const TableComponentToPrint = React.forwardRef((props, ref) => {
  const { t, i18n } = useTranslation();
  let initialState = {
    pageNumber: 0,
    pageSize: 0,
    isPaging: false,
    langId: 0,
    isSearch: false,
  };

  const { data, isLoading, isError } = useQuery(['users', initialState], getUsers, {
    staleTime: 2000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
  if (isLoading) return '';

  const tableHeader = [
    { title: t('User full name') },
    { title: t('User name') },
    { title: t('email') },
    { title: t('User role') },
  ];

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" ref={ref}>
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y-2 divide-gray-200">
            <thead className=" text-sm font-bold text-black">
              <tr>
                {tableHeader.map((e) => {
                  return (
                    <th key={e.title} scope="col" className={`px-6 py-3 ${dir('text-left', 'text-right')} uppercase  `}>
                      {t(e.title)}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
              {data.data.map((user) => {
                return (
                  <tr key={user.id} className="">
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      <div className=" ">{user.fullName}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      <div className=" ">{user.userName}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      <div className=" ">{user.email}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {user.roles.map((role) => {
                        return (
                          <div key={role} className="inline-block px-2">
                            {role}
                          </div>
                        );
                      })}
                      {user.roles.length === 0 && <div className="inline-block px-2">Not Assigned</div>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
export default function DownloadPdf(props) {
  const { languageId } = props;
  const { i18n, t } = useTranslation();
  const componentRef = useRef();
  const pageStyle = `
    @page {
      size: 380mm ;
    }
`;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle,
  });

  return (
    <button
      onClick={handlePrint}
      className={` py-2 rounded px-5 flex text-white  bg-[#7e22ce] space-x-2 items-center text-sm font-bold ${
        i18n.language === 'en' ? '' : 'space-x-reverse'
      }`}
    >
      <div className="hidden">
        <TableComponentToPrint ref={componentRef} languageId={languageId} />
      </div>

      <Print />
      <span>{t('Print')}</span>
    </button>
  );
}
