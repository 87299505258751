import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { dir } from '../../utils/localisation';
import ChevronRight from '../Svg/ChevronRight';

export default function CustomMain(props) {
  const { routes, title, icon, sidebarToggle } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={`${isOpen ? 'bg-[#222222]' : ''} `}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`${
          isOpen ? 'bg-[#222222]' : ''
        } flex justify-between items-center  w-full duration-200 mt-4 py-2 px-6  hover:bg-[#222222]  hover:text-[#fdce00]`}
      >
        <div className={` flex items-center`}>
          <img alt="x" src={icon} />
          {sidebarToggle && (
            <>
              <span className="mx-4">
                <div className="flex items-center space-x-2">
                  <b>{t(title)}</b>
                </div>
              </span>
            </>
          )}
        </div>

        <ChevronRight className={`w-5 h-5 transform ${isOpen ? '-rotate-90' : 'rotate-90'}`} />
      </button>
      {isOpen &&
        routes?.map((route) => {
          return (
            <NavLink
              key={route.id}
              to={route.to}
              activeClassName="bg-gray-900  text-white border-white"
              activeStyle={{
                borderColor: '#fff',
              }}
              className="flex justify-between bg-[#222222] items-center duration-200 text-sm font-regular py-2 pl-8  border-gray-800  hover:text-[#fdce00]"
            >
              <span className="mx-4">
                <div className={`flex  space-x-4 items-center ${dir('', 'space-x-reverse')}`}>
                  <div className="w-1 h-1 bg-[#bababa] rounded-full "></div>
                  <span>{t(route.name)}</span>
                </div>
              </span>
            </NavLink>
          );
        })}
    </div>
  );
}
