import axios from 'axios';

export const GetAllAnswersByFrmQoptionTypeId = async (e) => {
  console.log('e', e);
  const { data } = await axios.post('/FrmAnswer/GetAllAnswersByFrmQoptionTypeId', e.queryKey[1]);
  return data;
};

export const deleteBySubmitId = async (e) => {
  console.log('e', e);
  const { data } = await axios.post('/FrmAnswer/DeleteAnwsersBySubmitId', e);
  return data;
};

// request handles
export const GetDataOfRequest = async (body) => {
  const { data } = await axios.get(
    `/FrmAnswer/GetDataOfRequest?formId=${body.queryKey[1].formID}&RequestDataId=${parseInt(
      body.queryKey[1].requestId
    )}`
  );
  return data;
};

// request handles
export const GetRequestMessageByRequestId = async (body) => {
  const { data } = await axios.get(
    `/FrmAnswer/GetRequestMessageByRequestId?RequestDataId=${parseInt(body.queryKey[1].requestId)}`
  );
  return data;
};
