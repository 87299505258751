import axios from 'axios';

// Comon API
export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};

export const getPages = async (e) => {
  const initialData = {
    pageNumber: 1,
    pageSize: 1,
    isPaging: false,
    langId: 0,
  };
  const query = await axios.post('/PgPage/GetAll', initialData);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

// GalleryAlbumApi
export const getMenu = async (e) => {
  const query = await axios.post('/MuMenuItem/GetByTypeId', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};
export const getMenuByParentId = async (e) => {
  const query = await axios.post('/MuMenuItem/GetByParentId', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

export const addMenuItem = async (e) => {
  const { data } = await axios.post('/MuMenuItem/Add', e);
  return data;
};
export const editMenuItem = async (e) => {
  const { data } = await axios.post('/MuMenuItem/Edit', e);
  return data;
};
export const toggleStatusMenuItem = async (e) => {
  const { data } = await axios.post('/MuMenuItem/ChangeStatus', e);
  return data;
};
export const saveMenuReorder = async (e) => {
  const { data } = await axios.post('/MuMenuItem/EditOrderNumbers', e);
  return data;
};
export const deleteMenuItem = async (e) => {
  const { data } = await axios.post('/MuMenuItem/Delete', e);
  return data;
};

// export const editGalleryAlbumItem = async (e) => {
//   const { data } = await axios.post('/GlAlbumItem/Edit', e);
//   return data;
// };

// export const deleteGalleryAlbumItem = async (id) => {
//   const mutation = await axios.post(`/GlAlbumItem/Delete`, { id });
//   const data = mutation.data.data;
//   const isDone = mutation.data.isDone;
//   return { data, isDone };
// };
