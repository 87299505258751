import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../../../SharedComponents/Loading';
import Notification from '../../../../SharedComponents/Notification';
import ChevronRight from '../../../../SharedComponents/Svg/ChevronRight';
import ToggleSectionStatus from '../ToggleSectionStatus';
import AddTabItem from './AddTabItem';
import { addTab, addTabItem, editTab } from './ApiTab';
import { getSectionById } from '../ApiSections';
import TabItem from './TabItem';
import DeleteSection from '../DeleteSection';
import EditSection from '../EditSection';
import { useTranslation } from 'react-i18next';
import SectionHeader from '../SectionHeader';
import LanguageList from '../../../../SharedComponents/LanguageList';

export default function Tab(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { section, languages, provided } = props;
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const addTabItemMutation = useMutation(addTabItem, {
    onSuccess: async (data) => {
      if (data.isDone) {
        // queryClient.invalidateQueries(['sections']);
        // queryClient.invalidateQueries('sections');
        queryClient.invalidateQueries(['sections']);

        queryClient.resetQueries('section');
      }
    },
  });

  const createTab = useMutation(addTab, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['sections']);
      }
    },
  });

  const editTabMutation = useMutation(editTab, {
    onSuccess: async (data) => {
      if (data.isDone) {
        // queryClient.invalidateQueries(['sections']);
        queryClient.invalidateQueries(['sections']);
        queryClient.resetQueries(['sections']);
      }
      queryClient.resetQueries(['sections']);
    },
  });

  const onSaveSettings = async (values) => {
    try {
      await editTabMutation.mutate(values.pgTab);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {editTabMutation.isSuccess && <Notification message={t('Tab Updated')} />}

      <div className="block bg-white w-full shadow border mb-4">
        <SectionHeader
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section={section}
          languageId={languageId}
          provided={provided}
        />{' '}
        {isOpen && (
          <TabInner
            section={section}
            languages={languages}
            languageId={languageId}
            isOpen={isOpen}
            addTabItemMutation={addTabItemMutation}
            editTabMutation={editTabMutation}
            onSaveSettings={onSaveSettings}
            setLanguageId={setLanguageId}
          />
        )}
        {addTabItemMutation.isSuccess && <Notification message="Tab Item Added" />}
      </div>
    </>
  );
}

const TabInner = (props) => {
  const { section, isOpen, languages, setLanguageId, languageId, addTabItemMutation, editTabMutation, onSaveSettings } =
    props;
  const { t, i18n } = useTranslation();
  const Alignments = [
    { id: false, name: 'Horizontal' },
    { id: true, name: 'Vertical' },
  ];

  const { data, isLoading, isError } = useQuery(['section', { id: section.id }], getSectionById, {
    staleTime: 90000,
  });

  const [viewUi, setViewUi] = useState(false);
  useEffect(() => {
    setLanguageId(languages?.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  if (isLoading)
    return (
      <div className="relative bg-white h-64">
        <Loading />
      </div>
    );

  if (isError) return 'error';
  return (
    <Formik initialValues={data.data}>
      {({ values, setFieldValue, setValues }) => {
        return (
          <Form>
            <div className={`bg-white px-4  overflow-hidden ${isOpen ? 'py-3 h-full  ' : '  py-0 h-0'}`}>
              <div className="">
                <div className="flex justify-between relative items-center">
                  <div className="flex transform -translate-y-1 relative z-10">
                    {t('Section Type')} : {t('Tabs')}
                  </div>
                  <div>
                    <div>
                      <LanguageList
                        languages={languages.data?.data}
                        languageId={languageId}
                        setLanguageId={setLanguageId}
                        className="flex justify-end transform translate-y-1 relative z-10 mb-4"
                      />
                    </div>
                  </div>
                  <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />
                </div>
              </div>
              <div className="bg-gray-100 p-4">
                <div className="flex justify-between">
                  <div> {t('Section Settings')} </div>
                  {/* <div className='flex space-x-2'>
                    <button
                      type='button'
                      onClick={() => {
                        setViewUi(false);
                      }}
                      className={`${
                        viewUi === false
                          ? 'bg-darkblue text-white'
                          : 'bg-gray-400 text-white'
                      } p-1 rounded shadow focus:outline-none `}
                    >
                      <PecnilAlt />
                    </button>
                    <button
                      type='button'
                      onClick={() => {
                        setViewUi(true);
                      }}
                      className={`${
                        viewUi
                          ? 'bg-lightgreen text-white'
                          : 'bg-gray-400 text-white'
                      }  p-1 rounded shadow focus:outline-none `}
                    >
                      <Eye />
                    </button>
                  </div> */}
                </div>
                <hr className="mt-2 mb-4 block" />
                <div className="grid grid-cols-3 gap-6 items-center flex">
                  <div>
                    <Field
                      className="border rounded p-2 w-full"
                      as="select"
                      name="pgTab.isVertical"
                      onChange={(e) => {
                        setFieldValue('pgTab.isVertical', e.target.value);
                      }}
                    >
                      {Alignments.map((Alignment, index) => (
                        <option value={Alignment.id} key={Alignment.id}>
                          {t(Alignment.name)}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    type="button"
                    onClick={() => {
                      onSaveSettings(values);
                    }}
                    className="bg-darkblue focus:outline-none space-x-1 text-sm font-bold text-white flex items-center hover:bg-darkbluehover shadow  px-4 py-2 rounded transition duration-300 ease-in-out"
                  >
                    {t('Save Settings')}
                  </button>
                </div>
              </div>
              {values &&
                values?.pgTab?.pgTabItems &&
                values?.pgTab.pgTabItems
                  .sort(function (a, b) {
                    return a.orderNum - b.orderNum;
                  })
                  .map((pgTabItem, index) => (
                    <FieldArray
                      key={index}
                      name="pgTab.pgTabItems"
                      render={(arrayHelpers) => (
                        <>
                          <TabItem
                            viewUi={viewUi}
                            setFieldValue={setFieldValue}
                            languageId={languageId}
                            pgTabItem={pgTabItem}
                            pgTabItemIndex={index}
                            pgTabItemsHelper={arrayHelpers}
                          />
                        </>
                      )}
                    />
                  ))}

              <div className="flex justify-end py-4">
                {/* <AddCardItem
              pgTabId={values.pgTabId}
              addCardItemMutation={addCardItemMutation}
            /> */}
                <AddTabItem
                  orderNumber={values?.pgTab?.pgTabItems.length}
                  addTabItemMutation={addTabItemMutation}
                  tabCardId={values.pgTabId}
                />
                {/* <button
              type='button'
              onClick={() => {
                onSubmit(values);
              }}
              className='bg-lightgreen py-2 px-2 flex justify-center text-center h-full text-sm font-bold text-white items-center hover:bg-lightgreenhover text-centerhover shadow  rounded transition duration-300 ease-in-out'
            >
              Save Section
            </button> */}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
