import React from 'react';

export default function Excel(props) {
  const { className } = props;
  return (
    <svg className={`${className ? className : ' text-white w-6 h-6'}`}>
      <g id="prefix__Group_1358" data-name="Group 1358" transform="translate(-220.862 -478.894)">
        <path
          id="prefix__Path_2952"
          data-name="Path 2952"
          fill="currentColor"
          d="M1048.839 759.892v-9.214c0-.913.4-1.311 1.308-1.311h10.234a2.9 2.9 0 012.135.882c1.181 1.186 2.369 2.366 3.549 3.553a2.8 2.8 0 01.839 2.037v13.33c0 .885-.407 1.3-1.285 1.3h-15.52a1.122 1.122 0 01-1.261-1.289l.001-9.288zm1.447.008c0 2.882.005 5.764-.007 8.646 0 .346.094.449.444.448q7.154-.016 14.309 0c.339 0 .421-.1.42-.43q-.016-5.644 0-11.289c0-.32-.1-.4-.406-.4-1.472.014-2.945.007-4.417.006a1.118 1.118 0 01-1.214-1.221c0-1.472-.008-2.945.006-4.417 0-.31-.085-.407-.4-.406q-4.153.018-8.306 0c-.37 0-.432.13-.43.458.006 2.867.001 5.736.001 8.605zm10.607-8.988c-.016.215-.031.324-.031.434 0 1.221.01 2.442-.008 3.663 0 .325.111.406.415.4 1.221-.013 2.442 0 3.663-.007.118 0 .235-.021.445-.041a3.613 3.613 0 00-.36-.552q-1.765-1.786-3.551-3.552a3.135 3.135 0 00-.572-.344z"
          transform="translate(-827.977 -270.472)"
        />
        <path
          id="prefix__Path_2953"
          data-name="Path 2953"
          fill="currentColor"
          d="M1119.327 870.238l-.913.054 1.568 2.329 1.489-2.322-.873-.059c0-.328-.015-.653.011-.974a.316.316 0 01.224-.21q1.378-.023 2.757 0a.316.316 0 01.233.2c.026.322.011.648.011 1.044-.744-.257-.98.286-1.284.725-.523.755-1.039 1.513-1.573 2.26a.508.508 0 00.009.7c.542.725 1.049 1.475 1.572 2.214.452.64.453.639 1.243.722v1.172h-3.167c-.025 0-.071 0-.072-.006a7.908 7.908 0 01-.118-.956c0-.077.174-.2.287-.227a2.957 2.957 0 01.618-.01l-1.579-2.383-1.484 2.325a2.3 2.3 0 00.281.055c.583.023.583.021.583.621v.553h-3.25c0-.332-.017-.657.013-.978.007-.073.19-.194.277-.182a.757.757 0 00.786-.444c.579-.883 1.182-1.75 1.789-2.615a.458.458 0 00.015-.631 89.932 89.932 0 01-1.869-2.7.585.585 0 00-.62-.25c-.266.028-.356-.077-.351-.339.018-1-.112-.872.854-.878.718-.005 1.435.007 2.153 0 .27 0 .4.078.381.365-.014.264-.001.537-.001.825z"
          transform="translate(-889.97 -381.105)"
        />
      </g>
    </svg>
  );
}
