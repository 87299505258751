// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Settings: 'الإعدادات',
  'Contact Us': 'إتصل بنا',
  Seo: 'صداقة محركات البحث',
  Logo: 'شعار',
  Favicon: 'رمزية الموقع',
  'Social Media': 'وسائل التواصل الاجتماعي',
  'Website Title': 'عنوان الموقع',
  'CMS Title': 'عنوان لوحة التحكم',
  'SEO Description': 'وصف سيو',
  keywords: 'الكلمات الدالة',
  'Settings Updated': 'تم تحديث الإعدادات',
  'Favicon 32 x 32': 'رمزية الموقع ( 32x32 )',
  'Favicon 180 x 180': 'رمزية الموقع ( 180x180 )',
  'Favicon 192 x 192': 'رمزية الموقع ( 192x192 )',
  'Favicon 270 x 270': 'رمزية الموقع ( 270x270 )',
  'Save Branch': 'حفظ فرع',
  'Add New Branch': 'إضافة فرع جديد',
  'Add Branch': 'إضافة فرع',
  'whatsApp Number': 'رقم الواتساب',
  Address: 'عنوان',
  Email: 'بريد إلكتروني',
  Location: 'الموقع',
  Telephones: 'الهواتف',
  faxs: 'الفاكسات',
  'Are you sure you want to delete this Telephone? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف هذا الهاتف؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Delete Telephone': 'حذف الهاتف',
  'Delete Branch': 'حذف فرع',
  'Are you sure you want to delete this Branch? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف هذا الفرع؟ سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'Save Branches Order': 'حفظ ترتيب الفروع',
  'Branches Order Have Been Changed Successfully': 'تم تحديث ترتيب الفروع بنجاح',
  'Network Error': 'خطأ فالإتصال',
  'Branch Added': 'تم إضافة فرع جديد بنجاح',
  'Branch, Deleted': 'تم حذف الفرع بنجاح',
  'Email Settings': 'إعدادات البريد الإلكتروني',
  Password: 'كلمه السر',
  Port: 'بورت',
  Host: 'الخادم',
  'Email Settings, Updated': 'تم تحديث إعدادات البريد الإلكتروني بنجاح',
  'Email Settings, Added': 'تم اضافه إعدادات البريد الإلكتروني بنجاح',
  'Open Hour': 'اوقات العمل',
  From: 'من',
};
