import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import Notification from '../../SharedComponents/Notification';
import { AddComplaintStatus } from './ApiComplaints';

export default function DenyComplaint(props) {
  const { t } = useTranslation();
  const { submitId } = useParams();
  const queryClient = useQueryClient();
  const AddComplaintStatusMutation = useMutation(AddComplaintStatus, {
    onSuccess: () => {
      queryClient.refetchQueries('getComplaintBySubmitId');

      toast(<Notification message={t('Complaint Denied')} />);
    },
  });
  const onClick = () => {
    console.log('clicked');
    AddComplaintStatusMutation.mutate({
      complaintStatus: 3,
      submitId: submitId,
    });
  };
  return (
    <button
      onClick={onClick}
      type="button"
      className="bg-red-500 hover:bg-red-700 text-white px-4 py-2 text-sm rounded-md"
    >
      {t('Deny')}
    </button>
  );
}
