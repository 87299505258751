import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';
import { dir } from '../../utils/localisation';
import { getFormQuestionOption } from '../../Views/Forms/ApiForms';

export default function SubNav(props) {
  const { typeId, purposeOfRequest } = props;
  let initialState = {
    typeId: typeId,
    paginationFilter: {
      pageNumber: 1,
      pageSize: 99999,
      isPaging: false,
      langId: 0,
    },
  };

  const { data, paginationResult, isDone, isLoading, isError, refetch } = useQuery(
    ['formQuestionOption', initialState],
    getFormQuestionOption,
    {
      staleTime: 99900,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  return (
    <>
      {!isLoading &&
        data.data.map((subMenu) => {
          return (
            <NavLink
              key={subMenu.id}
              to={
                subMenu.frmQoptionLngs[0].title === 'Complaints'
                  ? `/complaints/${subMenu.id}/${subMenu.frmQoptionLngs[0].title}`
                  : `/answers/${subMenu.id}/${subMenu.frmQoptionLngs[0].title}/${purposeOfRequest}`
              }
              // to={`/requests/${subMenu.id}/0`}
              activeClassName="text-[#fdce00] border-white"
              activeStyle={{
                borderColor: '#fff',
              }}
              className="flex justify-between bg-[#222222] items-center duration-200 text-sm font-regular py-2 pl-8  border-gray-800  hover:text-[#fdce00]"
            >
              <span className="mx-4">
                <div className={`flex  space-x-4 items-center ${dir('', 'space-x-reverse')}`}>
                  <div className="w-1 h-1 bg-[#bababa] rounded-full "></div>
                  <span>
                    {dir(
                      `${subMenu.frmQoptionLngs.filter((item) => item.languageId === 2)[0].title}`,
                      `${subMenu.frmQoptionLngs.filter((item) => item.languageId === 1)[0].title}`
                    )}
                  </span>
                </div>
              </span>
            </NavLink>
          );
        })}
    </>
  );
}
