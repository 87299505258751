import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../../SharedComponents/Notification';
import { toggleStatusMenuItem } from './ApiMenu';

export default function ToggleMenuItemStatus(props) {
  const { isActive, id } = props;
  const [activeToggle, setActiveToggle] = useState(isActive);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate, data } = useMutation(toggleStatusMenuItem, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['Menu']);
    },
  });
  const handleClick = async (e) => {
    setActiveToggle(!activeToggle);
    const newData = { menuItemId: id, isActive: !activeToggle };
    // setNewUserRole(parseInt(e.role));

    try {
      await mutate(newData);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <button onClick={handleClick} className="flex items-center cursor-pointer focus:outline-none">
        {/* <!-- toggle --> */}
        <div className="relative">
          {/* <!-- line --> */}
          <div
            className={` w-12 h-6 ${activeToggle ? 'bg-lightgreen' : 'bg-gray-400'}  rounded-full shadow-inner`}
          ></div>
          {/* <!-- dot --> */}
          <div
            className={`transition-all ease-in-out duration-700 absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 ${
              activeToggle ? 'right-0' : 'left-0'
            } `}
          ></div>
        </div>
        {/* <!-- label --> */}
      </button>
      {data && data.isDone === true && <Notification message={t('Menu Item Status Have Been Changed Successfully')} />}
      {data && data.isDone === false && <Notification error={true} message={t('Network Error')} />}
    </>
  );
}
