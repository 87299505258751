import Photograph from '../../SharedComponents/Svg/Photograph';
import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getFolder, getFolders, getLanguages, moveAsset } from './ApiAssets';
import Loading from '../../SharedComponents/Loading';
import youtube from '../../assets/img/youtube.svg';
import vimeo from '../../assets/img/vimeo.svg';
import AddFolder from './AddFolder';
import UploadAsset from './UploadAsset';
import ImageTypeContainer from './Components/ImageTypeContainer';
import AudioTypeContainer from './Components/AudioTypeContainer';
import DocumentTypeContainer from './Components/DocumentTypeContainer';
import Microphone from '../../SharedComponents/Svg/Microphone';
import VideoCamera from '../../SharedComponents/Svg/VideoCamera';
import LinkIcon from '../../SharedComponents/Svg/LinkIcon';
import Document from '../../SharedComponents/Svg/Document';
import VideoTypeContainer from './Components/VideoTypeContainer';
import YoutubeTypeContainer from './Components/YoutubeTypeContainer';
import VimeoTypeContainer from './Components/VimeoTypeContainer';
import LinkTypeContainer from './Components/LinkTypeContainer';
import { useParams } from 'react-router';
import Notification from '../../SharedComponents/Notification';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import LanguageList from '../../SharedComponents/LanguageList';

export default function Assets() {
  const params = useParams();
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState(params.activeTab <= 7 ? parseInt(params.activeTab) : 1);
  const [searchPermissions, setSearchPermissions] = useState('');
  const [languageId, setLanguageId] = useState(1);
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });

  useEffect(() => {
    setLanguageId(languages?.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);
  // entries to control pageSize
  const [entries, setEntries] = useState(10);
  // pageNumber to Control pageNumber
  const [pageNumber, setPageNumber] = useState(1);
  const [publicFolderId, setPublicFolderId] = useState(1);
  // initial state for APi call
  let initialState = {
    typeId: activeTab,
    paginationFilter: {
      pageNumber: pageNumber,
      pageSize: entries,
      isPaging: false,
      langId: 0,
    },
  };
  // Query to load Data
  const { data, isDone, isLoading, isError } = useQuery(['assetFolders', initialState], getFolders, {
    staleTime: 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      setPublicFolderId(data?.data[0]?.id !== undefined ? data?.data[0]?.id : 1);
    },
  });

  // Query to load publicFolder Data
  const publicFolder = useQuery(['assetFolder', publicFolderId], getFolder, {
    staleTime: 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  const tabData = [
    {
      svg: <Photograph />,
      title: t('Images'),
    },
    {
      svg: <Microphone />,
      title: t('Audio'),
    },
    {
      svg: <VideoCamera />,
      title: t('Video'),
    },
    {
      svg: <img src={youtube} alt="youtube" />,
      title: t('Youtube'),
    },
    {
      svg: <img src={vimeo} alt="youtube" />,
      title: t('Vimeo'),
    },
    {
      svg: <LinkIcon />,
      title: t('Link'),
    },
    {
      svg: <Document />,
      title: t('Document'),
    },
  ];

  const queryClient = useQueryClient();
  // query to Load Images
  const [assetCollection, setAssetCollection] = useState([]);
  const moveAssetToFolder = useMutation(moveAsset, {
    onSuccess: () => {
      queryClient.invalidateQueries(['assetFolders']);
      queryClient.invalidateQueries(['assetFolder']);
    },
  });
  const onMoveAssetToFolder = (e) => {
    const newData = {
      folderId: parseInt(e.target.value),
      assestsIds: assetCollection,
    };

    try {
      moveAssetToFolder.mutate(newData);
      setAssetCollection([]);
    } catch (error) {}
  };

  if (isLoading) return <Loading />;
  if (isError) return 'error';

  // Fetch Folders Containing Data
  return (
    <>
      {moveAssetToFolder.isSuccess === true && <Notification message={t('Assets Have Been Moved Successfully')} />}
      {moveAssetToFolder && moveAssetToFolder.data && moveAssetToFolder.data.isDone === false && (
        <Notification error={true} message={t('Please Select Assets To Move')} />
      )}
      {/* <Notification /> */}

      <header className="flex justify-between items-center mb-4">
        <BreadCrumbs to="/assets" name={t('Assets')} />
        <div>
          <UploadAsset id={publicFolderId} typeId={activeTab} />
        </div>
      </header>

      <div className="bg-white rounded border shadow-md p-4 ">
        <div className="flex justify-between relative items-center mb-4">
          <div className="flex transform  relative z-10">
            {tabData.map((e, index) => {
              return (
                <div
                  key={index}
                  className={` ${
                    activeTab === index + 1 ? 'text-pagetabhover border-pagetabhover' : ''
                  } flex justify-center items-center text-gray-500   hover:text-pagetabhover pb-4 px-4 border-b-2 hover:border-pagetabhover transition duration-300 ease-in-out cursor-pointer `}
                  onClick={(e) => setActiveTab(index + 1)}
                >
                  {e.svg}
                  <span className={`${i18n.language === 'en' ? 'ml-1' : 'mr-1'}`}>{e.title}</span>
                </div>
              );
            })}
          </div>
          <LanguageList
            className="flex transform  relative z-10 "
            languages={languages.data?.data}
            languageId={languageId}
            setLanguageId={setLanguageId}
          />
          <hr className="border w-full absolute top-0 left-0 z-0 mt-10" />
        </div>

        <div className="flex items-center justify-between">
          <div>
            <div className="flex items-center space-x-4">
              <div>
                <AddFolder typeId={activeTab} />
              </div>
              <div>
                <div
                  className={`flex items-center ${
                    i18n.language === 'an' ? 'space-x-4' : 'space-x-reverse space-x-4'
                  } text-gray-600`}
                >
                  <span>{t('Move To')} </span>
                  <select onChange={onMoveAssetToFolder} className="border rounded w-64 py-2 px-1">
                    {data &&
                      !isLoading &&
                      data.data.map((e) => {
                        return (
                          <option key={e.id} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center space-x-4 text-gray-600">
              {/* <span>Search </span>
              <input className='border rounded w-64 py-2 px-1' /> */}
            </div>
          </div>
        </div>
        {activeTab === 1 && (
          <ImageTypeContainer
            assetCollection={assetCollection}
            setAssetCollection={setAssetCollection}
            data={data}
            publicFolder={publicFolder}
            languageId={languageId}
            activeTab={activeTab}
          />
        )}
        {activeTab === 2 && publicFolder.data && (
          <AudioTypeContainer
            assetCollection={assetCollection}
            setAssetCollection={setAssetCollection}
            isLoading={isLoading}
            data={data}
            publicFolder={publicFolder}
            languageId={languageId}
            activeTab={activeTab}
          />
        )}
        {activeTab === 3 && (
          <VideoTypeContainer
            assetCollection={assetCollection}
            setAssetCollection={setAssetCollection}
            data={data}
            publicFolder={publicFolder}
            languageId={languageId}
            activeTab={activeTab}
          />
        )}
        {activeTab === 4 && (
          <YoutubeTypeContainer
            assetCollection={assetCollection}
            setAssetCollection={setAssetCollection}
            data={data}
            publicFolder={publicFolder}
            languageId={languageId}
            activeTab={activeTab}
          />
        )}
        {activeTab === 5 && (
          <VimeoTypeContainer
            assetCollection={assetCollection}
            setAssetCollection={setAssetCollection}
            data={data}
            publicFolder={publicFolder}
            languageId={languageId}
            activeTab={activeTab}
          />
        )}
        {activeTab === 6 && (
          <LinkTypeContainer
            assetCollection={assetCollection}
            setAssetCollection={setAssetCollection}
            data={data}
            publicFolder={publicFolder}
            languageId={languageId}
            activeTab={activeTab}
          />
        )}
        {activeTab === 7 && (
          <DocumentTypeContainer
            assetCollection={assetCollection}
            setAssetCollection={setAssetCollection}
            searchPermissions={searchPermissions}
            isLoading={isLoading}
            data={data}
            publicFolder={publicFolder}
            languageId={languageId}
            activeTab={activeTab}
          />
        )}
      </div>
    </>
  );
}
