import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import Loading from '../../SharedComponents/Loading';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import TablePagination from '../../SharedComponents/TablePagination';
import TableSearch from '../../SharedComponents/TableSearch';
import { dir } from '../../utils/localisation';
import { getLanguages } from '../Settings/ApiSettings';
import { GetAllEmployee } from './ApiEmployees';
import LanguageList from '../../SharedComponents/LanguageList';
export default function Users() {
  // to control how many rows to show in table
  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [languageId, setLanguageId] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLanguage, setSearchlanguage] = useState(0);
  const onSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    setSearchlanguage(languageId);
  };
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  let initialState = {
    pageNumber: 0,
    pageSize: 0,
    numberOfPages: 1,
    isPaging: false,
    isFilter: false,
    langId: 0,
    isSearch: true,
    newsnumber: 0,
    parentId: 0,
    checkIsRequest: false,
    isRequest: true,
    filter: [
      {
        filterBy: 'string',
        filterType: 'string',
        dateFilteration: {
          from: 'string',
          to: 'string',
        },
        searchTerm: [
          {
            langId: 0,
            term: 'string',
          },
        ],
      },
    ],
    searchFilter: {
      langId: 0,
      term: 'string',
    },
    optionId: 'string',
  };

  const parms = useParams();
  const { role } = parms;
  // const roles = useQuery(['roles'], getRoles);

  const { data, isLoading, isError } = useQuery(['employees', initialState], GetAllEmployee, {
    staleTime: 2000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      pagination(data);
    },
  });

  console.log(data, 'user');

  const { t, i18n } = useTranslation();

  const tabltHeader = [{ title: t('Name') }, { title: t('options') }];
  const [paginationCount, setPaginationCount] = useState([]);
  function pagination(data) {
    let paginationCount = [];
    for (var i = 1; i <= data.paginationResult?.numberOfPages; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }

  // to control how many rows to show in table
  const onChangeEntries = (e) => {
    setEntries(e.target.value);
  };

  return (
    <>
      <header className="flex justify-between">
        <BreadCrumbs to="/users" name={t('Users')} />
        <div className={`flex items-center space-x-3 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
          {/* <DownloadPdf languageId={languageId} />
          <DownloadExcel languageId={languageId} /> */}
        </div>
      </header>

      {/* Table */}
      <div className="bg-white rounded border border-[#e6e7e9] shadow-md mt-5 ">
        <div className="pt-6 px-5">
          <div className="border-[#eaedf0] border-b flex    justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
              <h2 className="text-sm font-bold uppercase">{t('Users')}</h2>
              <h4 className="bg-move text-white py-1 px-3 rounded-md">{data?.paginationResult?.dataCount}</h4>
            </div>

            <div className="flex transform  relative z-10 ">
              {languages && (
                <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
              )}
            </div>
          </div>
        </div>
        <div className=" px-5  mt-4">
          <div className=" flex  items-center  justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${dir('', 'space-x-reverse')}`}>
              <Link
                to="/createEmployee"
                className={`bg-lightgreen ${
                  i18n.language === 'en' ? '' : 'space-x-reverse'
                } space-x-1 text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
              >
                <PlusCircle className="w-5 h-5" /> <span>{t('Add Employee')}</span>
              </Link>
            </div>

            <div className="flex transform  relative z-10 ">
              <TableSearch entries={entries} onChangeEntries={onChangeEntries} onSearch={onSearch} />
            </div>
          </div>
        </div>
        {isLoading && (
          <div className="relative bg-white h-72">
            <Loading />
          </div>
        )}
        {isError && 'loading'}
        {data && (
          <div className="flex flex-col  overflow-hidden ">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y-2 divide-gray-200">
                    <thead className=" text-sm font-bold text-black">
                      <tr>
                        {tabltHeader.map((e) => {
                          return (
                            <th
                              key={e.title}
                              scope="col"
                              className={`px-6 py-3 ${
                                i18n.language === 'en' ? 'text-left' : 'text-right'
                              }    uppercase font-bold text-sm `}
                            >
                              {e.title}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
                      {data.data.map((employee) => {
                        return (
                          <tr key={employee.id} className="">
                            <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                              <div className=" ">
                                {languageId === 1 && employee.arabicName}
                                {languageId === 2 && employee.englishName}
                              </div>
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap flex items-center space-x-2">
                              {/* <Link
        to={'/editUser/' + user.id}
        className="bg-green-300 text-green-800 hover:bg-green-400  p-1 rounded transition duration-300 ease-in-out"
      >
        <PecnilAlt className="w-5 h-5" />
      </Link> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  {/* <TablePagination
                    data={data}
                    paginationCount={paginationCount}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
