import axios from 'axios';

// Comon API
export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};

export const getSetting = async (e) => {
  const query = await axios.get('/Setting/Get');
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  console.log(paginationResult);
  return { data, isDone, paginationResult };
};
export const editSetting = async (e) => {
  const mutation = await axios.post(`/Setting/Edit`, e);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};

export const addSetting = async (e) => {
  const mutation = await axios.post(`/Setting/Add`, e);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};

export const deleteSetting = async (id) => {
  const mutation = await axios.post(`/Setting/Delete`, { id });
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};

export const deleteTelephone = async (e) => {
  const { data } = await axios.post('/Setting/DeleteTelephone', e);
  return data;
};

export const addBranch = async (e) => {
  const mutation = await axios.post(`/Setting/AddBranch`, e);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};
export const editBranch = async (e) => {
  const mutation = await axios.post(`/Setting/EditBranch`, e);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};

export const deleteBranch = async (e) => {
  const { data } = await axios.post('/Setting/DeleteBranch', e);
  return data;
};

export const saveBranchReorder = async (e) => {
  const { data } = await axios.post('/Setting/EditBrachesOrderNumbers', e);
  return data;
};
export const getEmailSettings = async () => {
  const { data } = await axios.get('Setting/GetEmail');
  return data;
};

export const updateEmialSettings = async (e) => {
  const { data } = await axios.post('/Setting/EditEmail', e);
  return data;
};

export const addEmialSettings = async (e) => {
  const { data } = await axios.post('/Setting/AddEmail', e);
  return data;
};
