import { useQuery } from 'react-query';

import { Fragment, useEffect, useState } from 'react';
import Loading from '../../SharedComponents/Loading';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import TablePagination from '../../SharedComponents/TablePagination';
import LanguageList from '../../SharedComponents/LanguageList';
import Excel from '../../SharedComponents/Svg/Excel';
import Print from '../../SharedComponents/Svg/Print';
import { getLanguages } from '../Page/Pages/ApiPage';
import { Formik, Field } from 'formik';
import ChevronDown from '../../SharedComponents/Svg/ChevronDown';
import Close from '../../SharedComponents/Svg/Close';
import Search from '../../SharedComponents/Svg/Search';
import Plus from '../../SharedComponents/Svg/Plus';
import Minus from '../../SharedComponents/Svg/Minus';
import { getVisitorsReport } from './ApiReports';
import VisitorsList from './VisitorsList';
import { dir } from '../../utils/localisation';

export default function Reports() {
  const params = useParams();
  const { typeId } = params;
  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [languageId, setLanguageId] = useState(1);
  const [activeTable, setActiveTable] = useState(undefined);
  const { t, i18n } = useTranslation();

  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });

  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);
  const tabltHeader1 = [
    { title: t('ID') },
    { title: t('EN Name') },
    { title: t('Governorate') },
    { title: t('Age Group.') },
    { title: t('Reasons of visit') },
    { title: t('Registration Method') },
  ];

  const [searchFilter, setSearchFilter] = useState({
    pageNumber: 0,
    pageSize: 0,
    numberOfPages: 0,
    isPaging: true,
    isFilter: false,
    langId: 0,
    isSearch: true,
    newsnumber: 0,
    parentId: 0,
    checkIsRequest: false,
    isRequest: true,
    filter: [
      {
        filterBy: 'string',
        filterType: 'string',
        dateFilteration: {
          from: 'string',
          to: 'string',
        },
        searchTerm: [
          {
            langId: 0,
            term: 'string',
          },
        ],
      },
    ],
    searchFilter: {
      langId: 0,
      term: 'string',
    },
    optionId: 0,
  });
  // getVisitorsReport
  const { data, isLoading } = useQuery(['getVisitorsReport'], getVisitorsReport);
  const datax = [
    {
      id: 1,
      frmFormLngs: [
        {
          id: 1,
          languageId: 1,
          title: 'test',
        },
      ],
      createDate: '01-12-2020',
      mobile: '01069926643',
      idNo: '237498',
      reason: 'training',

      registrationMethod: 'Phone',
    },
    {
      id: 1,
      frmFormLngs: [
        {
          id: 1,
          languageId: 1,
          title: 'test',
        },
      ],
      createDate: '01-12-2020',
      mobile: '01069926643',
      idNo: '237498',
      reason: 'training',

      registrationMethod: 'Phone',
    },
  ];

  let paginationCount = [];

  //  if (isLoading) return <Loading />;
  // if (isError) return 'error';

  // for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
  //   paginationCount.push(i);
  // }
  // to control how many rows to show in table

  const onChangeEntries = (e) => {
    setEntries(e.target.value);
  };

  return (
    <>
      <header className="flex justify-between items-center mb-5">
        <BreadCrumbs to="/formQuestionOptionsTypes" name={t('Form Question Options')} />

        <div className={`flex items-center space-x-3 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
          <button
            className={` py-2 rounded px-5 flex text-white  bg-[#7e22ce] space-x-2 items-center text-sm font-bold ${dir(
              '',
              'space-x-reverse'
            )}`}
          >
            <Print />
            <span>{t('Print')}</span>
          </button>
          <button
            className={`py-2 rounded px-5 flex text-white  bg-lightgreen space-x-2 items-center text-sm font-bold ${dir(
              '',
              'space-x-reverse'
            )} `}
          >
            <Excel />
            <span>{t('Excel')}</span>
          </button>
        </div>
      </header>

      <div className="bg-white rounded-xl border shadow-md overflow-hidden">
        <div className="p-6 px-5">
          <div className="border-[#eaedf0] border-b flex   justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
              <h2 className="text-sm font-bold uppercase">{t('New Visitors')}</h2>
            </div>

            <div className="flex transform  relative z-10 ">
              {languages && (
                <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
              )}
            </div>
          </div>
        </div>
        <div className="bg-[#e6e7e9] border border-[#e6e7e9] py-4 px-6">
          <h2 className="text-sm font-bold uppercase">{t('New Visitors')}</h2>
          <Formik>
            {() => (
              <>
                <div className="grid grid-cols-3 md:gap-xo-4 gap-x-3 lg:gap-x-7 ">
                  <label className="pt-4 w-full">
                    <p className="text-[#666666] text-sm font-medium pb-2">Date frome</p>
                    <Field type="date" className="w-full p-3 border border-[#d1d5db] rounded-md" />
                  </label>
                  <label className="pt-4 w-full">
                    <p className="text-[#666666] text-sm font-medium pb-2">to</p>
                    <Field type="date" className="w-full p-3 border border-[#d1d5db] rounded-md" />
                  </label>
                  {/* <VisitorsList /> */}
                  <label className="pt-4 w-full">
                    <p className="text-[#666666] text-sm font-medium pb-2">Registeration Type</p>
                    <select className="w-full pb-[18px] p-3 border border-[#d1d5db] rounded-md">
                      <option value="Select Folder">received financial assistance in germany </option>
                      <option value="Movies">Movies </option>
                      <option value="Pictures">30 </option>
                    </select>
                  </label>
                  {isAdvanced && (
                    <>
                      <label className="pt-4 w-full">
                        <p className="text-[#666666] text-sm font-medium pb-2">Registeration Type</p>
                        <select className="w-full pb-[18px] p-3 border border-[#d1d5db] rounded-md">
                          <option value="Select Folder">received financial assistance in germany </option>
                          <option value="Movies">Movies </option>
                          <option value="Pictures">30 </option>
                        </select>
                      </label>
                      <label className="pt-4 w-full">
                        <p className="text-[#666666] text-sm font-medium pb-2">Registeration Type</p>
                        <select className="w-full pb-[18px] p-3 border border-[#d1d5db] rounded-md">
                          <option value="Select Folder">received financial assistance in germany </option>
                          <option value="Movies">Movies </option>
                          <option value="Pictures">30 </option>
                        </select>
                      </label>
                      <label className="pt-4 w-full">
                        <p className="text-[#666666] text-sm font-medium pb-2">Registeration Type</p>
                        <select className="w-full pb-[18px] p-3 border border-[#d1d5db] rounded-md">
                          <option value="Select Folder">received financial assistance in germany </option>
                          <option value="Movies">Movies </option>
                          <option value="Pictures">30 </option>
                        </select>
                      </label>
                      <label className="pt-4 w-full">
                        <p className="text-[#666666] text-sm font-medium pb-2">Registeration Type</p>
                        <select className="w-full pb-[18px] p-3 border border-[#d1d5db] rounded-md">
                          <option value="Select Folder">received financial assistance in germany </option>
                          <option value="Movies">Movies </option>
                          <option value="Pictures">30 </option>
                        </select>
                      </label>
                      <label className="pt-4 w-full">
                        <p className="text-[#666666] text-sm font-medium pb-2">Registeration Type</p>
                        <select className="w-full pb-[18px] p-3 border border-[#d1d5db] rounded-md">
                          <option value="Select Folder">received financial assistance in germany </option>
                          <option value="Movies">Movies </option>
                          <option value="Pictures">30 </option>
                        </select>
                      </label>
                      <label className="pt-4 w-full">
                        <p className="text-[#666666] text-sm font-medium pb-2">Registeration Type</p>
                        <select className="w-full pb-[18px] p-3 border border-[#d1d5db] rounded-md">
                          <option value="Select Folder">received financial assistance in germany </option>
                          <option value="Movies">Movies </option>
                          <option value="Pictures">30 </option>
                        </select>
                      </label>
                    </>
                  )}
                </div>
                <div className="flex items-center justify-between pt-4">
                  <button
                    onClick={() => setIsAdvanced((e) => !e)}
                    className={`  space-x-2 flex items-center ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                  >
                    <ChevronDown className="w-5 h-5" />
                    <h4>{t(isAdvanced ? 'Less' : 'Advanced')}</h4>
                  </button>
                  <div>
                    <button className={` py-2 rounded px-5 flex text-white  bg-[#4f46e5]`}>{t('Search')}</button>
                  </div>
                </div>
              </>
            )}
          </Formik>
        </div>
        <div className="px-5 py-5">
          <p className="text-[#dd0000]">Registeration Report from 25 Mar 2020 : 10 Jun 2021 </p>
          <div className={`flex items-center pt-5 space-x-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
            <div className="flex flex-col text-center justify-center w-24   bg-[#4f46e5] text-white rounded py-1">
              <h3>365</h3>
              <h4>{t('Registeration')}</h4>
            </div>
            <div className="bg-[#f3f3f3] px-2 py-2 rounded text-[#666666] font-semibold text-sm relative min-w-[112px]">
              <h3>{t('Registeration Type')}</h3>
              <h4 className="font-medium">Received Financial Assistance In Germany</h4>
              <button className="absolute top-0 right-0 p-1">
                <Close className="w-4 h-4  " />
              </button>
            </div>
            <div className="bg-[#f3f3f3] px-2 py-2 rounded text-[#666666] font-semibold text-sm relative min-w-[112px]">
              <h3>{t('Gender')}</h3>
              <h4 className="font-medium">Male</h4>
              <button className="absolute top-0 right-0 p-1">
                <Close className="w-4 h-4  " />
              </button>
            </div>
          </div>
        </div>
        <div className="flex px-5 py-1">
          <Formik initialValues={{ target: { value: '' } }}>
            {({ values }) => {
              return (
                <div className="flex items-center relative  w-max ml-auto ">
                  <Field
                    name="target.value"
                    onKeyDownCapture={(e) => {}}
                    className="border rounded w-64 py-2 px-2 border-[#d1d5db] text-[#373d4c]"
                    type="text"
                    placeholder={t('Search')}
                  />
                  <button
                    type="button"
                    className={`border rounded py-2 px-2 h-full flex items-center bg-move hover:bg-movehover text-white absolute  ${
                      i18n.language === 'en' ? 'right-0' : 'left-0'
                    }`}
                  >
                    <Search className="w-7 h-7" />
                  </button>
                </div>
              );
            }}
          </Formik>
        </div>
        <table className="min-w-full divide-y-2 divide-gray-200 mt-6">
          <thead className=" text-sm font-bold text-black">
            <tr>
              {tabltHeader1.map((e) => {
                return (
                  <th
                    key={e.title}
                    scope="col"
                    className={`px-6 py-3 ${
                      i18n.language === 'en' ? 'text-left' : 'text-right'
                    }    uppercase font-bold text-sm ${e.className}`}
                  >
                    {e.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
            {datax.map((form, index) => {
              return (
                <>
                  <tr key={form.id} className="">
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">{form.id}</td>

                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {form.frmFormLngs.map((e) => {
                        return (
                          <Fragment key={e.id}>
                            {languageId === e.languageId && (
                              <>
                                <div className="flex items-center space-x-2">
                                  <b>{e.title}</b>
                                </div>
                              </>
                            )}
                          </Fragment>
                        );
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">{form.mobile}</td>

                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">{form.idNo}</td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">{form.reason}</td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">{form.registrationMethod}</td>
                    <td
                      className={`px-6 py-4 whitespace-nowrap font-bold text-base flex items-center space-x-2 ${
                        i18n.language === 'en' ? '' : 'space-x-reverse'
                      }`}
                    >
                      <button
                        onClick={() => {
                          setActiveTable((e) => (e === index ? undefined : index));
                        }}
                        type="button"
                        className={`w-7 h-7 rounded-full overflow-hidden border-2 border-white shadow-md flex items-center justify-center ${
                          index === activeTable ? 'bg-red-500' : 'bg-lightgreen'
                        }  text-white`}
                      >
                        {index === activeTable ? <Minus className="w-5 h-5" /> : <Plus className="w-5 h-5" />}
                      </button>
                    </td>
                  </tr>
                  {index === activeTable && (
                    <tr className="px-6 py-4 whitespace-nowrap font-bold text-base ">
                      <div className="flex flex-col px-6 pt-4 pb-2 ">
                        <div className="grid grid-cols-2 gap-7 mb-4">
                          <h2 className="text-black text-sm font-bold">Level of Education</h2>{' '}
                          <p className="text-[#6b7280] font-bold text-sm">Master's</p>
                        </div>
                        <div className="grid grid-cols-2 gap-7 mb-4">
                          <h2 className="text-black text-sm font-bold">Level of Education</h2>{' '}
                          <p className="text-[#6b7280] font-bold text-sm">Master's</p>
                        </div>
                      </div>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                {/* <TablePagination
                  data={[]}
                  paginationCount={paginationCount}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
