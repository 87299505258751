import { Fragment } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getForms } from '../../../Forms/ApiForms';

export default function FormsList(props) {
  //TODO
  //   Error while searching by name
  const { languageId, setFieldValue, formId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [searchParam, setSearchParam] = useState('');
  const { t } = useTranslation();
  const onSearchParam = (e) => {
    setSearchParam(e.target.value);
  };
  const PagesinitialData = {
    pageNumber: 1,
    pageSize: 1,
    isPaging: false,
    langId: languageId,
  };
  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery(['forms', PagesinitialData], getForms, {
    staleTime: 360000000,
  });
  if (isLoading) return '';
  if (isError) return 'error';
  const onClick = (data) => {
    setFieldValue('pgSectionForms.formId', data.id);
    setIsOpen(false);
  };
  let defaultValue = data.data.find((e) => e.id === formId);
  return (
    <div className="relative z-10">
      <label>
        <span>{t('Form')}</span>
        <input
          onFocus={() => {
            setIsOpen(true);
          }}
          onChange={onSearchParam}
          className="border rounded  w-full mb-0 p-1"
          value={defaultValue?.frmFormLngs[0].title}
        />
      </label>

      {isOpen && (
        <div className=" bottom-0 left-0 bg-white w-full transform  pl-0 block ">
          <ul className="border rounded divide-y overflow-y-scroll  mt-2">
            {data.data
              .filter((e) => e.statusId === 1)
              .filter((e) => e.frmFormLngs[0]?.title.toLowerCase().includes(searchParam.toLowerCase()))

              .map((e) => {
                return (
                  <Fragment key={e.id}>
                    <button
                      onClick={(x) => {
                        onClick(e);
                      }}
                      type="button"
                      className={`${
                        formId === e.id ? 'bg-darkbluehover text-white' : ''
                      } w-full text-left block px-2 py-1 hover:bg-darkbluehover hover:text-white`}
                    >
                      {e.frmFormLngs.map((x) => {
                        return <Fragment key={x.id}>{languageId === x.languageId && x.title}</Fragment>;
                      })}
                    </button>
                  </Fragment>
                );
              })}
          </ul>
        </div>
      )}
    </div>
  );
}
