import { isError, useQuery, useQueryClient } from 'react-query';

import { Fragment, useEffect, useState } from 'react';
import Loading from '../../SharedComponents/Loading';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import TablePagination from '../../SharedComponents/TablePagination';
import LanguageList from '../../SharedComponents/LanguageList';
import Excel from '../../SharedComponents/Svg/Excel';
import Print from '../../SharedComponents/Svg/Print';
import { getLanguages } from '../Page/Pages/ApiPage';
import { Formik, Field, Form } from 'formik';
import ChevronDown from '../../SharedComponents/Svg/ChevronDown';
import Close from '../../SharedComponents/Svg/Close';
import Search from '../../SharedComponents/Svg/Search';
import Plus from '../../SharedComponents/Svg/Plus';
import Minus from '../../SharedComponents/Svg/Minus';
import { getVisitorsReport } from './ApiReports';
import VisitorsList from './VisitorsList';
import AgeGroupList from './AgeGroupList';
import LevelOfEducationList from './LevelOfEducationList';
import EmployeeStatusList from './EmployeeStatusList';
import DependentsList from './DependentsList';
import ReasonOfVisitList from './ReasonOfVisitList';
import RegisterationMethodList from './RegisterationMethodList';
import OccupationList from './OccupationList';
import TablePaginationNew from '../../SharedComponents/TablePaginationNew';
import { dir } from '../../utils/localisation';
import DownloadPdf from './DownloadPdf';
import DownloadExcel from './DownloadExcel';
import { DateTime } from 'luxon';

export default function VisitorsReports() {
  const params = useParams();
  const { typeId } = params;
  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [languageId, setLanguageId] = useState(1);
  const [activeTable, setActiveTable] = useState(undefined);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [filtersNames, setfiltersNames] = useState([]);
  const [paginationCount, setPaginationCount] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [searchFilter, setSearchFilter] = useState({
    pageNumber: pageNumber,
    pageSize: entries,
    numberOfPages: 0,
    isPaging: true,
    isFilter: true,
    langId: languageId,
    isSearch: false,
    newsnumber: 0,
    parentId: 0,
    checkIsRequest: false,
    isRequest: true,
    filter: [
      {
        filterBy: 'visitors',
        filterType: 'String',
        dateFilteration: {
          from: 'string',
          to: 'string',
        },
        searchTerm: [
          {
            langId: languageId,
            term: '1',
          },
        ],
      },
    ],
    searchFilter: {
      langId: languageId,
      term: searchTerm,
    },
    optionId: 0,
  });
  function pagination() {
    let paginationCount = [];
    for (var i = 1; i <= numberOfPages + 0.8; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }
  useEffect(() => {
    pagination();
    setSearchFilter((e) => ({ ...e, pageNumber: pageNumber }));
  }, [numberOfPages, pageNumber]);
  const { t, i18n } = useTranslation();

  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });

  // useEffect(() => {
  //   setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  // }, [languages.data]);
  const tabltHeader1 = [
    { title: t('ID') },
    { title: t('En Name') },
    { title: 'Gender' },
    { title: t('Governorate') },
    { title: t('Age Group.') },
    { title: t('Reasons of visit') },
    { title: t('Registration Method') },
  ];

  // getVisitorsReport
  const querClient = useQueryClient();
  const { data, isLoading, isError } = useQuery(['getVisitorsReport', searchFilter], getVisitorsReport, {
    onSuccess: (data) => {
      setNumberOfPages(data.paging.totalPages);
    },
  });

  if (isLoading) return 'loading';
  if (isError) return 'error';

  //  if (isLoading) return <Loading />;
  // if (isError) return 'error';

  // for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
  //   paginationCount.push(i);
  // }
  // to control how many rows to show in table

  const onChangeEntries = (e) => {
    setEntries(e.target.value);
  };

  const onSubmit = (e) => {
    console.log('e', e);

    let dateFilter = undefined;

    if (e.dateFrom && e.dateTo) {
      dateFilter = {
        filterBy: 'datefromto',
        filterType: 'date',
        dateFilteration: {
          from: DateTime.fromJSDate(new Date(e.dateFrom)).toFormat('dd-MM-y'),
          to: DateTime.fromJSDate(new Date(e.dateTo)).toFormat('dd-MM-y'),
        },
      };
    } else if (e.dateFrom) {
      dateFilter = {
        filterBy: 'dateform',
        filterType: 'date',
        dateFilteration: {
          from: DateTime.fromJSDate(new Date(e.dateFrom)).toFormat('dd-MM-y'),
          to: '',
        },
      };
    } else if (e.dateTo) {
      dateFilter = {
        filterBy: 'dateto',
        filterType: 'date',
        dateFilteration: {
          from: '',
          to: DateTime.fromJSDate(new Date(e.dateTo)).toFormat('dd-MM-y'),
        },
      };
    }

    const visitorsFilter = e.visitors
      ? {
          filterBy: 'visitors',
          filterType: 'String',
          dateFilteration: {
            from: 'string',
            to: 'string',
          },
          searchTerm: [
            {
              langId: languageId,
              term: e.visitors.toString(),
            },
          ],
        }
      : undefined;

    const ageGroup = e.ageGroup
      ? {
          filterBy: 'agegroup',
          filterType: 'String',
          dateFilteration: {
            from: 'string',
            to: 'string',
          },
          searchTerm: [
            {
              langId: languageId,
              term: e.ageGroup.toString(),
            },
          ],
        }
      : undefined;
    const levelofeducation = e.levelofeducation
      ? {
          filterBy: 'levelofeducation',
          filterType: 'String',
          dateFilteration: {
            from: 'string',
            to: 'string',
          },
          searchTerm: [
            {
              langId: languageId,
              term: e.levelofeducation.toString(),
            },
          ],
        }
      : undefined;

    const employeestatusFilter = e.employeestatus
      ? {
          filterBy: 'employeestatus',
          filterType: 'String',
          dateFilteration: {
            from: 'string',
            to: 'string',
          },
          searchTerm: [
            {
              langId: languageId,
              term: e.employeestatus.toString(),
            },
          ],
        }
      : undefined;

    const depondentsFilter = e.depondents
      ? {
          filterBy: 'depondents',
          filterType: 'String',
          dateFilteration: {
            from: 'string',
            to: 'string',
          },
          searchTerm: [
            {
              langId: languageId,
              term: e.depondents.toString(),
            },
          ],
        }
      : undefined;
    const reasonsofvisitFilter = e.reasonsofvisit
      ? {
          filterBy: 'reasonsofvisit',
          filterType: 'String',
          dateFilteration: {
            from: 'string',
            to: 'string',
          },
          searchTerm: [
            {
              langId: languageId,
              term: e.reasonsofvisit.toString(),
            },
          ],
        }
      : undefined;
    const registrationmethodFilter = e.registrationmethod
      ? {
          filterBy: 'registrationmethod',
          filterType: 'String',
          dateFilteration: {
            from: 'string',
            to: 'string',
          },
          searchTerm: [
            {
              langId: languageId,
              term: e.registrationmethod.toString(),
            },
          ],
        }
      : undefined;

    const occupationsFilter = e.occupations
      ? {
          filterBy: 'occupations',
          filterType: 'String',
          dateFilteration: {
            from: 'string',
            to: 'string',
          },
          searchTerm: [
            {
              langId: languageId,
              term: e.occupations.toString(),
            },
          ],
        }
      : undefined;

    const arr = [
      visitorsFilter,
      ageGroup,
      levelofeducation,
      dateFilter,
      employeestatusFilter,
      depondentsFilter,
      reasonsofvisitFilter,
      registrationmethodFilter,
      occupationsFilter,
    ];
    console.log(arr);
    const newArr = arr.filter((e) => e);
    setSearchFilter({
      ...searchFilter,
      filter: newArr,
    });
    querClient.invalidateQueries(['getVisitorsReport']);
  };
  const removeFilter = (filterBy) => {
    const newArr = searchFilter.filter.filter((item) => item.filterBy !== filterBy);
    const filtersNamesNewArr = filtersNames.filter((item) => item.filterBy !== filterBy);
    setSearchFilter({
      ...searchFilter,
      filter: newArr,
    });
    setfiltersNames([...filtersNames, ...filtersNamesNewArr]);
    querClient.invalidateQueries(['getVisitorsReport']);
  };
  console.log('searchFilter', filtersNames);
  return (
    <>
      <header className="flex justify-between items-center mb-5">
        <BreadCrumbs to="/reports/visitors" name={t('Visitors Reports')} />

        <div className={`flex items-center space-x-3 ${dir('', 'space-x-reverse')}`}>
          {/* <DownloadPdf />

          <DownloadExcel /> */}
        </div>
      </header>

      <div className="bg-white rounded-xl border shadow-md  overflow-hidden">
        <div className="p-6 px-5">
          <div className="border-[#eaedf0] border-b flex   justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${dir('', 'space-x-reverse')}`}>
              <h2 className="text-sm font-bold uppercase">{t('New Visitors')}</h2>
            </div>

            <div className="flex transform  relative z-10 ">
              {languages && (
                <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
              )}
            </div>
          </div>
        </div>
        <Formik
          initialValues={
            {
              //   visitors: 1,
            }
          }
          onSubmit={onSubmit}
        >
          {({ values, errors, setFieldValue }) => (
            <>
              <Form>
                <div className="bg-[#e6e7e9] border border-[#e6e7e9] py-4 px-6">
                  <h2 className="text-sm font-bold uppercase">{t('New Visitors')}</h2>
                  <div className="grid lg:grid-cols-3 md:gap-xo-4 gap-x-3 lg:gap-x-7 ">
                    <label className="pt-4 w-full">
                      <p className="text-[#666666] text-sm font-medium pb-2">{t('Date frome')}</p>
                      <Field type="date" name="dateFrom" className="w-full p-3 border border-[#d1d5db] rounded-md" />
                    </label>
                    <label className="pt-4 w-full">
                      <p className="text-[#666666] text-sm font-medium pb-2">{t('to')}</p>
                      <Field type="date" name="dateTo" className="w-full p-3 border border-[#d1d5db] rounded-md" />
                    </label>
                    <VisitorsList
                      title={t('Visitor Status')}
                      placeholder={t('Select Visitor Status')}
                      FieldName="visitors"
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      dropData={(data) => {
                        setfiltersNames((e) =>
                          e?.filter((item) => item.filterBy !== 'visitors').concat({ ...data, filterBy: 'visitors' })
                        );
                      }}
                    />

                    {isAdvanced && (
                      <>
                        <AgeGroupList
                          title={t('Age Group')}
                          placeholder={t('Select Age Group')}
                          FieldName="agegroup"
                          values={values}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          dropData={(data) => {
                            setfiltersNames((e) =>
                              e
                                ?.filter((item) => item.filterBy !== 'agegroup')
                                .concat({ ...data, filterBy: 'agegroup' })
                            );
                          }}
                        />
                        <LevelOfEducationList
                          title={t('Level Of Education')}
                          placeholder={t('Select Level Of Education')}
                          FieldName="levelofeducation"
                          values={values}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          dropData={(data) => {
                            setfiltersNames((e) =>
                              e
                                ?.filter((item) => item.filterBy !== 'levelofeducation')
                                .concat({ ...data, filterBy: 'levelofeducation' })
                            );
                          }}
                        />
                        <EmployeeStatusList
                          title={t('Employee status')}
                          placeholder={t('Select Employee status')}
                          FieldName="employeestatus"
                          values={values}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          dropData={(data) => {
                            setfiltersNames((e) =>
                              e
                                ?.filter((item) => item.filterBy !== 'employeestatus')
                                .concat({ ...data, filterBy: 'employeestatus' })
                            );
                          }}
                        />
                        <DependentsList
                          title={t('Depondents')}
                          placeholder={t('Select dependents')}
                          FieldName="depondents"
                          values={values}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          dropData={(data) => {
                            setfiltersNames((e) =>
                              e
                                ?.filter((item) => item.filterBy !== 'depondents')
                                .concat({ ...data, filterBy: 'depondents' })
                            );
                          }}
                        />
                        <ReasonOfVisitList
                          title={t('Reason Of Visit')}
                          placeholder={t('Select Reason Of Visit')}
                          FieldName="reasonsofvisit"
                          values={values}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          dropData={(data) => {
                            setfiltersNames((e) =>
                              e
                                ?.filter((item) => item.filterBy !== 'reasonsofvisit')
                                .concat({ ...data, filterBy: 'reasonsofvisit' })
                            );
                          }}
                        />
                        <RegisterationMethodList
                          title={t('Registeration Method')}
                          placeholder={t('Select Registeration Method')}
                          FieldName="registrationmethod"
                          values={values}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          dropData={(data) => {
                            setfiltersNames((e) =>
                              e
                                ?.filter((item) => item.filterBy !== 'registrationmethod')
                                .concat({ ...data, filterBy: 'registrationmethod' })
                            );
                          }}
                        />
                        <OccupationList
                          title={t('Occupations')}
                          placeholder={t('Select Occupations')}
                          FieldName="occupations"
                          values={values}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          dropData={(data) => {
                            setfiltersNames((e) =>
                              e
                                ?.filter((item) => item.filterBy !== 'occupations')
                                .concat({ ...data, filterBy: 'occupations' })
                            );
                          }}
                        />{' '}
                      </>
                    )}
                  </div>
                  <div className="flex items-center justify-between pt-4">
                    <button
                      type="button"
                      onClick={() => setIsAdvanced((e) => !e)}
                      className={`  space-x-2 flex items-center ${dir('', 'space-x-reverse')}`}
                    >
                      <ChevronDown className="w-5 h-5" />
                      <h4>{t(isAdvanced ? 'Less' : 'Advanced')}</h4>
                    </button>
                    <div>
                      <button className={` py-2 rounded px-5 flex text-white  bg-[#4f46e5]`}>{t('Search')}</button>
                    </div>
                  </div>
                </div>

                <div className="px-5 py-5">
                  <p className="text-[#dd0000]">
                    {t('Registeration Report from')}{' '}
                    {
                      searchFilter?.filter.filter(
                        (item) => item.filterBy === 'datefromto' || item.filterBy === 'dateform'
                      )[0]?.dateFilteration?.from
                    }{' '}
                    :{' '}
                    {
                      searchFilter?.filter.filter(
                        (item) => item.filterBy === 'datefromto' || item.filterBy === 'dateto'
                      )[0]?.dateFilteration?.to
                    }{' '}
                  </p>
                  <div className={`flex items-center pt-5 space-x-4 ${dir('', 'space-x-reverse')}`}>
                    <div className="flex flex-col text-center justify-center w-24   bg-[#4f46e5] text-white rounded py-1">
                      <h3>{data?.data?.length}</h3>
                      <h4>{t('Visitors')}</h4>
                    </div>

                    {searchFilter?.filter
                      ?.filter((item) => item.filterBy !== 'datefromto')
                      ?.filter((item) => item.filterBy !== 'dateform')
                      ?.filter((item) => item.filterBy !== 'dateto')
                      .map((item) => (
                        <div className="bg-[#f3f3f3] px-5 py-2 rounded text-[#666666] font-semibold text-sm relative min-w-[112px]">
                          <h3>{t(item.filterBy)}</h3>
                          <h4 className="font-medium">
                            {
                              filtersNames
                                ?.filter((i) => i.filterBy === item.filterBy)[0]
                                ?.frmQoptionLngs?.filter((lang) => lang?.languageId === languageId)[0]?.title
                            }
                          </h4>
                          <button
                            type="button"
                            className="absolute top-0 right-0 p-1"
                            onClick={() => {
                              removeFilter(item.filterBy);
                            }}
                          >
                            <Close className="w-4 h-4  " />
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
        <div className="flex px-5 py-1">
          <Formik
            initialValues={{ target: { value: '' } }}
            onSubmit={(e) => {
              console.log(e);
              // setSearchTerm(e.target.value);
              setSearchFilter({
                ...searchFilter,
                searchFilter: {
                  langId: languageId,
                  term: e.target.value,
                },
              });
            }}
          >
            {({ values }) => {
              return (
                <Form className="flex items-center relative  w-max ml-auto ">
                  <Field
                    name="target.value"
                    className="border rounded w-64 py-2 px-2 border-[#d1d5db] text-[#373d4c]"
                    type="text"
                    placeholder={t('Search')}
                  />
                  <button
                    type="submit"
                    className={`border rounded py-2 px-2 h-full flex items-center bg-move hover:bg-movehover text-white absolute  ${
                      i18n.language === 'en' ? 'right-0' : 'left-0'
                    }`}
                  >
                    <Search className="w-7 h-7" />
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
        <table className="min-w-full divide-y-2 divide-gray-200 mt-6">
          <thead className=" text-sm font-bold text-black">
            <tr>
              {tabltHeader1.map((e) => {
                return (
                  <th
                    key={e.title}
                    scope="col"
                    className={`px-6 py-3 ${
                      i18n.language === 'en' ? 'text-left' : 'text-right'
                    }    uppercase font-bold text-sm ${e.className}`}
                  >
                    {e.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
            {data?.data?.map((item, index) => {
              return (
                <>
                  <tr key={item.id} className="">
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1046)[0]?.formOwner}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1029)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1034)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1048)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1033)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1039)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1040)[0]?.answer}
                    </td>

                    <td
                      className={`px-6 py-4 whitespace-nowrap font-bold text-base flex items-center space-x-2 ${
                        i18n.language === 'en' ? '' : 'space-x-reverse'
                      }`}
                    >
                      <button
                        onClick={() => {
                          setActiveTable((e) => (e === index ? undefined : index));
                        }}
                        type="button"
                        className={`w-7 h-7 rounded-full overflow-hidden border-2 border-white shadow-md flex items-center justify-center ${
                          index === activeTable ? 'bg-red-500' : 'bg-lightgreen'
                        }  text-white`}
                      >
                        {index === activeTable ? <Minus className="w-5 h-5" /> : <Plus className="w-5 h-5" />}
                      </button>
                    </td>
                  </tr>
                  {index === activeTable && (
                    <tr className="px-6 py-4 whitespace-nowrap font-bold text-base ">
                      <td colspan="8">
                        <div className="flex flex-col px-6 pt-4 pb-2 ">
                          <div className="grid grid-cols-2 gap-7 mb-4">
                            <h2 className="text-black text-sm font-bold">{t('Location')}</h2>{' '}
                            <p className="text-[#6b7280] font-bold text-sm">
                              {item.answer.filter((e) => e.questionId === 1041)[0]?.answer}
                            </p>
                          </div>
                          <div className="grid grid-cols-2 gap-7 mb-4">
                            <h2 className="text-black text-sm font-bold">{t('Governorate')}</h2>{' '}
                            <p className="text-[#6b7280] font-bold text-sm">
                              {item.answer.filter((e) => e.questionId === 1048)[0]?.answer}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <TablePaginationNew
                  data={data}
                  paginationCount={paginationCount}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
