import { useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { AddUserConnection } from '../Api/Authenticate';
import Notification from './Notification';

export const Notify = () => {
  const [connection, setConnection] = useState(null);
  const [inputText, setInputText] = useState('');
  const userData = localStorage.getItem('token');
  const userId = userData ? JSON.parse(userData).userId : 0;
  const AddUserConnectionMutation = useMutation(AddUserConnection);
  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl('https://egcapi.key-frame.cf/notify')
      .withAutomaticReconnect()
      .build();
    // .start();

    setConnection(connect);
    localStorage.setItem('connect', JSON.stringify(connect));
    // console.log('connect', connect);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        // .then(() => {
        //   AddUserConnectionMutation.mutate({
        //     connectionId: connection.connectionId,
        //     userId: userId,
        //   });
        // })
        .then((data) => {
          console.log('data', data);
          connection.on('ReceiveMessage', (message) => {
            console.log('xx', message);
            // toast('message');
            toast(<Notification message={'Messsage'} />);
          });
        })
        .catch((error) => console.log(error));
      console.log('connectionx', connection);
    }
  }, [connection]);

  // useEffect(() => {
  //   if (connection) {
  //     console.log('connection', connection);
  //     if (connection.connectionState === 'Connected') {
  //       connection.on('ReceiveMessage', (message) => {
  //         console.log(message);

  //         toast('message');
  //       });
  //     }
  //   }
  // }, [connection]);

  const sendMessage = async () => {
    if (connection) {
      console.log('connection', connection);
      try {
        await connection.send('SendMessage', 'sssssss');
      } catch (error) {
        console.log('sss', error);
      }
    }
    // if (connection) await connection.recieve('RecieveMessage', 'Kenz');
    setInputText('');
  };

  return (
    <div className="hidden">
      <input
        value={inputText}
        onChange={(input) => {
          setInputText(input.target.value);
        }}
      />
      <button onClick={sendMessage} type="primary">
        Send MEssage
      </button>
    </div>
  );
};
