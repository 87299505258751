import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../SharedComponents/Modal';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../SharedComponents/Loading';
import { editFormsRole, GetFormsRole, getLanguages } from './ApiRol';
import PecnilAlt from '../../SharedComponents/Svg/PencilAlt';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../SharedComponents/LanguageList';
import toast from 'react-hot-toast';
import Notification from '../../SharedComponents/Notification';

export default function EditRole(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props.id ? props : 1;

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <PecnilAlt className="w-5 h-5" />
      </button>

      {isOpen && <ModalContainer id={id} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, id } = props;
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(['GetFormsRole', { id }], GetFormsRole);
  // Mutate Data to Create New Page
  const initialValues = data?.data;
  console.log(initialValues);
  const mutation = useMutation(editFormsRole, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['formRoles']);
        queryClient.invalidateQueries(['GetFormsRole']);
        toast.custom(<Notification message={t('The FormRole has been modified successfully')} />);
        setIsOpen(false);
      }
    },
  });

  const onSubmit = async (e) => {
    try {
      await mutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };
  if (isLoading) return <Loading />;
  return (
    <>
      <Formik onSubmit={onSubmit} validateOnBlur={false} validateOnChange={false} initialValues={initialValues}>
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onSubmit={onSubmit}
                title={t('Edit FormRoles')}
                isLoading={mutation.isLoading}
                add={true}
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <label className="flex items-center">
                      <b
                        className={`font-semibold block w-14 text-gray-700 ${
                          i18n.language === 'en' ? '' : 'text-right'
                        }`}
                      >
                        {t('Create')}
                      </b>
                      <Field className="border rounded w-11  p-1" type="checkbox" name={`create`} />
                    </label>
                    <label className="flex items-center">
                      <b
                        className={`font-semibold block w-14 text-gray-700 ${
                          i18n.language === 'en' ? '' : 'text-right'
                        }`}
                      >
                        {t('Read')}
                      </b>
                      <Field className="border rounded w-11  p-1" type="checkbox" name={`get`} />
                    </label>
                    <label className="flex items-center">
                      <b
                        className={`font-semibold block w-14 text-gray-700 ${
                          i18n.language === 'en' ? '' : 'text-right'
                        }`}
                      >
                        {t('Edit')}
                      </b>
                      <Field className="border rounded w-11  p-1" type="checkbox" name={`update`} />
                    </label>
                    <label className="flex items-center">
                      <b
                        className={`font-semibold block w-14 text-gray-700 ${
                          i18n.language === 'en' ? '' : 'text-right'
                        }`}
                      >
                        {t('Delete')}
                      </b>
                      <Field className="border rounded w-11  p-1" type="checkbox" name={`delete`} />
                    </label>
                  </>
                )}
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
