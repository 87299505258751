import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getPages } from './ApiMenu';

export default function PagesList(props) {
  const { languageId, setActivePage, setFieldValue } = props;
  const { t, i18n } = useTranslation();
  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('pages', getPages, {
    staleTime: 360000000,
  });
  if (isLoading) return '';
  if (isError) return 'error';

  const onClick = (data) => {
    data.pgPageLngs.map((e, index) => setFieldValue(`muMenuItemLngs[${index}].title`, e.title));
    const categoryname = data.pgPageCategory.pgPageCategoryLngs.filter((e) => {
      if (e.languageId === languageId) {
        return e.name;
      }
      return '';
    });
    setFieldValue(`url`, data.uniqueUrl);
    setFieldValue(`pageId`, data.id);
    setFieldValue(`categoryName`, categoryname[0].name);

    const newData = {
      uniqueUrl: data.uniqueUrl,
      pgPageLngs: data.pgPageLngs,
    };
    setActivePage(newData);
  };
  return (
    <>
      <h3 className={i18n.language === 'en' ? 'text-left' : 'text-right'}>{t('Pages')}</h3>
      <ul className="border rounded divide-y overflow-y-scroll max-h-32">
        {data.data.map((e) => {
          return (
            <Fragment key={e.id}>
              <button
                onClick={(x) => {
                  onClick(e);
                }}
                type="button"
                className=" w-full text-left block px-2 py-1 hover:bg-darkbluehover hover:text-white"
              >
                {e.pgPageLngs.map((x) => {
                  return <Fragment key={x.id}>{languageId === x.languageId && x.title}</Fragment>;
                })}
              </button>
            </Fragment>
          );
        })}
      </ul>
    </>
  );
}
