import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Modal from '../../SharedComponents/Modal';
import PecnilAlt from '../../SharedComponents/Svg/PencilAlt';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import { addNewsLetter, addRole } from './ApiNewsLetter';

export default function AddNewsLetter(props) {
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { mutate, data } = useMutation(addNewsLetter, {
    onSuccess: () => {
      queryClient.invalidateQueries(['newsLetters']);
      setIsOpen(false);
    },
  });
  const onSubmit = async (e) => {
    try {
      await mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={`bg-lightgreen ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } space-x-1 text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <PlusCircle className="w-5 h-5" /> <span>{t('Add Email')}</span>
      </button>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          // resetForm();
        }}
      >
        <Form>
          <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={t('Add Email')} add={true}>
            <label className={`${i18n.language === 'en' ? 'text-left' : 'text-right'}`}>
              <b className="font-semibold block text-gray-900">{t('Email')}</b>
              <Field
                style={{ textIndent: '10px' }}
                placeholder={t('Add Email')}
                className="border rounded  w-full mb-8 p-1"
                name="email"
                type="text"
              />
            </label>
          </Modal>
        </Form>
      </Formik>
    </>
  );
}
