import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../../SharedComponents/Notification';
import Flag from '../../../SharedComponents/Svg/Flag';
import { saveMenuReorder } from './ApiMenu';

export default function SaveMenuItemOrder(props) {
  const { state } = props;
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const { mutate, data } = useMutation(saveMenuReorder, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['Menu']);
    },
  });
  const handleOrder = async (e) => {
    const newData = state.menuItems.map((e, index) => {
      return { orderNumber: index, menuItemId: e.id };
    });
    try {
      await mutate(newData);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <button
        onClick={handleOrder}
        className={`bg-darkblue space-x-1 ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } text-sm font-bold text-white flex items-center hover:bg-darkbluehover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <Flag className="w-5 h-5" /> <span>{t('Save Menu Order')}</span>
      </button>
      {data && data.isDone === true && <Notification message={t('Menu Order Have Been Changed Successfully')} />}
      {data && data.isDone === false && <Notification error={true} message={t('Network Error')} />}
    </>
  );
}
