import { Field, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import Loading from '../../SharedComponents/Loading';
import complaintAvatar from './../../assets/img/complaintAvatar.png';
import { AddComplaintFollowUp, getComplaintBySubmitId } from './ApiComplaints';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import PaperClip from '../../SharedComponents/Svg/PaperClip';
import Notification from '../../SharedComponents/Notification';
import { toast } from 'react-toastify';
import { dir } from '../../utils/localisation';
import excel from '../../assets/img/excel.svg';
import pdf from '../../assets/img/pdf.svg';
import word from '../../assets/img/word.svg';
import powerPoint from '../../assets/img/powerPoint.svg';
import Modal from '../../SharedComponents/Modal';
import UploadAttachment from './UploadAttachment';
import Refresh from '../../SharedComponents/Refresh';
import ApproveComplaint from './ApproveComplaint';
import DenyComplaint from './DenyComplaint';
import FinishComplaint from './FinishComplaint';

export default function SingleComplaint(props) {
  const { submitId } = useParams();
  const formikRef = useRef(null);
  const chatDivRef = useRef(null);
  const [imageIsOpen, setImageIsOpen] = useState(false);
  const [imageURL, setImageURL] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();
  const userData = localStorage.getItem('token');
  const userId = userData ? JSON.parse(userData).userId : 0;
  const { t } = useTranslation();
  const setPopupImage = (url) => {
    setImageIsOpen(true);
    setImageURL(url);
  };
  const scrollToBottom = () => {
    chatDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const messageMutation = useMutation(AddComplaintFollowUp, {
    onSuccess: () => {
      queryClient.refetchQueries('getComplaintBySubmitId');
    },
    onError: () => {
      toast(<Notification isSuccess={false} isError={true} message={t('error while adding')} />);
    },
  });
  const { data, isLoading, isError, refetch, isFetching } = useQuery(
    ['getComplaintBySubmitId', { id: submitId }],
    getComplaintBySubmitId,
    {
      // refetchInterval: 1000,
      onSuccess: () => {
        scrollToBottom();
      },
    }
  );
  if (isLoading) return <Loading />;
  if (isError) return 'error';

  const onSubmit = (e) => {
    messageMutation.mutate(e);
    formikRef.current?.resetForm();
  };
  const initialValues = {
    userId: userId,
    message: '',
    submitId: submitId,
    isVisitor: false,
  };

  return (
    <>
      <ModalContainer isOpen={isOpen} setIsOpen={setIsOpen} onSubmit={onSubmit} />
      <ImageModalContainer
        isOpen={imageIsOpen}
        setIsOpen={setImageIsOpen}
        imageURL={imageURL}
        setImageURL={setImageURL}
      />
      <div className="grid grid-cols-4 bg-white">
        <div>
          <div className="px-5 py-5 text-white bg-[#dd0000]">
            <div className="mb-2">
              <h3 className="text-sm font-regular">{t('Complaints number')}</h3>
              <h2 className="text-xl font-bold">{submitId}</h2>
            </div>
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-regular">
                {' '}
                {data.data.complaintStatus.length > 0 ? (
                  <>
                    {data.data.complaintStatus[data.data.complaintStatus.length - 1].complaintStatus === 1 &&
                      t('Approved')}
                    {data.data.complaintStatus[data.data.complaintStatus.length - 1].complaintStatus === 2 &&
                      t('Finished')}
                    {data.data.complaintStatus[data.data.complaintStatus.length - 1].complaintStatus === 3 &&
                      t('Denied')}
                  </>
                ) : (
                  t('UnderRevision')
                )}
              </h3>
              <button
                className={`flex items-center text-sm space-x-2 ${dir('', 'space-x-reverse')} `}
                onClick={refetch}
              >
                <Refresh className={`w-4 h-4 text-white ${isFetching ? 'animate-spin' : ''}`} />
                <span>{t('Update')}</span>
              </button>
            </div>
          </div>
          <div className="bg-white p-5">
            <div className="flex mb-5 space-x-2">
              {data.data.complaintStatus.length < 1 && (
                <>
                  <ApproveComplaint />
                  <DenyComplaint />
                </>
              )}

              {data.data.complaintStatus.length > 0 &&
                data.data.complaintStatus[data.data.complaintStatus.length - 1].complaintStatus === 1 && (
                  <FinishComplaint />
                )}
            </div>
            <div className="px-4 pb-4 relative">
              <div
                className={`w-3 h-3 bg-[#f5c600] block rounded-full absolute ${dir(
                  'left-0',
                  'right-0'
                )}  top-0 mt-1 z-20`}
              ></div>
              <div
                className={`h-full w-[1px] bg-black absolute top-0 mt-2 z-10  ${dir(
                  'left-0  ml-[6px]',
                  'right-0  mr-[6px]'
                )} `}
              ></div>
              <span className="text-[#666666] text-sm block ">Complaint Date</span>
              <span className="text-black text-xs">15/09/2021</span>
            </div>
            <div className="relative pb-6">
              <div
                className={`w-3 h-3 bg-[#f5c600] block rounded-full absolute ${dir(
                  'left-0',
                  'right-0'
                )}  top-0 mt-1 z-20`}
              ></div>
              {data?.data?.answers?.filter((e) => e.questionId === 1054)[0]?.answer?.length !== 0 && (
                <div
                  className={`h-full w-[1px] bg-[#00000028] absolute top-0 mt-2 z-10  ${dir(
                    'left-0  ml-[6px]',
                    'right-0  mr-[6px]'
                  )} `}
                ></div>
              )}

              <div className={`flex  items-center  ${dir('pl-5', 'pr-5')}`}>
                <h3 className="text-[#666666] text-sm font-regular">{t('Subject of the complaint')}</h3>
                <div className={`h-full w-[1px] bg-black absolute top-0 left-0 mt-2 ml-[6px] z-10`}></div>
              </div>
              <h4 className={`mt-2 ${dir('pl-5', 'pr-5')} text-sm font-regular`}>
                {data?.data?.answers?.filter((e) => e.questionId === 1055)[0]?.answer}{' '}
              </h4>
            </div>

            {data.data.complaintStatus.map((status, index) => {
              return (
                <div className="px-4 pb-4 relative">
                  <div className={`w-3 h-3 bg-[#f5c600] block rounded-full absolute left-0 top-0 mt-1 z-20`}></div>
                  <div className={`h-full w-[1px] bg-black absolute top-0 left-0 mt-2 ml-[6px] z-10`}></div>

                  {/* {data.data.complaintStatus.length !== index + 1 && (
                  )} */}

                  <span className="text-[#666666] text-sm block ">
                    {status.complaintStatus === 1 && t('Approved')}
                    {status.complaintStatus === 2 && t('Finished')}
                    {status.complaintStatus === 3 && t('Denied')}
                    {status.complaintStatus === 4 && t('UnderRevision')}
                  </span>
                  <span className="text-black text-xs">
                    {dir(
                      `${DateTime.fromISO(status.date).setLocale('').toFormat('dd / LL / yyyy')}`,
                      `${DateTime.fromISO(status.date).setLocale('ar-EG').toFormat('yyyy / LL / dd')}`
                    )}
                  </span>
                </div>
              );
            })}

            {data?.data?.answers?.filter((e) => e.questionId === 1054)[0]?.answer?.length !== 0 && (
              <div className="relative pb-6">
                <div
                  className={`w-3 h-3 bg-[#f5c600] block rounded-full absolute ${dir(
                    'left-0',
                    'right-0'
                  )}  top-0 mt-1 z-20`}
                ></div>

                <div className={`flex  items-center  ${dir('pl-5', 'pr-5')}`}>
                  <h3 className="text-[#666666] text-sm font-regular">{t('Attachments')}</h3>
                </div>
                <h4 className={`mt-2 ${dir('pl-5', 'pr-5')} text-sm font-regular flex flex-wrap`}>
                  {JSON.parse(data?.data?.answers.filter((e) => e.questionId === 1054)[0]?.answer).map((file) => {
                    if (file?.file.split('.').pop() === 'pdf') {
                      return (
                        <a href={process.env.REACT_APP_IMAGE_PATH + file?.file} target="_blank" rel="noreferrer">
                          {' '}
                          <img src={pdf} alt="pdf" />
                        </a>
                      );
                    }
                    if (file?.file.split('.').pop() === 'xlsx') {
                      return (
                        <a href={process.env.REACT_APP_IMAGE_PATH + file?.file} target="_blank" rel="noreferrer">
                          {' '}
                          <img src={excel} alt="excel" />
                        </a>
                      );
                    }
                    if (file?.file.split('.').pop() === 'doc') {
                      return (
                        <a href={process.env.REACT_APP_IMAGE_PATH + file?.file} target="_blank" rel="noreferrer">
                          {' '}
                          <img src={word} alt="word" />
                        </a>
                      );
                    }
                    if (file?.file.split('.').pop() === 'ppt') {
                      return (
                        <a href={process.env.REACT_APP_IMAGE_PATH + file?.file} target="_blank" rel="noreferrer">
                          {' '}
                          <img src={powerPoint} alt="powerPoint" />
                        </a>
                      );
                    }
                    return (
                      <button onClick={() => setPopupImage(file?.file)}>
                        <img
                          src={process.env.REACT_APP_IMAGE_PATH + file?.file}
                          className="mb-5 w-40 h-40 object-cover"
                          loading="lazy"
                        />
                      </button>
                    );
                  })}
                </h4>
              </div>
            )}
          </div>
        </div>
        <div className="col-span-3">
          <div className="bg-white p-5 flex items-center font-bold  ">
            <img className="max-h-10 px-2 my-1" src={complaintAvatar} alt="logo" />
            <span>{data.data.answers[0].answer}</span>
          </div>
          <div className="bg-[#f6f7f8] ">
            <div ref={chatDivRef} className="p-5 overflow-x-hidden overflow-y-auto h-[500px]">
              {data?.data?.complaintFollowUps?.length > 0 &&
                data?.data?.complaintFollowUps.map((e) => {
                  return (
                    <div
                      className={`flex items-end`}
                      style={{
                        direction: e.isVisitor ? dir('rtl', 'ltr') : dir('ltr', 'rtl'),
                        textAlign: e.isVisitor ? 'left' : 'right',
                      }}
                    >
                      <div className="mb-8">
                        {e?.isVisitor ? (
                          <ComplaintVisitor setPopupImage={setPopupImage} info={e} />
                        ) : (
                          <ComplaintEmployee setPopupImage={setPopupImage} info={e} />
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            {data?.data?.complaintFollowUps &&
              data?.data?.complaintStatus[data.data.complaintStatus.length - 1]?.complaintStatus === 1 && (
                <Formik
                  onSubmit={onSubmit}
                  initialValues={initialValues}
                  validationSchema={Yup.object().shape({
                    message: Yup.string().min(1, '').max(500, '').required(''),
                  })}
                  innerRef={formikRef}
                >
                  {({ values }) => (
                    <Form className="flex border items-center px-6 h-20 bg-white">
                      <div className="flex flex-1 items-center pb-2">
                        <button type="button" onClick={() => setIsOpen(true)}>
                          <PaperClip />
                        </button>
                        <Field
                          name="message"
                          type="text"
                          placeholder={t('Write Your Message')}
                          className="px-3 py-2 w-full outline-none"
                        />
                      </div>
                      <div>
                        <button
                          type="submit"
                          className={`flex items-center justify-center mb-1 px-8 py-3  text-center text-white text-xl font-semibold bg-[#dd0000] rounded-lg`}
                        >
                          {t('Send')}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}{' '}
          </div>
        </div>
      </div>
    </>
  );
}

const ComplaintVisitor = (props) => {
  const { info, setPopupImage } = props;
  return (
    <div className={`${dir('text-left', 'text-right')}`}>
      <div className={`flex items-start space-x-2 ${dir('flex-row-reverse', 'flex-row-reverse space-x-reverse')}`}>
        <div className="bg-white border border-[#dfdfe0] text-[#666666] min-w-[300px]  p-2 rounded-md ">
          {info.isFile ? (
            JSON.parse(info.message).map((file) => {
              if (file?.file.split('.').pop() === 'pdf') {
                return (
                  <a href={process.env.REACT_APP_IMAGE_PATH + file?.file}>
                    {' '}
                    <img src={pdf} alt="pdf" />
                  </a>
                );
              }
              if (file?.file.split('.').pop() === 'xlsx') {
                return (
                  <a href={process.env.REACT_APP_IMAGE_PATH + file?.file}>
                    {' '}
                    <img src={excel} alt="excel" />
                  </a>
                );
              }
              if (file?.file.split('.').pop() === 'doc') {
                return (
                  <a href={process.env.REACT_APP_IMAGE_PATH + file?.file}>
                    {' '}
                    <img src={word} alt="word" />
                  </a>
                );
              }
              if (file?.file.split('.').pop() === 'ppt') {
                return (
                  <a href={process.env.REACT_APP_IMAGE_PATH + file?.file}>
                    {' '}
                    <img src={powerPoint} alt="powerPoint" />
                  </a>
                );
              }
              return (
                <button onClick={() => setPopupImage(file?.file)}>
                  <img
                    src={process.env.REACT_APP_IMAGE_PATH + file?.file}
                    className="mb-5 w-40 h-40 object-cover"
                    loading="lazy"
                  />
                </button>
              );
            })
          ) : (
            <span>{info.message}</span>
          )}
        </div>

        <div className="flex items-center  flex-col  ">
          <img className="max-h-8 " src={complaintAvatar} alt="logo" />
          <span
            className=" font-bold text-left"
            style={{
              fontSize: 10,
              direction: 'initial',
            }}
          >
            {dir(
              `${DateTime.fromISO(info.sendDate).setLocale('').toFormat('hh:mm a ')}`,
              `${DateTime.fromISO(info.sendDate).setLocale('ar-EG').toFormat('hh:mm a')}`
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
const ComplaintEmployee = (props) => {
  const { info, setPopupImage } = props;
  return (
    <div className={`${dir('text-left', 'text-right')}`}>
      <div className={`flex items-start space-x-2   ${dir('', 'space-x-reverse')}`}>
        <div className="flex items-center flex-col ">
          <img className="max-h-8 " src={complaintAvatar} alt="logo" />
          <span
            className="font-bold"
            style={{
              fontSize: 10,
              direction: 'initial',
            }}
          >
            {dir(
              `${DateTime.fromISO(info.sendDate).setLocale('').toFormat('hh:mm a')}`,
              `${DateTime.fromISO(info.sendDate).setLocale('ar-EG').toFormat('hh:mm a')}`
            )}
          </span>
        </div>
        <div className="bg-white border border-[#dfdfe0] text-[#666666] min-w-[300px]  p-2 rounded-md ">
          {info.isFile ? (
            JSON.parse(info.message).map((file) => {
              if (file?.file.split('.').pop() === 'pdf') {
                return (
                  <a href={process.env.REACT_APP_IMAGE_PATH + file?.file}>
                    {' '}
                    <img src={pdf} alt="pdf" />
                  </a>
                );
              }
              if (file?.file.split('.').pop() === 'xlsx') {
                return (
                  <a href={process.env.REACT_APP_IMAGE_PATH + file?.file}>
                    {' '}
                    <img src={excel} alt="excel" />
                  </a>
                );
              }
              if (file?.file.split('.').pop() === 'doc') {
                return (
                  <a href={process.env.REACT_APP_IMAGE_PATH + file?.file}>
                    {' '}
                    <img src={word} alt="word" />
                  </a>
                );
              }
              if (file?.file.split('.').pop() === 'ppt') {
                return (
                  <a href={process.env.REACT_APP_IMAGE_PATH + file?.file}>
                    {' '}
                    <img src={powerPoint} alt="powerPoint" />
                  </a>
                );
              }
              return (
                <button onClick={() => setPopupImage(file?.file)}>
                  <img
                    src={process.env.REACT_APP_IMAGE_PATH + file?.file}
                    className="mb-5 w-40 h-40 object-cover"
                    loading="lazy"
                  />
                </button>
              );
            })
          ) : (
            <span>{info.message}</span>
          )}
        </div>
      </div>
    </div>
  );
};

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, onSubmit } = props;
  const [files, setFiles] = useState([]);
  const { t } = useTranslation();
  const { submitId } = useParams();
  const userData = localStorage.getItem('token');
  const userId = userData ? JSON.parse(userData).userId : 0;
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={t('Download the file')}
      cancelTitle={t('Close')}
      onSubmit={() => {
        onSubmit({
          userId: userId,
          message: JSON.stringify(files),
          submitId: submitId,
          isVisitor: false,
          isFile: true,
        });
        setFiles([]);
        setIsOpen(false);
      }}
    >
      <UploadAttachment files={files} setFiles={setFiles} setIsOpen={setIsOpen} />
    </Modal>
  );
};

// id: 2,
// userId: 2,
// message: 'test',
// sendDate: '2021-09-28T21:10:08.5101587',
// readDate: null,
// submitId: 294,
// isVisitor: true,
// arName: 'ar name',
// enName: 'en name',
// department: 'dep',

const ImageModalContainer = (props) => {
  const { isOpen, setIsOpen, imageURL, setImageURL } = props;

  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={() => {
        setIsOpen(false);
        setImageURL('');
      }}
      title={imageURL}
      hideSubmit
      cancelTitle={t('Close')}
    >
      <img src={process.env.REACT_APP_IMAGE_PATH + imageURL} alt="" className="w-full h-full object-contain" />
    </Modal>
  );
};
