import axios from 'axios';

// Comon API
export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};

// GalleryAlbumApi
export const getMenus = async (e) => {
  const query = await axios.post('/MuMenuType/GetAll', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

export const addMenu = async (e) => {
  const { data } = await axios.post('/MuMenuType/Add', e);
  return data;
};
export const editMenu = async (e) => {
  const { data } = await axios.post('/MuMenuType/Edit', e);
  return data;
};

export const deleteMenu = async (id) => {
  const mutation = await axios.post(`/MuMenuType/Delete`, { id });
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};

// this to set main menu
export const changeMainMenuType = async (e) => {
  const { data } = await axios.post('/MuMenuType/ChangeMainMenuType', e);
  return data;
};
