import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function Modal(props) {
  const {
    isOpen,
    setIsOpen,
    title,
    add,
    confirmTitle,
    cancelTitle,
    isLoading,
    isDone,
    className,
    hideSubmit,
    submitType,
    onSubmit,
  } = props;
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className={` ${isOpen ? 'visible' : 'invisible'} fixed z-50 inset-0  `}>
        <div className="flex items-end justify-center min-h-screen max-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    --> */}
          <div
            className={` ${isOpen ? 'opacity-100 ' : 'opacity-0 '} fixed inset-0 transition-opacity `}
            aria-hidden="true"
          >
            <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
          </div>

          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          {/* <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span> */}
          {/* <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    --> */}
          <div
            className={`${
              isOpen ? 'opacity-100 translate-y-0 sm:scale-100' : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            } ${
              className ? className : ' sm:max-w-lg'
            } inline-block align-bottom bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full`}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="flex justify-between items-center border-b px-4 py-4">
              <h3 className=" font-bold text-lg text-[#373d4c]">{title}</h3>
              <button
                type="button"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <svg
                  className="w-6 h-6 text-[#9ea6b2] "
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
            <div className="p-4 overflow-y-scroll text-[#666666] text-sm" style={{ maxHeight: '75vh' }}>
              {props.children}
            </div>
            <div className="bg-white border-t px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              {!hideSubmit && (
                <button
                  onClick={onSubmit}
                  type={`${submitType ? submitType : 'submit'}`}
                  className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  font-medium text-white  
                ${add ? 'bg-move hover:bg-movehover' : 'bg-red-600 text-base hover:bg-red-700 '}
                ${i18n.language === 'en' ? 'sm:ml-3' : 'sm:mr-3 flex-row-reverse'}
                 sm:w-auto sm:text-sm`}
                >
                  {confirmTitle ? confirmTitle : t('Save')}
                  {(isLoading || isDone) && (
                    <svg
                      className="animate-spin w-6 h-6 inline-block ml-4   text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                </button>
              )}

              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className={`mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 ${
                  i18n.language === 'en' ? 'sm:ml-3' : 'sm:mr-3'
                } sm:w-auto sm:text-sm`}
              >
                {cancelTitle ? cancelTitle : t('Cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
