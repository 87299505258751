import { Field } from 'formik';
import { useQuery } from 'react-query';
import { getFormQuestions, getQuestionTypes } from '../ApiForms';

export default function AllQuestions(props) {
  const { fieldName, folderId, languageId } = props;
  let initialState = {
    formId: parseInt(folderId),
    paginationFilter: {
      pageNumber: 0,
      pageSize: 0,
      numberOfPages: 0,
      isPaging: false,
      langId: 0,
    },
  };

  const { data, isLoading, isError } = useQuery(['formQuestion', initialState], getFormQuestions);
  if (isLoading) return 'loading';
  if (isError) return 'error';
  return (
    <label className={`relative`}>
      <Field className="border rounded  w-full p-2 text-sm focus:outline-none" name={fieldName} as="select">
        {data.data.map((option) => {
          return (
            <>
              {option.frmQuestionLngs.map((e) => {
                return <>{e.languageId === languageId && <option value={parseInt(option.id)}>{e.title}</option>};</>;
              })}
            </>
          );
        })}
      </Field>
      {/* <button className='absolute bottom-0 right-0 text-white -mb-2 border rounded bg-black p-1'>
    <DocumentDuplicate />
  </button> */}
    </label>
  );
}
