import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import Print from '../../SharedComponents/Svg/Print';
import { dir } from '../../utils/localisation';
import { GetAllAnswersByFrmQoptionTypeId } from './ApiAnswers';

const TableComponentToPrint = React.forwardRef((props, ref) => {
  const { languageId } = props;
  const { t, i18n } = useTranslation();
  const { qOptionName, qOptionId } = useParams();
  const tableHeader = [
    { title: t('ID') },
    { title: t('EN NAME') },
    { title: t('Ar Name') },
    { title: t('Mobile No.') },
    { title: t('ID NO.') },
    { title: t('REASON OF VISIT') },
    { title: t('Date of Birth') },
    { title: t('Age Group') },
    { title: t('Gender') },
    { title: t('Alternative Phone') },
    { title: t('Email') },
    { title: t('Date of Visit') },
    { title: t('Reason of Visit') },
    { title: t('Registration Method') },
    { title: t('Location') },
    { title: t('General information session') },
    { title: t('General information date') },
    { title: t('Group advisory session') },
    { title: t('Visitors') },
    { title: t('Governorate') },
    { title: t('Date') },
  ];

  const initialState = {
    pageNumber: 0,
    pageSize: 0,
    numberOfPages: 0,
    isPaging: true,
    isFilter: false,
    langId: 1,
    isSearch: false,
    newsnumber: 0,
    parentId: 0,
    checkIsRequest: false,
    isRequest: true,
    filter: [
      {
        filterBy: 'string',
        filterType: 'string',
        dateFilteration: {
          from: 'string',
          to: 'string',
        },
        searchTerm: [
          {
            langId: languageId,
            term: 'string',
          },
        ],
      },
    ],
    searchFilter: {
      langId: 0,
      term: 'string',
    },
    optionId: parseInt(qOptionId),
  };

  const { data, isLoading, isError } = useQuery(['formAnswer', initialState], GetAllAnswersByFrmQoptionTypeId, {
    onSuccess: ({ data }) => {
      //   setNumberOfPages(uniqBySubmitId(data, (it) => it.submitId).length / entries);
    },
  });
  if (isLoading) return '';

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" ref={ref}>
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y-2 divide-gray-200">
            <thead className=" text-sm font-bold text-black">
              <tr>
                {tableHeader.map((e) => {
                  return (
                    <th
                      key={e.title}
                      scope="col"
                      className={`px-6 py-3 ${dir('text-left', 'text-right')}    uppercase font-bold text-sm ${
                        e.className
                      }`}
                    >
                      {e.title}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
              {data.data.map((item) => {
                return (
                  <tr key={item.id} className="">
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1046)[0]?.formOwner}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1029)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1030)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1035)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1031)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1039)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1032)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1033)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1034)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1036)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1037)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1038)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1039)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1040)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1041)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1042)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1043)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1044)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1046)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1048)[0]?.answer}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                      {item.answer.filter((e) => e.questionId === 1045)[0]?.answer}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    // <table className="min-w-full divide-y-2 divide-gray-200" ref={ref}>
    //   <thead className=" text-sm font-bold text-black">
    //     <tr>
    //       {tableHeader.map((e) => {
    //         return (
    //           <th
    //             key={e.title}
    //             scope="col"
    //             className={`px-6 py-3 ${
    //               i18n.language === 'en' ? 'text-left' : 'text-right'
    //             }    uppercase font-bold text-sm ${e.className}`}
    //           >
    //             {e.title}
    //           </th>
    //         );
    //       })}
    //     </tr>
    //   </thead>
    //   <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
    //     {data.data.map((item) => {
    //       return (
    //         <tr key={item.id} className="">
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1046)[0].formOwner}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1029)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1030)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1035)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1031)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1039)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1032)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1033)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1034)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1036)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1037)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1038)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1039)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1040)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1041)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1042)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1043)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1044)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1046)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1048)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1045)[0]?.answer}
    //           </td>
    //         </tr>
    //       );
    //     })}
    //   </tbody>
    // </table>
  );
});
export default function DownloadPdf(props) {
  const { languageId } = props;
  const { i18n, t } = useTranslation();
  const componentRef = useRef();
  const pageStyle = `
    @page {
      size: 980mm 350mm;
    }
`;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
  });

  return (
    <button
      onClick={handlePrint}
      className={` py-2 rounded px-5 flex text-white  bg-[#7e22ce] space-x-2 items-center text-sm font-bold ${
        i18n.language === 'en' ? '' : 'space-x-reverse'
      }`}
    >
      <div className="hidden">
        <TableComponentToPrint ref={componentRef} languageId={languageId} />
      </div>

      <Print />
      <span>{t('Print')}</span>
    </button>
  );
}
