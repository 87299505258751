const Permissions = [
  'AccountMangment.Register.policy',
  'AccountMangment.CreateRole.policy',
  'AccountMangment.DeleteRole.policy',
  'Language.Add.policy',
  'Language.Edit.policy',
  'Language.Delete.policy',
  'UserAccount.AddUser.policy',
  'UserAccount.EditUser.policy',
  'UserAccount.EditStatus.policy',
  'UserAccount.EditRole.policy',
  'UserAccount.GetUser.policy',
  'UserAccount.GetNotMappedProviders.policy',
  'UserAccount.CurrentUser.policy',
  'UserAccount.GetUserByRole.policy',
  'UserAccount.ChangePassword',
  'UserRole.List',
  'UserRole.Add',
  'UserRole.Edit',
  'UserRole.Delete',
  'RolePerm.List',
  'RolePerm.GetForUser',
  'RolePerm.SetForUser',
  'RolePerm.GetForRole',
  'RolePerm.SetForRole',
  'MuMenuItem.Add.policy',
  'MuMenuItem.Edit.policy',
  'MuMenuItem.Delete.policy',
  'MuMenuType.Add.policy',
  'MuMenuType.Edit.policy',
  'MuMenuType.Delete.policy',
  'PgComment.Add.policy',
  'PgComment.Edit.policy',
  'PgComment.Delete.policy',
  'PgEvent.Add.policy',
  'PgEvent.Edit.policy',
  'PgEvent.Delete.policy',
  'PgKpi.Add.policy',
  'PgKpi.Edit.policy',
  'PgKpi.Delete.policy',
  'PgPage.Add.policy',
  'PgPage.Edit.policy',
  'PgPage.Delete.policy',
  'PgPageRatingCountView.Add.policy',
  'PgPageRatingCountView.Edit.policy',
  'PgPageRatingCountView.Delete.policy',
  'PgPageRatingView.Add.policy',
  'PgPageRatingView.Edit.policy',
  'PgPageRatingView.Delete.policy',
  'PgPageType.Add.policy',
  'PgPageType.Edit.policy',
  'PgPageType.Delete.policy',
  'PgRatingResult.Add.policy',
  'PgRatingResult.Edit.policy',
  'PgRatingResult.Delete.policy',
  'PgSection.Add.policy',
  'PgSection.Edit.policy',
  'PgSection.Delete.policy',
  'AstAsset.Add.policy',
  'AstAsset.Edit.policy',
  'AstAsset.Delete.policy',
  'AstAssetType.Add.policy',
  'AstAssetType.Edit.policy',
  'AstAssetType.Delete.policy',
  'AstFolder.Add.policy',
  'AstFolder.Edit.policy',
  'AstFolder.Delete.policy',
  'GlAlbum.Add.policy',
  'GlAlbum.Edit.policy',
  'GlAlbum.Delete.policy',
  'GlAlbumItem.Add.policy',
  'GlAlbumItem.Edit.policy',
  'GlAlbumItem.Delete.policy',
  'GlType.Add.policy',
  'GlType.Edit.policy',
  'GlType.Delete.policy',
  'FrmAnswer.Add.policy',
  'FrmAnswer.Edit.policy',
  'FrmAnswer.Delete.policy',
  'FrmEmail.Add.policy',
  'FrmEmail.Edit.policy',
  'FrmEmail.Delete.policy',
  'FrmForm.Add.policy',
  'FrmForm.Edit.policy',
  'FrmForm.Delete.policy',
  'FrmQoption.Add.policy',
  'FrmQoption.Edit.policy',
  'FrmQoption.Delete.policy',
  'FrmQoptionType.Add.policy',
  'FrmQoptionType.Edit.policy',
  'FrmQoptionType.Delete.policy',
  'FrmQuestion.Add.policy',
  'FrmQuestion.Edit.policy',
  'FrmQuestion.Delete.policy',
  'FrmQuestionType.Add.policy',
  'FrmQuestionType.Edit.policy',
  'FrmQuestionType.Delete.policy',
  'FrmType.Add.policy',
  'FrmType.Edit.policy',
  'FrmType.Delete.policy',
];

export default Permissions;
