import { Field, FieldArray, Form, Formik, isEmptyArray } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../SharedComponents/Modal';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import React, { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../SharedComponents/Loading';
import { addForm, getLanguages } from './ApiForms';
import DocumentDuplicate from '../../SharedComponents/Svg/DocumentDuplicate';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../SharedComponents/LanguageList';

export default function CreateForm(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [acceptFiles, setAcceptFiles] = useState('');
  const { id } = props.id ? props : 1;
  const { typeId } = props;
  const { t, i18n } = useTranslation();
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`${
          i18n.language === 'en' ? '' : 'sapace-x-reverse'
        } bg-lightgreen space-x-1 space-x-reverse text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <PlusCircle className="w-5 h-5" /> <span>{t('Create Form')}</span>
      </button>

      {isOpen && <ModalContainer id={id} typeId={typeId} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, id, typeId } = props;
  const [languageId, setLanguageId] = useState(1);
  const [uploadSucess, setUploadSucess] = useState(false);
  const { t, i18n } = useTranslation();
  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [data]);

  const frmFormLngs = data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
    submitBtnText: '',
    rejectResponseMessage: '',
    actionFailedMessage: '',
    actionSuccessMessage: '',
  }));
  const initialValues = {
    typeId: 1,
    statusId: 0,
    uniqueUrl: '',
    frmFormLngs: frmFormLngs,
    isRequest: false,
  };
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const mutation = useMutation(addForm, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['forms']);

        setIsOpen(false);
      }
    },
  });

  const onSubmit = async (e) => {
    try {
      await mutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {data && data.data && (
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <Modal isOpen={isOpen} setIsOpen={setIsOpen} onSubmit={onSubmit} title={t('Create Form')} add={true}>
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  {data && (
                    <LanguageList
                      className="flex transform translate-y-1 relative z-10 mb-4"
                      languages={data?.data}
                      languageId={languageId}
                      setLanguageId={setLanguageId}
                    >
                      <hr className="border w-full absolute bottom-0 left-0 z-0" />
                    </LanguageList>
                  )}

                  {/* Submit Form */}

                  {values &&
                    values.frmFormLngs &&
                    values?.frmFormLngs.map((asset, index) => (
                      <FieldArray
                        key={index}
                        name="frmFormLngs"
                        render={(arrayHelpers) => (
                          <>
                            {languageId === asset.languageId && (
                              <>
                                <label className={`${i18n.language === 'en' ? '' : 'text-right'}`}>
                                  <b className="font-semibold block text-gray-700">{t('Title')}</b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    type="text"
                                    name={`frmFormLngs[${index}].title`}
                                  />
                                </label>
                                <label className={`${i18n.language === 'en' ? '' : 'text-right'}`}>
                                  <b className="font-semibold block text-gray-700">{t('Submit Button Text')}</b>
                                  <Field
                                    className={`border rounded  w-full mb-4 p-1`}
                                    name={`frmFormLngs[${index}].submitBtnText`}
                                    type="text"
                                  />
                                </label>

                                <label className={`${i18n.language === 'en' ? '' : 'text-right'}`}>
                                  <b className="font-semibold block text-gray-700">{t('Reject Response Message')}</b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    name={`frmFormLngs[${index}].rejectResponseMessage`}
                                    type="text"
                                  />
                                </label>
                                <label className={`${i18n.language === 'en' ? '' : 'text-right'}`}>
                                  <b className="font-semibold block text-gray-700">{t('Action Failed Message')}</b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    name={`frmFormLngs[${index}].actionFailedMessage`}
                                    type="text"
                                  />
                                </label>
                                <label className={`${i18n.language === 'en' ? '' : 'text-right'}`}>
                                  <b className="font-semibold block text-gray-700">{t('Action Success Message')}</b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    name={`frmFormLngs[${index}].actionSuccessMessage`}
                                    type="text"
                                  />
                                </label>
                              </>
                            )}
                          </>
                        )}
                      />
                    ))}
                  <label className={`${i18n.language === 'en' ? '' : 'text-right'} relative`}>
                    <b className="font-semibold block text-gray-700">{t('Unique Url')}</b>
                    <Field className="border rounded  w-full mb-4 p-1" name={`uniqueUrl`} type="text" />
                    {/* <button className='absolute bottom-0 right-0 text-white -mb-2 border rounded bg-black p-1'>
                      <DocumentDuplicate />
                    </button> */}
                  </label>

                  <div className="flex items-center">
                    <label
                      className={`flex items-center space-x-2 ${
                        i18n.language === 'en' ? '' : 'space-x-reverse'
                      } mb-2 text-sm font-bold text-gray-600`}
                    >
                      <Field type="checkbox" name="isRequest" className="w-4 h-4" />
                      <b className="font-semibold block text-base  text-gray-700">{t('Is Request?')}</b>
                    </label>
                  </div>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
