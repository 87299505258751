import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import LanguageList from '../../SharedComponents/LanguageList';
import Loading from '../../SharedComponents/Loading';
import PecnilAlt from '../../SharedComponents/Svg/PencilAlt';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import TablePagination from '../../SharedComponents/TablePagination';
import TableSearch from '../../SharedComponents/TableSearch';
import { GetAllRequests, getLanguages } from './ApRequests';

export default function Requests() {
  const { t, i18n } = useTranslation();
  const [languageId, setLanguageId] = useState(1);
  const [entries, setEntries] = useState(10);
  const [filterSearch, setFilterSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLanguage, setSearchlanguage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationCount, setPaginationCount] = useState([]);

  function pagination(data) {
    let paginationCount = [];
    for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }
  // to control how many rows to show in table
  const onChangeEntries = (e) => {
    setFilterSearch('');
    setEntries(e.target.value);
  };

  const onSearch = (e) => {
    setFilterSearch('');
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    setSearchlanguage(languageId);
  };

  let initialState = {
    pageNumber: pageNumber,
    pageSize: entries,
    isPaging: true,
    langId: 0,
    isSearch: searchTerm ? true : false,
    searchFilter: {
      langId: searchLanguage,
      term: searchTerm,
    },
  };
  const { data, isLoading, isError } = useQuery(['polls', initialState], GetAllRequests, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      pagination(data);
    },
  });

  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  const tabltHeader = [
    { title: 'Title' },
    { title: 'request id', filter: true, className: 'flex items-center' },
    { title: 'Options', className: 'w-64' },
  ];

  return (
    <>
      <header className="flex justify-between items-center">
        <BreadCrumbs to="/requests" name={t('Requests')} />
        <div>
          <Link
            to={`/requests/0`}
            className={`bg-lightgreen space-x-1 ${
              i18n.language === 'en' ? '' : 'space-x-reverse'
            } text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
          >
            <PlusCircle className="w-5 h-5" /> <span>{t('Create New Request')}</span>
          </Link>
        </div>
      </header>
      <div className="my-6">
        <h2 className="text-2xl font-bold">{t('Requests')}</h2>
      </div>

      <div className="bg-white rounded border shadow-md">
        <div className="flex   relative items-center px-6 py-6  ">
          <TableSearch onChangeEntries={onChangeEntries} entries={entries} onSearch={onSearch} />
          <div className="flex transform  relative z-10 ml-auto ">
            <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
          </div>
          <hr className="border w-full absolute top-0 left-0 z-0 mt-16" />
        </div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y-2 divide-gray-200">
                  <thead className=" text-sm font-bold text-black">
                    <tr>
                      {tabltHeader.map((e) => {
                        return (
                          <th
                            key={e.title}
                            scope="col"
                            className={`px-6 py-3 ${i18n.language === 'en' ? 'text-left' : 'text-right'}   uppercase  ${
                              e.className
                            } `}
                          >
                            {t(e.title)}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
                    {isLoading && (
                      <div className="relative bg-white h-72">
                        <Loading />
                      </div>
                    )}
                    {isError && 'error'}
                    {data &&
                      data?.data &&
                      data?.data?.map((request) => {
                        return (
                          <tr key={request.id} className="">
                            <td className="px-6 py-4 whitespace-nowrap">{request.id}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{request.requestStatus}</td>
                            <td
                              className={`px-6 py-4 whitespace-nowrap flex items-center space-x-2 ${
                                i18n.language === 'en' ? '' : 'space-x-reverse'
                              }`}
                            >
                              <a
                                href={`./request/${request.requestStatus}/${request.id}`}
                                className="bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out"
                              >
                                <PecnilAlt className="w-5 h-5" />
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {/* pagination */}

                {data && (
                  <TablePagination
                    data={data}
                    paginationCount={paginationCount}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
