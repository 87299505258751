import { Fragment } from 'react';
import { useQuery } from 'react-query';
import VolumeUp from '../../SharedComponents/Svg/VolumeUp';
import { getFolder } from './ApiAssets';
import DeleteFile from './Components/DeleteFile';
import EditUploadAsset from './EditUploadAsset';
import excel from '../../assets/img/excel.svg';

import pdf from '../../assets/img/pdf.svg';

import word from '../../assets/img/word.svg';

import powerPoint from '../../assets/img/powerPoint.svg';
import DownloadDocument from '../../SharedComponents/Svg/DownloadDocument';
import VideoCamera from '../../SharedComponents/Svg/VideoCamera';
import Eye from '../../SharedComponents/Svg/Eye';
import ViewYoutube from './Components/ViewYoutube';
import ViewVimeo from './Components/ViewVimeo';
import ViewVideo from './Components/ViewVideo';
import { useTranslation } from 'react-i18next';

export const AudioRow = (props) => {
  const { folderId, languageId, activeTab } = props;
  const { t, i18n } = useTranslation();
  const { data, isLoading, isError } = useQuery(['assetFolder', folderId], getFolder, {
    staleTime: 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
  const tabltHeader = [{ title: 'Title' }, { title: 'Audio File' }, { title: 'Options' }];
  if (isLoading)
    return (
      <tr>
        <td></td>
      </tr>
    );
  if (isError) return '';
  return (
    <>
      {' '}
      <table className="min-w-full divide-y-2 divide-gray-200">
        <thead className=" text-sm font-bold text-black">
          <tr>
            {tabltHeader.map((e) => {
              return (
                <th
                  key={e.title}
                  scope="col"
                  className={`px-6 py-3  ${i18n.language === 'en' ? 'text-left' : 'text-right'}   uppercase `}
                >
                  {t(e.title)}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
          {data?.data?.astAssets.map((audio) => {
            return (
              <tr key={audio.id} className="">
                <td className="px-6 py-4 whitespace-nowrap">
                  {audio.astAssetLngs.map((e) => {
                    return (
                      <>
                        {languageId === e.languageId && (
                          <>
                            <div className="flex items-center space-x-2">
                              <VolumeUp className=" w-8 h-8" />
                              <b>{e.title}</b>
                            </div>
                          </>
                        )}
                      </>
                    );
                  })}
                </td>
                <td className="">
                  <div className="flex items-center">
                    <audio className="h-8" controls src={`${process.env.REACT_APP_IMAGE_PATH}${audio.fileUrl}`} />
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap flex items-center space-x-2">
                  <EditUploadAsset
                    // folder={link}
                    typeId={activeTab}
                    asset={audio}
                    isLoading={isLoading}
                  />
                  <DeleteFile id={audio.id} isLoading={isLoading} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export const FileRow = (props) => {
  const { folderId, languageId, activeTab } = props;
  const { t, i18n } = useTranslation();
  const { data, isLoading, isError } = useQuery(['assetFolder', folderId], getFolder, {
    staleTime: 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
  const tabltHeader = [{ title: 'Title' }, { title: 'Options', className: 'w-64' }];

  if (isLoading)
    return (
      <tr>
        <td></td>
      </tr>
    );
  if (isError) return '';

  return (
    <table className="min-w-full divide-y-2 divide-gray-200">
      <thead className=" text-sm font-bold text-black">
        <tr>
          {tabltHeader.map((e, index) => {
            return (
              <th
                key={index}
                scope="col"
                className={`px-6 py-3 ${i18n.language === 'en' ? 'text-left' : 'text-right'}  uppercase  ${
                  e.className
                }`}
              >
                {t(e.title)}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
        {data?.data?.astAssets.map((document) => {
          return (
            <tr key={document.id} className="">
              <td className="px-6 py-4 whitespace-nowrap">
                {document.astAssetLngs.map((e) => {
                  return (
                    <Fragment key={e.id}>
                      {languageId === e.languageId && (
                        <>
                          <div key={e.id} className="flex items-center space-x-2">
                            {document.fileUrl.split('.').pop() === 'pdf' && <img src={pdf} alt="pdf" />}
                            {document.fileUrl.split('.').pop() === 'xlsx' && <img src={excel} alt="pdf" />}
                            {document.fileUrl.split('.').pop() === 'doc' && <img src={word} alt="pdf" />}
                            {document.fileUrl.split('.').pop() === 'ppt' && <img src={powerPoint} alt="pdf" />}

                            <b>{e.title}</b>
                          </div>
                        </>
                      )}
                    </Fragment>
                  );
                })}
              </td>

              <td className="px-6 py-4 whitespace-nowrap flex items-center space-x-2 ">
                <EditUploadAsset
                  // folder={document}
                  typeId={activeTab}
                  asset={document}
                  isLoading={isLoading}
                />

                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${process.env.REACT_APP_IMAGE_PATH}${document.fileUrl}`}
                  className="bg-indigo-100 text-indigo-800 hover:bg-indigo-200  p-1 rounded transition duration-300 ease-in-out"
                >
                  <DownloadDocument className="w-5 h-5 " />
                </a>
                <DeleteFile id={document.id} isLoading={isLoading} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const LinkRow = (props) => {
  const { folderId, languageId, activeTab } = props;
  const { t, i18n } = useTranslation();
  const { data, isLoading, isError } = useQuery(['assetFolder', folderId], getFolder, {
    staleTime: 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
  const tabltHeader = [{ title: 'Title' }, { title: 'Options', className: 'w-64' }];
  if (isLoading)
    return (
      <tr>
        <td></td>
      </tr>
    );
  if (isError) return '';
  return (
    <table className="min-w-full divide-y-2 divide-gray-200">
      <thead className=" text-sm font-bold text-black">
        <tr>
          {tabltHeader.map((e) => {
            return (
              <th
                key={e.title}
                scope="col"
                className={`px-6 py-3 ${i18n.language === 'en' ? 'text-left' : 'text-right'}   uppercase  ${
                  e.className
                }`}
              >
                {t(e.title)}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
        {data?.data?.astAssets.map((link) => {
          return (
            <tr key={link.id} className="">
              <td className="px-6 py-4 whitespace-nowrap">
                {link.astAssetLngs.map((e) => {
                  return (
                    <Fragment key={e.id}>
                      {languageId === e.languageId && (
                        <>
                          <div className="flex items-center space-x-2">
                            <VideoCamera className=" w-8 h-8" />
                            <b>{e.title}</b>
                          </div>
                        </>
                      )}
                    </Fragment>
                  );
                })}
              </td>

              <td className="px-6 py-4 whitespace-nowrap flex items-center space-x-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${link.fileUrl}`}
                  className="bg-orange hover:bg-orangehover transition duration-500 text-white rounded p-1"
                >
                  <Eye className="w-5 h-5" />
                </a>
                <EditUploadAsset
                  // folder={link}
                  typeId={activeTab}
                  asset={link}
                  isLoading={isLoading}
                />
                <DeleteFile id={link.id} isLoading={isLoading} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const VimeoRow = (props) => {
  const { folderId, languageId, activeTab } = props;
  const { t, i18n } = useTranslation();
  const tabltHeader = [{ title: 'Title' }, { title: 'Options', className: 'w-64' }];
  const { data, isLoading, isError } = useQuery(['assetFolder', folderId], getFolder, {
    staleTime: 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
  if (isLoading)
    return (
      <tr>
        <td></td>
      </tr>
    );
  if (isError) return '';
  return (
    <table className="min-w-full divide-y-2 divide-gray-200">
      <thead className=" text-sm font-bold text-black">
        <tr>
          {tabltHeader.map((e) => {
            return (
              <th
                key={e.title}
                scope="col"
                className={`px-6 py-3 ${i18n.language === 'en' ? 'text-left' : 'text-right'}   uppercase  ${
                  e.className
                }`}
              >
                {t(e.title)}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
        {data?.data?.astAssets.map((vimeo) => {
          return (
            <tr key={vimeo.id} className="">
              <td className="px-6 py-4 whitespace-nowrap">
                {vimeo.astAssetLngs.map((e) => {
                  return (
                    <Fragment key={e.id}>
                      {languageId === e.languageId && (
                        <>
                          <div className="flex items-center space-x-2">
                            <VideoCamera className=" w-8 h-8" />
                            <b>{e.title}</b>
                          </div>
                        </>
                      )}
                    </Fragment>
                  );
                })}
              </td>

              <td className="px-6 py-4 whitespace-nowrap flex items-center space-x-2">
                <ViewVimeo video={vimeo.fileUrl} />
                <EditUploadAsset
                  // folder={link}
                  typeId={activeTab}
                  asset={vimeo}
                  isLoading={isLoading}
                />
                <DeleteFile id={vimeo.id} isLoading={isLoading} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const YoutubeRow = (props) => {
  const { folderId, languageId, activeTab } = props;
  const { t, i18n } = useTranslation();
  const tabltHeader = [{ title: 'Title' }, { title: 'Options' }];

  const { data, isLoading, isError } = useQuery(['assetFolder', folderId], getFolder, {
    staleTime: 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
  if (isLoading)
    return (
      <tr>
        <td></td>
      </tr>
    );
  if (isError) return '';
  return (
    <table className="min-w-full divide-y-2 divide-gray-200">
      <thead className=" text-sm font-bold text-black">
        <tr>
          {tabltHeader.map((e) => {
            return (
              <th
                key={e.title}
                scope="col"
                className={`px-6 py-3 ${i18n.language === 'en' ? 'text-left' : 'text-right'}   uppercase `}
              >
                {t(e.title)}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
        {data?.data?.astAssets.map((youtube) => {
          return (
            <tr key={youtube.id} className="">
              <td className="px-6 py-4 whitespace-nowrap">
                {youtube.astAssetLngs.map((e) => {
                  return (
                    <Fragment key={e.id}>
                      {languageId === e.languageId && (
                        <>
                          <div className="flex items-center space-x-2">
                            <VideoCamera className=" w-8 h-8" />
                            <b>{e.title}</b>
                          </div>
                        </>
                      )}
                    </Fragment>
                  );
                })}
              </td>

              <td className="px-6 py-4 whitespace-nowrap flex items-center space-x-2">
                <ViewYoutube video={youtube.fileUrl} />
                <EditUploadAsset
                  // folder={link}
                  typeId={activeTab}
                  asset={youtube}
                  isLoading={isLoading}
                />
                <DeleteFile id={youtube.id} isLoading={isLoading} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const VideoRow = (props) => {
  const { folderId, languageId, activeTab } = props;
  const tabltHeader = [{ title: 'Title' }, { title: 'Options', className: 'w-64' }];
  const { t, i18n } = useTranslation();
  const { data, isLoading, isError } = useQuery(['assetFolder', folderId], getFolder, {
    staleTime: 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
  if (isLoading)
    return (
      <tr>
        <td></td>
      </tr>
    );
  if (isError) return '';
  return (
    <table className="min-w-full divide-y-2 divide-gray-200">
      <thead className=" text-sm font-bold text-black">
        <tr>
          {tabltHeader.map((e) => {
            return (
              <th
                key={e.title}
                scope="col"
                className={`px-6 py-3 ${i18n.language === 'en' ? 'text-left' : 'text-right'}   uppercase  ${
                  e.className
                }`}
              >
                {t(e.title)}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
        {data?.data?.astAssets.map((video) => {
          return (
            <tr key={video.id} className="">
              <td className="px-6 py-4 whitespace-nowrap">
                {video.astAssetLngs.map((e) => {
                  return (
                    <Fragment key={e.id}>
                      {languageId === e.languageId && (
                        <>
                          <div className="flex items-center space-x-2">
                            <VideoCamera className=" w-8 h-8" />
                            <b>{e.title}</b>
                          </div>
                        </>
                      )}
                    </Fragment>
                  );
                })}
              </td>

              <td className="px-6 py-4 whitespace-nowrap flex items-center space-x-2">
                {/* <EditFolder folder={folder} isLoading={isLoading} /> */}
                <ViewVideo video={`${process.env.REACT_APP_IMAGE_PATH}${video.fileUrl}`} />
                <EditUploadAsset
                  // folder={link}
                  typeId={activeTab}
                  asset={video}
                  isLoading={isLoading}
                />
                <DeleteFile id={video.id} isLoading={isLoading} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
