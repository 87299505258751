import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../SharedComponents/BreadCrumbs';
import LanguageList from '../../../SharedComponents/LanguageList';
import Loading from '../../../SharedComponents/Loading';
import Notification from '../../../SharedComponents/Notification';
import Home from '../../../SharedComponents/Svg/Home';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import DeleteFormQuestionOptionsType from '../../Forms/FormQuestionOption/Type/DeleteFormQuestionOptionsType';
import { getGalleryAlbums, getLanguages } from './ApiGalleryAlbums';
import CreateGalleryAlbum from './CreateGalleryAlbum';
import DeleteGalleryAlbum from './DeleteGalleryAlbum';
import EditGalleryAlbum from './EditGalleryAlbum';

export default function GalleryAlbums(props) {
  const [languageId, setLanguageId] = useState(1);
  const regEx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const { t, i18n } = useTranslation();
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  let initialState = {
    pageNumber: pageNumber,
    pageSize: entries,
    isPaging: true,
    langId: 0,
  };
  const { data, isLoading, isError } = useQuery(['galleryAlbums', initialState], getGalleryAlbums);

  if (isLoading) return <Loading />;
  if (isError) return 'error';
  return (
    <>
      <header className="flex justify-between items-center mb-4">
        <BreadCrumbs to="/galleryalbums" name={t('Galley Albums')} />

        <div>
          <CreateGalleryAlbum orderNumber={data.data.length} />
        </div>
      </header>
      <div className="bg-white px-4 py-4">
        <div className="flex justify-between relative items-center mb-4">
          <div className="flex transform  relative z-10">
            <span className="font-bold text-xl p  b-3">{t('Gallery Albums List')}</span>
          </div>
          <div className="flex transform  relative z-10 ">
            {data && languages && (
              <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
            )}
          </div>{' '}
          <hr className="border w-full absolute top-0 left-0 z-0 mt-10" />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 ">
          {data.data.map((album, index) => {
            const url = album.imageUrl.match(regEx);
            console.log('url', url);
            console.log('url', album.imageUrl);
            return (
              <div className=" relative " key={album.id}>
                <div className="relative mb-4 ">
                  <div className="absolute mt-4 h-full w-full mx-auto -z-0  px-8">
                    <div
                      style={{
                        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
                      }}
                      className=" w-full h-full    bg-white rounded-xl shadow-xl border overflow-hidden"
                    ></div>
                  </div>
                  <div className="absolute mt-2 h-full w-full mx-auto -z-10  px-4">
                    <div
                      style={{
                        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
                      }}
                      className=" w-full h-full    bg-white rounded-xl shadow-xl border overflow-hidden"
                    ></div>
                  </div>
                  <div
                    style={{
                      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
                    }}
                    className="relative   bg-white rounded-xl shadow-xl border overflow-hidden"
                  >
                    <span className="absolute top-0 right-0 m-4 bg-orange text-white w-12 flex rounded text-2xl justify-center items-center h-12 text-center">
                      {album.itemsCount}
                    </span>
                    {/* {console.log('album', album.imageUrl?.match(regEx)?['1'])} */}
                    {(album.isVideo === 'true' || album.isVideo === true) && (
                      <iframe
                        style={{ width: '100%' }}
                        height="315"
                        src={`https://www.youtube.com/embed/${url ? url[1] : ''}`}
                        title="video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    )}
                    {(album.isVideo === 'false' || album.isVideo === false) && (
                      <img
                        className="object-cover h-52 w-full"
                        alt="alt"
                        src={`${process.env.REACT_APP_IMAGE_PATH}${album.imageUrl}`}
                      />
                    )}
                    <div className="text-center py-4">
                      {album.glAlbumLngs.map((e) => {
                        return (
                          <Fragment key={e.id}>
                            {languageId === e.languageId && (
                              <div key={e.id}>
                                <b className="block mt-1">{e.title}</b>
                              </div>
                            )}
                          </Fragment>
                        );
                      })}
                    </div>
                    <div
                      className={`flex justify-center ${
                        i18n.language === 'en' ? '' : 'space-x-reverse'
                      }  space-x-2 mb-4`}
                    >
                      <Link
                        to={`./galleryalbums/${album.id}`}
                        className="bg-lightgreen text-white hover:bg-lightgreenhover text-xs focus:outline-none   px-4 rounded-full transition duration-300 ease-in-out"
                      >
                        {t('View')}
                      </Link>
                      <EditGalleryAlbum album={album} />
                      <DeleteGalleryAlbum itemsCount={album.itemsCount} id={album.id} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
