import { useState } from 'react';
import { useQuery } from 'react-query';
import Loading from '../../../SharedComponents/Loading';
import { getMenuByParentId } from './ApiMenu';
import MenuItem from './MenuItem';

export default function SubMenuItem(props) {
  const { menuItem, languageId, index, activeMenu } = props;
  const [entries, setEntries] = useState(999);
  const [pageNumber, setPageNumber] = useState(1);
  let initialState = {
    pageNumber: pageNumber,
    pageSize: entries,
    isPaging: false,
    langId: 0,
    parentId: menuItem.id,
  };
  const { data, isLoading, isError } = useQuery(
    ['menuByParent', initialState],
    getMenuByParentId
    // {
    //   onSuccess: (data) => {
    //     setState({ ...state, menuItems: data.data });
    //   },
    // }
  );

  //   useEffect(() => {}, [state]);
  if (isLoading)
    return (
      <div className="relative h-72">
        <Loading />
      </div>
    );
  if (isError) return 'error';
  return (
    <>
      {data.data.map((e, index) => {
        return <MenuItem activeMenu={activeMenu} languageId={languageId} index={index + 50} key={e.id} menuItem={e} />;
      })}

      {/* // <div className='border px-4  p-3 shadow '>
      //   <div className='flex justify-between'>
      //     <div className='flex items-center space-x-2'>
      //       <span>Home</span>
      //       <span className='bg-yellow-100 text-yellow-500 px-2 rounded'>
      //         {' '}
      //         0{' '}
      //       </span>
      //     </div>
      //     <div className='flex items-center space-x-2'>
      //       <EditMenuItem />
      //       <DeleteMenuItem />
      //       <ToggleMenuItem />
      //       <OptionsMenuItem />
      //     </div>
      //   </div>
      // </div> */}
    </>
  );
}
