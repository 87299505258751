import axios from 'axios';

export const GetAllCalenders = async (e) => {
  const { data } = await axios.get('/FrmAnswer/GetAllCalenders');
  return data;
};

export const GetAllEmployee = async (e) => {
  const { data } = await axios.post('/UserAccount/GetAllEmployee', e.queryKey[1]);
  return data;
};
export const GetVisitorsNames = async (e) => {
  const { id } = e.queryKey[1];
  const { data } = await axios.get(`/FrmAnswer/GetVisitorsNames?id=${id}`);
  return data;
};

export const AddCalenderVisit = async (e) => {
  const { data } = await axios.post('/FrmAnswer/AddCalender', e);
  return data;
};

export const EditCalenderVisit = async (e) => {
  console.log('e', e);
  const { data } = await axios.post('/FrmAnswer/EditCalender', e);
  return data;
};
