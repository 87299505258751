import { Field, FieldArray, Form, Formik } from 'formik';
import { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../../../SharedComponents/Notification';
import SectionHeader from '../SectionHeader';
import LoadingCircle from '../../../../SharedComponents/Svg/LoadingCircle';
import { editModal } from './ApiModal';
import ImageContainer from '../../Pages/ImageContainer';
import AddThumbnail from '../../Pages/AddThumbnail';
import AddYoutube from '../../../../SharedComponents/AddYoutube';
import AddCardImage from '../Card/AddCardImage';
import RichTextEditor from '../../Pages/RichTextEditor';
import LanguageList from '../../../../SharedComponents/LanguageList';

export default function ModalSection(props) {
  const regEx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;

  const [isOpen, setIsOpen] = useState(false);
  const { id, section, languages, editSectionMutation, provided } = props;
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const modalTypeIds = [{ id: 1, name: 'Single' }];
  const modalMediaTypeIds = [
    { id: 1, name: 'Image' },
    { id: 2, name: 'Video' },
    { id: 3, name: 'Text' },
  ];

  const pgSectionModalLng = languages?.data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
    text: '',
  }));
  const editModalMutation = useMutation(editModal, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['sections']);
      }
    },
  });

  const onSaveSettings = async (values) => {
    try {
      await editModalMutation.mutate(values.pgSectionModal);
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    setLanguageId(languages?.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  return (
    <div className="block bg-white w-full shadow border mb-4">
      {editModalMutation.isSuccess && <Notification message={t('Section Updated')} />}

      {editModalMutation.isError && <Notification error={true} message={t('Network Error')} />}

      <SectionHeader
        provided={provided}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        section={section}
        languageId={languageId}
      />
      <Formik
        initialValues={{
          ...section,
          pgSectionModal: {
            ...section.pgSectionModal,
            pgSectionModalLng:
              section.pgSectionModal.pgSectionModalLng.length > 0
                ? section.pgSectionModal.pgSectionModalLng
                : pgSectionModalLng,
          },
        }}
      >
        {({ values, setFieldValue, setValues }) => {
          values = {
            ...values,
            pgSectionModal: {
              ...values.pgSectionModal,
              modalMediaTypeId: values.pgSectionModal.modalMediaTypeId > 0 ? values.pgSectionModal.modalMediaTypeId : 1,
              modalTypeId: values.pgSectionModal.modalTypeId > 0 ? values.pgSectionModal.modalTypeId : 1,
            },
          };
          const url = values?.pgSectionModal?.url?.match(regEx);

          return (
            <>
              <>
                <div className={`bg-white px-4  overflow-hidden      ${isOpen ? 'py-3 h-full  ' : '  py-0 h-0'}`}>
                  <div className="">
                    <div className="flex justify-between relative items-center">
                      <div className="flex transform -translate-y-1 relative z-10">
                        {t('Section Type')} : {t('Modal')}
                      </div>
                      <div>
                        <div>
                          <LanguageList
                            languages={languages.data?.data}
                            languageId={languageId}
                            setLanguageId={setLanguageId}
                            className="flex justify-end transform translate-y-1 relative z-10 mb-4"
                          />
                        </div>
                      </div>
                      <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />
                    </div>
                  </div>
                  <div className="bg-gray-100 p-4">
                    <div> {t('Section Settings')} </div>
                    <hr className="mt-2 mb-4 block" />
                    <div className="grid grid-cols-3 gap-6 items-center flex">
                      <div>
                        <label
                          className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                        >
                          <Field
                            className="border rounded p-2 w-full"
                            as="select"
                            name="pgSectionModal.modalTypeId"
                            onChange={(e) => {
                              setFieldValue('pgSectionModal.modalTypeId', parseInt(e.target.value));
                            }}
                          >
                            {modalTypeIds.map((modalTypeId, index) => (
                              <option value={parseInt(modalTypeId.id)} key={modalTypeId.id}>
                                {t(modalTypeId.name)}
                              </option>
                            ))}
                          </Field>
                          <Field
                            className="border rounded p-2 w-full"
                            as="select"
                            name="pgSectionModal.modalMediaTypeId"
                            onChange={(e) => {
                              setFieldValue('pgSectionModal.modalMediaTypeId', parseInt(e.target.value));
                            }}
                          >
                            {modalMediaTypeIds.map((modalMediaTypeId, index) => (
                              <option value={parseInt(modalMediaTypeId.id)} key={modalMediaTypeId.id}>
                                {t(modalMediaTypeId.name)}
                              </option>
                            ))}
                          </Field>
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* <FieldArray
                    name="pgStatistics.pgStatisticsItems"
                    render={(arrayHelpers) => (
                      <>
                        {values.pgStatistics.pgStatisticsItems.map((pgStatisticsItem, index) => {
                          return (
                            <Fragment key={index}>
                              <>
                                <StatisticItem
                                  languageId={languageId}
                                  languages={languages}
                                  pgStatisticsItem={pgStatisticsItem}
                                  setFieldValue={setFieldValue}
                                  pgStatisticsItemIndex={index}
                                  pgStatisticsItemsHelper={arrayHelpers}
                                />
                              </>
                            </Fragment>
                          );
                        })}
                      </>
                    )}
                  /> */}
                  <FieldArray
                    name="pgSectionModal.pgSectionModalLng"
                    render={(arrayHelpers) => (
                      <>
                        {values.pgSectionModal.pgSectionModalLng.map((e, index) => {
                          return (
                            <Fragment key={index}>
                              {languageId === e.languageId && (
                                <>
                                  <label>
                                    <Field
                                      className="border rounded  w-full  p-2 mt-2 "
                                      type="text"
                                      placeholder={t('Add Title')}
                                      name={`pgSectionModal.pgSectionModalLng[${index}].title`}
                                    />
                                  </label>
                                </>
                              )}
                            </Fragment>
                          );
                        })}
                      </>
                    )}
                  />
                  {values.pgSectionModal.modalMediaTypeId === 3 && (
                    <FieldArray
                      name="pgSectionModal.pgSectionModalLng"
                      render={(arrayHelpers) => (
                        <>
                          {values.pgSectionModal.pgSectionModalLng.map((e, index) => {
                            return (
                              <Fragment key={index}>
                                {languageId === e.languageId && (
                                  <>
                                    <label className="mt-4 block">
                                      <RichTextEditor
                                        fieldName={`pgSectionModal.pgSectionModalLng[${index}].text`}
                                        fieldValue={values.pgSectionModal.pgSectionModalLng[index].text}
                                        setFieldValue={setFieldValue}
                                      />
                                      {/* <Field
                                        className="border rounded  w-full  p-2 mt-2 "
                                        type="text"
                                        placeholder={t('Add Title')}
                                        name={`pgSectionModal.pgSectionModalLng[${index}].text`}
                                      /> */}
                                    </label>
                                  </>
                                )}
                              </Fragment>
                            );
                          })}
                        </>
                      )}
                    />
                  )}
                  {values.pgSectionModal.modalMediaTypeId === 2 && (
                    <div className="h-64 focus:outline-none group rounded border-dashed border-2 mb-4 relative mt-4">
                      {(url && (
                        <>
                          <iframe
                            style={{ width: '100%' }}
                            height="315"
                            src={`https://www.youtube.com/embed/${url[1]}`}
                            title="video"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                          <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                            <AddYoutube edit={true} setField={setFieldValue} fieldName={t('pgSectionModal.url')} />
                          </div>
                        </>
                      )) || (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <AddYoutube edit={true} setField={setFieldValue} fieldName={t('pgSectionModal.url')} />
                        </div>
                      )}
                    </div>
                  )}
                  {values.pgSectionModal.modalMediaTypeId === 1 && (
                    <div className="h-52 focus:outline-none group rounded border-dashed border-2 mb-2 relative mt-4">
                      {(values.pgSectionModal.url && (
                        <>
                          <div className="flex items-center justify-center h-full p-2">
                            <img
                              className="w-full h-full object-contain"
                              alt="img"
                              src={`${process.env.REACT_APP_IMAGE_PATH}${values.pgSectionModal.url}`}
                            />{' '}
                          </div>
                          <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                            <AddCardImage edit={true} setField={setFieldValue} fieldName={t('pgSectionModal.url')} />
                          </div>
                        </>
                      )) || (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <AddCardImage fieldName={t('pgSectionModal.url')} setField={setFieldValue} />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="flex justify-end mt-4">
                    <button
                      type="button"
                      disabled={editModalMutation.isLoading}
                      onClick={() => {
                        onSaveSettings(values);
                      }}
                      className={`bg-darkblue relative focus:outline-none space-x-1 ${
                        i18n.language === 'en' ? '' : 'space-x-reverse'
                      } text-sm font-bold text-white flex items-center hover:bg-darkbluehover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
                    >
                      {t('Update Modal')}
                      {editModalMutation.isLoading && <LoadingCircle />}
                    </button>
                  </div>

                  <div className={`flex justify-end py-4 space-x-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                    {/* <AddStaticItem
                      pgStatisticsId={section.pgStatisticsId}
                      languages={languages}
                      orderNumber={section.pgStatistics.pgStatisticsItems.length}
                      addStaticItemMutation={addStaticItemMutation}
                      // addCardItemMutation={addCardItemMutation}
                    /> */}
                    {/* <button
                    type="button"
                    onClick={() => {
                      onSubmit(values);
                    }}
                    className="bg-lightgreen py-2 px-2 flex justify-center text-center h-full text-sm font-bold text-white items-center hover:bg-lightgreenhover text-centerhover shadow  rounded transition duration-300 ease-in-out"
                  >
                    {t('Save Section')}
                  </button> */}
                  </div>
                </div>
              </>
            </>
          );
        }}
      </Formik>
    </div>
  );
}
