import { Field, FieldArray, Form, Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import Facebook from '../../SharedComponents/Svg/Facebook';
import Twitter from '../../SharedComponents/Svg/Twitter';
import Linkedin from '../../SharedComponents/Svg/Linkedin';
import Instagram from '../../SharedComponents/Svg/Instagram';
import Youtube from '../../SharedComponents/Svg/Youtube';
import Vimeo from '../../SharedComponents/Svg/Vimeo';
import Google from '../../SharedComponents/Svg/Google';
import Flag from '../../SharedComponents/Svg/Flag';
import Home from '../../SharedComponents/Svg/Home';
import Phone from '../../SharedComponents/Svg/Phone';
import { editSetting, getLanguages, getSetting } from './ApiSettings';
import Share from '../../SharedComponents/Svg/Share';
import Cog from '../../SharedComponents/Svg/Cog';
import AddFavicon from './AddFavicon';
import Branch from './Branch';
import AddBranch from './AddBranch';
import Notification from '../../SharedComponents/Notification';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import SaveBranchOrder from './SaveBranchOrder';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import LanguageList from '../../SharedComponents/LanguageList';
import Email from '../../SharedComponents/Svg/Email';
import EmailSettings from './EmailSettings';
import Loading from '../../SharedComponents/Loading';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const BranchList = React.memo(function BranchList({ branches, languageId }) {
  return branches.map((branch, index) => (
    // <Quote quote={quote} index={index} key={quote.id} />
    <Fragment key={branch.id}>{branch && <Branch languageId={languageId} index={index} branch={branch} />}</Fragment>
  ));
});

export default function Settings(props) {
  const [entries, setEntries] = useState(10);
  const [activeTab, setActiveTab] = useState(0);
  const { t, i18n } = useTranslation();
  const [optionsActiveTab, setOptionsActiveTab] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [languageId, setLanguageId] = useState(1);

  const [searchTerm, setSearchTerm] = useState('');

  const [searchLanguage, setSearchlanguage] = useState(0);

  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  const { data, isLoading, isError, isSuccess } = useQuery(['settings'], getSetting, {
    staleTime: 90000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      setState({
        ...state,
        branches: data.data.branchs.sort(function (a, b) {
          return a.orderNum - b.orderNum;
        }),
      });
    },
  });
  const [success, setSuccess] = useState(false);
  const [state, setState] = useState({ branches: [] });

  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const mutation = useMutation(editSetting, {
    onSuccess: async (data) => {
      queryClient.invalidateQueries(['settings']);

      if (data.isDone) {
        queryClient.invalidateQueries(['settings']);
      }
    },
  });
  const onSubmit = async (e) => {
    try {
      await mutation.mutate(e);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (error) {
      console.log('error', error);
    }
  };
  const tabData = [
    {
      svg: <Flag />,
      title: t('Seo'),
    },
    {
      svg: <Phone />,
      title: t('Contact Us'),
    },
    {
      svg: <Email />,
      title: t('Email Settings'),
    },
  ];
  const optionsTabData = [
    {
      svg: <Cog />,
      title: t('Favicon'),
    },
    {
      svg: <Share />,
      title: t('Social Media'),
    },
  ];

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const branches = reorder(state.branches, result.source.index, result.destination.index);

    setState({ branches });
  }
  if (isLoading) return <Loading />;

  return (
    <>
      {success && <Notification message={`${t('Settings Updated')}`} />}
      <header className="flex justify-between items-center mb-4">
        <BreadCrumbs to="/settings" name={t('Settings')} />
        <div></div>
      </header>
      {/* Content */}
      {data && data?.data && (
        <Formik onSubmit={onSubmit} initialValues={data.data}>
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <Field className="hidden" type="number" name="id" />
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-2 bg-white shadow border rounded p-4 text-gray-600 font-bold">
                    <div className="flex justify-between relative items-center">
                      <div className="flex transform -translate-y-1 relative z-10">
                        {tabData.map((e, index) => (
                          <div
                            key={index}
                            className={`flex justify-center ${
                              t.language === 'en' ? '' : 'space-x-reverse'
                            } items-center space-x-2 ${
                              activeTab === index ? 'text-pagetabhover border-pagetabhover' : ''
                            } hover:text-pagetabhover pb-4 px-4 border-b-2 hover:border-pagetabhover transition duration-300 ease-in-out cursor-pointer `}
                            onClick={(e) => setActiveTab(index)}
                          >
                            {e.svg}
                            <span>{e.title}</span>
                          </div>
                        ))}{' '}
                      </div>
                      <div>
                        <div>
                          {languages && languages.data && (
                            <LanguageList
                              className="flex justify-end transform translate-y-1 relative z-10 mb-4"
                              languages={languages?.data?.data}
                              languageId={languageId}
                              setLanguageId={setLanguageId}
                            />
                          )}
                        </div>
                      </div>
                      <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />
                    </div>
                    <div>
                      {activeTab === 0 && (
                        <>
                          <div className="mb-4">
                            <span>{t('Logo')}</span>
                            <div className="h-24 focus:outline-none group rounded border-dashed border-2 mb-2 relative">
                              {(values.logoURL && (
                                <>
                                  {' '}
                                  <div className="flex items-center justify-center h-full p-2">
                                    <img
                                      className="w-full h-full object-contain"
                                      alt="img"
                                      src={`${process.env.REACT_APP_IMAGE_PATH}${values.logoURL}`}
                                    />{' '}
                                  </div>
                                  <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                                    <AddFavicon edit={true} setField={setFieldValue} fieldName="logoURL" />
                                  </div>
                                </>
                              )) || (
                                <div className="absolute inset-0 flex items-center justify-center">
                                  <AddFavicon fieldName="logoURL" setField={setFieldValue} />
                                </div>
                              )}
                            </div>
                          </div>

                          {values &&
                            values.settingLngs &&
                            values?.settingLngs.map((setting, index) => (
                              <FieldArray
                                key={index}
                                name="settingLngs"
                                render={(arrayHelpers) => (
                                  <>
                                    {languageId === setting.languageId && (
                                      <>
                                        <label>
                                          <b className="font-semibold block text-gray-700">{t('Website Title')}</b>
                                          <Field
                                            className="border rounded  w-full mb-4 p-1"
                                            type="text"
                                            name={`settingLngs[${index}].websiteTitle`}
                                          />
                                        </label>
                                        <label>
                                          <b className="font-semibold block text-gray-700">{t('CMS Title')}</b>
                                          <Field
                                            className="border rounded  w-full mb-4 p-1"
                                            type="text"
                                            name={`settingLngs[${index}].cmsTitle`}
                                          />
                                        </label>
                                        <label>
                                          <b className="font-semibold block text-gray-700">{t('SEO Description')}</b>
                                          <Field
                                            className="border rounded  w-full mb-4 p-1"
                                            as="textarea"
                                            name={`settingLngs[${index}].seoDescription`}
                                          />
                                        </label>
                                        <label>
                                          <b className="font-semibold block text-gray-700">{t('keywords')}</b>
                                          <Field
                                            className="border rounded  w-full mb-4 p-1"
                                            type="text"
                                            name={`settingLngs[${index}].keyWords`}
                                          />
                                        </label>
                                      </>
                                    )}
                                  </>
                                )}
                              />
                            ))}

                          <Field
                            type="text"
                            name="map"
                            placeholder="Add Map Url"
                            className="w-full border focus:outline-none px-2 py-1 rounded mb-4 "
                          />
                          <div className="text-[#666666] w-full map" dangerouslySetInnerHTML={{ __html: values.map }} />
                        </>
                      )}
                      {activeTab === 1 && (
                        <>
                          <div
                            className={` ${
                              i18n.language === 'en' ? '' : 'space-x-reverse'
                            } flex space-x-3 justify-end mb-4`}
                          >
                            <SaveBranchOrder state={state} />
                            <AddBranch orderNum={state.branches.length + 500} />
                          </div>

                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="list">
                              {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                  <BranchList branches={state.branches} languageId={languageId} />
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </>
                      )}
                      {activeTab === 2 && <EmailSettings />}
                    </div>
                    {activeTab !== 2 && (
                      <div className="justify-end flex space-x-4">
                        <button
                          type="submit"
                          className="bg-darkblue hover:bg-darkbluehover text-white px-4 py-2 rounded"
                        >
                          {t('Update')}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="space-y-8">
                    {/* Page Options */}
                    <div className="bg-white p-4 rounded shadow">
                      <div className="relative items-center">
                        <div className="flex justify-evenly transform translate-y-1 relative z-10">
                          {optionsTabData.map((e, index) => (
                            <div
                              key={index}
                              className={`flex justify-center items-center ${
                                t.language === 'en' ? '' : 'space-x-reverse'
                              } space-x-2 w-1/2 ${
                                optionsActiveTab === index ? 'text-pagetabhover border-pagetabhover' : ''
                              } hover:text-pagetabhover pb-4 px-4 border-b-2 hover:border-pagetabhover transition duration-300 ease-in-out cursor-pointer `}
                              onClick={(e) => setOptionsActiveTab(index)}
                            >
                              {e.svg}
                              <span>{e.title}</span>
                            </div>
                          ))}{' '}
                        </div>

                        <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />
                      </div>
                      <div className="my-4 space-y-4 relative text-gray-600">
                        {optionsActiveTab === 0 && (
                          <>
                            <div>
                              <span>{t('Favicon 32 x 32')}</span>
                              <div className="h-24 focus:outline-none group rounded border-dashed border-2 mb-2 relative">
                                {(values.favicon32URL && (
                                  <>
                                    {' '}
                                    <div className="flex items-center justify-center h-full p-2">
                                      <img
                                        className="w-full h-full object-contain"
                                        alt="img"
                                        src={`${process.env.REACT_APP_IMAGE_PATH}${values.favicon32URL}`}
                                      />{' '}
                                    </div>
                                    <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                                      <AddFavicon edit={true} setField={setFieldValue} fieldName="favicon32URL" />
                                    </div>
                                  </>
                                )) || (
                                  <div className="absolute inset-0 flex items-center justify-center">
                                    <AddFavicon fieldName="favicon32URL" setField={setFieldValue} />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div>
                              <span>{t('Favicon 180 x 180')}</span>
                              <div className="h-24 focus:outline-none group rounded border-dashed border-2 mb-2 relative">
                                {(values.favicon180URL && (
                                  <>
                                    {' '}
                                    <div className="flex items-center justify-center h-full p-2">
                                      <img
                                        className="w-full h-full object-contain"
                                        alt="img"
                                        src={`${process.env.REACT_APP_IMAGE_PATH}${values.favicon180URL}`}
                                      />{' '}
                                    </div>
                                    <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                                      <AddFavicon edit={true} setField={setFieldValue} fieldName="favicon180URL" />
                                    </div>
                                  </>
                                )) || (
                                  <div className="absolute inset-0 flex items-center justify-center">
                                    <AddFavicon fieldName="favicon180URL" setField={setFieldValue} />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div>
                              <span>{t('Favicon 192 x 192')}</span>
                              <div className="h-24 focus:outline-none group rounded border-dashed border-2 mb-2 relative">
                                {(values.favicon192URL && (
                                  <>
                                    <div className="flex items-center justify-center h-full p-2">
                                      <img
                                        className="w-full h-full object-contain"
                                        alt="img"
                                        src={`${process.env.REACT_APP_IMAGE_PATH}${values.favicon192URL}`}
                                      />
                                    </div>
                                    <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                                      <AddFavicon edit={true} fieldName="favicon192URL" setField={setFieldValue} />
                                    </div>
                                  </>
                                )) || (
                                  <div className="absolute inset-0 flex items-center justify-center">
                                    <AddFavicon fieldName="favicon192URL" setField={setFieldValue} />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div>
                              <span>{t('Favicon 270 x 270')}</span>
                              <div className="h-24 focus:outline-none group rounded border-dashed border-2 mb-2 relative">
                                {(values.favicon270URL && (
                                  <>
                                    {' '}
                                    <div className="flex items-center justify-center h-full p-2">
                                      <img
                                        className="w-full h-full object-contain"
                                        alt="img"
                                        src={`${process.env.REACT_APP_IMAGE_PATH}${values.favicon270URL}`}
                                      />{' '}
                                    </div>
                                    <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                                      <AddFavicon fieldName="favicon270URL" edit={true} setField={setFieldValue} />
                                    </div>
                                  </>
                                )) || (
                                  <div className="absolute inset-0 flex items-center justify-center">
                                    <AddFavicon fieldName="favicon270URL" setField={setFieldValue} />
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        {optionsActiveTab === 1 && (
                          <div className="flex flex-col space-y-3">
                            <div className="flex rounded-md shadow-sm border">
                              <Facebook className="w-11 h-7 p-1  bg-gray-100 text-gray-500" />
                              <Field
                                type="text"
                                name="facebookLink"
                                placeholder="Add Facebook link"
                                className="w-full focus:outline-none px-4 "
                              />
                            </div>
                            <div className="flex rounded-md shadow-sm border">
                              <Twitter className="w-11 h-7 p-1  bg-gray-100 text-gray-500" />
                              <Field
                                type="text"
                                name="twitterLink"
                                placeholder="Add Twitter link"
                                className="w-full focus:outline-none px-4 "
                              />
                            </div>
                            <div className="flex rounded-md shadow-sm border">
                              <Linkedin className="w-11 h-7 p-1  bg-gray-100 text-gray-500" />
                              <Field
                                type="text"
                                name="linkedinLink"
                                placeholder="Add Linkedin link"
                                className="w-full focus:outline-none px-4 "
                              />
                            </div>
                            <div className="flex rounded-md shadow-sm border">
                              <Instagram className="w-11 h-7 p-1  bg-gray-100 text-gray-500" />
                              <Field
                                type="text"
                                name="instagramLink"
                                placeholder="Add Instagram link"
                                className="w-full focus:outline-none px-4 "
                              />
                            </div>
                            <div className="flex rounded-md shadow-sm border">
                              <Youtube className="w-11 h-7 p-1  bg-gray-100 text-gray-500" />
                              <Field
                                type="text"
                                name="youTubeLink"
                                placeholder="Add Youtube link"
                                className="w-full focus:outline-none px-4 "
                              />
                            </div>
                            <div className="flex rounded-md shadow-sm border">
                              <Vimeo className="w-11 h-7 p-1  bg-gray-100 text-gray-500" />
                              <Field
                                type="text"
                                name="vimeoLink"
                                placeholder="Add Vimeo link"
                                className="w-full focus:outline-none px-4 "
                              />
                            </div>
                            <div className="flex rounded-md shadow-sm border">
                              <Google className="w-11 h-7 p-1  bg-gray-100 text-gray-500" />
                              <Field
                                type="text"
                                name="googleLink"
                                placeholder="Add Google link"
                                className="w-full focus:outline-none px-4 "
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="justify-end flex space-x-4">
                        <button
                          type="submit"
                          className="bg-darkblue hover:bg-darkbluehover text-white px-4 py-2 rounded"
                        >
                          {t('Update')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}{' '}
    </>
  );
}
