import AddImage from '../Views/Gallery/Components/AddImage';

export default function ImageContainer(props) {
  const { imageUrl, setFieldValue } = props;
  return (
    <>
      {(imageUrl && (
        <>
          <img className="object-fit h-64 w-full" alt="img" src={`${process.env.REACT_APP_IMAGE_PATH}${imageUrl}`} />
          <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
            <AddImage edit={true} setField={setFieldValue} />
          </div>
        </>
      )) || (
        <div className="absolute inset-0 flex items-center justify-center">
          <AddImage setField={setFieldValue} />
        </div>
      )}
    </>
  );
}
