import React from 'react';
import { useTranslation } from 'react-i18next';

// Rendering individual images
const Image = ({ image }) => {
  return (
    <div className="file-item border">
      <img style={{ maxHeight: 50 }} alt={`img - ${image.id}`} src={image.src} className="file-img" />
    </div>
  );
};

// ImageList Component
const ImageList = ({ images }) => {
  const { t } = useTranslation();
  // render each image by calling Image component
  const renderImage = (image, index) => {
    return <Image image={image} key={`${image.id}-image`} />;
  };

  // Return the list of files
  return (
    <section className="file-list border mb-4 p-2 flex items-center text-sm space-x-2 text-gray-400">
      {images.map(renderImage)} <span> {t('File Upload to server successfully')}</span>
    </section>
  );
};

export default ImageList;
