import { Field, FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../SharedComponents/Notification';
import ChevronRight from '../../SharedComponents/Svg/ChevronRight';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import { editBranch } from './ApiSettings';
import DeleteBranch from './DeleteBranch';
import DeleteTelephone from './DeleteTelephone';

export default function Branch(props) {
  const { branch, languageId, index } = props;
  const [success, setSuccess] = useState(false);
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const mutation = useMutation(editBranch, {
    onSuccess: async (data) => {
      // queryClient.invalidateQueries(["settings"]);
      queryClient.fetchQuery(['settings']);

      if (data.isDone) {
        queryClient.invalidateQueries(['settings']);
      }
    },
  });
  const onSubmit = async (e) => {
    try {
      await mutation.mutate(e);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <Draggable draggableId={`${branch.id}`} index={index}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            {success && <Notification message="Bransh Updated" />}

            <div className="border mb-4 shadow bg-white ">
              <div className="  px-4 p-3  flex justify-between">
                <div className="flex items-center space-x-2">
                  <button
                    type="button"
                    className="focus:outline-none "
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    <ChevronRight
                      className={`w-4 h-4 transform ${isOpen ? 'rotate-90' : 'rotate-0'}  transition-all duration-500`}
                    />
                  </button>
                  <div>
                    {branch.branchLngs.map((x) => {
                      return <span key={x.id}>{languageId === x.languageId && <> {x.name} </>}</span>;
                    })}
                  </div>
                </div>
                <div>
                  <DeleteBranch id={branch.id} />
                </div>
              </div>
              <div className={`bg-gray-100 px-4  overflow-hidden      ${isOpen ? 'py-3 h-full  ' : '  py-0 h-0'}`}>
                <Formik initialValues={branch}>
                  {({ values, setFieldValue, setValues }) => {
                    return (
                      <Form>
                        {values &&
                          values.branchLngs &&
                          values?.branchLngs.map((branchLanguage, index) => (
                            <FieldArray
                              key={index}
                              name="branchLngs"
                              render={(arrayHelpers) => (
                                <>
                                  {languageId === branchLanguage.languageId && (
                                    <>
                                      <label>
                                        <b className="font-semibold block text-gray-700">{t('Address')}</b>
                                        <Field
                                          className="border rounded  w-full mb-4 p-1"
                                          type="text"
                                          name={`branchLngs[${index}].address`}
                                        />
                                      </label>
                                    </>
                                  )}
                                </>
                              )}
                            />
                          ))}
                        <label>
                          <b className="font-semibold block text-gray-700">{t('whatsApp Number')}</b>
                          <Field className="border rounded  w-full mb-4 p-1" type="text" name={`whatsAppNumber`} />
                        </label>
                        <label>
                          <b className="font-semibold block text-gray-700">{t('Email')}</b>
                          <Field className="border rounded  w-full mb-4 p-1" type="text" name={`email`} />
                        </label>
                        <label>
                          <b className="font-semibold block text-gray-700">{t('Location')}</b>
                          <Field className="border rounded  w-full mb-4 p-1" as="textarea" name={`location`} />
                        </label>
                        <label>
                          <b className="font-semibold block text-gray-700">{t('Open Hour')}</b>
                          <Field className="border rounded  w-full mb-4 p-1" type="text" name={`openHour`} />
                        </label>
                        <div className="grid grid-cols-2 gap-4">
                          <label>
                            <b className="font-semibold block text-gray-700">{t('From')}</b>
                            <Field className="border rounded  w-full mb-4 p-1" type="date" name={`from`} />
                          </label>
                          <label>
                            <b className="font-semibold block text-gray-700">{t('to')}</b>
                            <Field className="border rounded  w-full mb-4 p-1" type="date" name={`from`} />
                          </label>
                        </div>
                        <label>
                          <b className="font-semibold block text-gray-700">{t('day From To')}</b>
                          <Field className="border rounded  w-full mb-4 p-1" type="text" name={`dayFromTo`} />
                        </label>
                        <b className="font-semibold block text-gray-700">{t('Telephones')}</b>
                        {values &&
                          values.telephones &&
                          values?.telephones.map((telephone, index) => (
                            <FieldArray
                              key={index}
                              name="telephones"
                              render={(arrayHelpers) => (
                                <>
                                  <label className="flex space-x-3">
                                    <Field
                                      className="border rounded  w-full mb-4 p-1"
                                      type="text"
                                      placeholder="Tel"
                                      name={`telephones[${index}].number`}
                                    />
                                    <DeleteTelephone
                                      index={index}
                                      deleteTelephoneHelpers={arrayHelpers}
                                      id={telephone.id}
                                    />
                                  </label>
                                </>
                              )}
                            />
                          ))}
                        <div className="text-center">
                          <button
                            type="button"
                            onClick={() => {
                              setValues({
                                ...values,
                                telephones: [...values.telephones, { number: '' }],
                              });
                            }}
                          >
                            <PlusCircle className="h-6 w-6 text-green-600" />
                          </button>
                        </div>
                        <b className="font-semibold block text-gray-700">{t('faxs')}</b>
                        {values &&
                          values.faxs &&
                          values?.faxs.map((fax, index) => (
                            <FieldArray
                              key={index}
                              name="faxs"
                              render={(arrayHelpers) => (
                                <>
                                  <label className="flex space-x-3">
                                    <Field
                                      className="border rounded  w-full mb-4 p-1"
                                      type="text"
                                      placeholder="Tel"
                                      name={`faxs[${index}].number`}
                                    />
                                    <DeleteTelephone id={branch.id} />
                                  </label>
                                </>
                              )}
                            />
                          ))}
                        <div className="text-center">
                          <button
                            type="button"
                            onClick={() => {
                              setValues({
                                ...values,
                                faxs: [...values.faxs, { number: '' }],
                              });
                            }}
                          >
                            <PlusCircle className="h-6 w-6 text-green-600" />
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            onClick={() => onSubmit(values)}
                            className="bg-lightgreen space-x-1 text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out"
                          >
                            <PlusCircle className="w-5 h-5" /> <span>{t('Save Branch')}</span>
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    </>
  );
}
