import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Modal from '../../SharedComponents/Modal';
import Notification from '../../SharedComponents/Notification';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import Trash from '../../SharedComponents/Svg/Trash';
import { addFolder, deleteFolder } from './ApiAssets';

export default function DeleteFolder(props) {
  const { id } = props;
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const deleteFolderMutation = useMutation(deleteFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries(['assetFolders']);
      setIsOpen(false);
    },
  });
  const onSubmit = async (e) => {
    try {
      deleteFolderMutation.mutate({ id });
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {deleteFolderMutation.isError && (
        <Notification
          error={true}
          setExtraIsOpen={setIsOpen}
          message={t('Sorry, This Folder have Data Inside, Please Delete and Retry')}
        />
      )}
      {deleteFolderMutation.isSuccess && <Notification message={t('The folder has been deleted successfully')} />}

      <button
        onClick={() => {
          onSubmit();
        }}
        className={`text-left flex items-center ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } space-x-2 hover:bg-gray-100 px-2 py-1`}
      >
        <Trash className="w-4 h-4 " />
        <span>{t('Delete')} </span>
      </button>
    </>
  );
}
