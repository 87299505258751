import { useMutation, useQuery, useQueryClient } from 'react-query';
import AddUser from './AddUser';
import { addUser, assignRole, getLanguages, getRoles, getUsers } from './ApiUser';
import { useState, useEffect } from 'react';
import EditUser from './EditUser';
import BreadCrumbs from '../../../SharedComponents/BreadCrumbs';
import { useTranslation } from 'react-i18next';
import { Field, Formik, Form } from 'formik';
import LoadingCircle from '../../../SharedComponents/Svg/LoadingCircle';
import { toast } from 'react-toastify';
import Notification from '../../../SharedComponents/Notification';

export default function CreateUsers() {
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const roles = useQuery(['roles'], getRoles);
  const [isOpen, setIsOpen] = useState(false);
  const [newUserRole, setNewUserRole] = useState(1);

  const assignNewRole = useMutation(assignRole, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['users']);
      if (data.isDone) {
        setIsOpen(false);
      }
    },
  });
  const { mutate, data, isLoading } = useMutation(addUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['users']);
      if (data.isDone) {
        toast(<Notification message={t('user Added')} />);

        try {
          const roleData = {
            userId: data.outId,
            rolesIds: [newUserRole],
            deleteOldRoles: true,
          };
          assignNewRole.mutate(roleData);
        } catch (error) {}
        // setIsOpen(false);
      }
    },
  });
  const onSubmit = async (e) => {
    const newData = { ...e, role: parseInt(e.role) };
    setNewUserRole(parseInt(e.role));

    try {
      await mutate(newData);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <header className="flex justify-between">
        <BreadCrumbs to="/users" name={t('Users')} />
      </header>
      {/* Table */}
      <div className="bg-white rounded border border-[#e6e7e9] shadow-md mt-5 ">
        <div className="p-6 px-5">
          <div className="border-[#eaedf0] border-b flex   justify-between relative  ">
            <div className={` flex items-center space-x-3 mb-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
              <h2 className="text-sm font-bold uppercase">{t('Add / Edit User')}</h2>
            </div>
          </div>
        </div>
        <div className="pb-6 px-5">
          <Formik
            initialValues={{
              userName: '',
              password: '',
              fullName: '',
              email: '',
              role: 1,
              isActive: true,
            }}
            onSubmit={(values, { resetForm }) => {
              onSubmit(values);
              resetForm();
            }}
          >
            <Form className="w-1/2">
              {data?.isDone === false && (
                <span className="border-red-400 mb-4 border text-center block rounded mt-4 text-red-400 p-2">
                  {data?.isMessage}
                </span>
              )}
              <label className=" w-full">
                <p className="text-[#666666] text-sm font-medium pt-4 pb-2">{t('User Full Name')}</p>
                <Field
                  style={{ textIndent: '10px' }}
                  type="text"
                  name="fullName"
                  className="w-full p-3 border border-[#d1d5db] rounded-md"
                />
              </label>

              <label className=" w-full">
                <p className="text-[#666666] text-sm font-medium pt-4 pb-2">{t('User Name')}</p>
                <Field
                  style={{ textIndent: '10px' }}
                  type="text"
                  placeholder={t('Enter Name')}
                  name="userName"
                  className="w-full p-3 border border-[#d1d5db] rounded-md"
                />
              </label>
              <label className=" w-full">
                <p className="text-[#666666] text-sm font-medium pt-4 pb-2">{t('E-mail')}</p>
                <Field
                  style={{ textIndent: '10px' }}
                  name="email"
                  type="text"
                  className="w-full p-3 border border-[#d1d5db] rounded-md"
                />
              </label>
              <label className=" w-full">
                <p className="text-[#666666] text-sm font-medium pt-4 pb-2">{t('Password')}</p>
                <Field
                  style={{ textIndent: '10px' }}
                  name="password"
                  type="password"
                  className="w-full p-3 border border-[#d1d5db] rounded-md"
                />
              </label>
              <label className=" w-full">
                <p className="text-[#666666] text-sm font-medium pt-4 pb-2">{t('Role')}</p>

                <Field
                  style={{ textIndent: '10px' }}
                  placeholder="Enter your new password"
                  className="w-full p-3 border border-[#d1d5db] rounded-md"
                  name="role"
                  as="select"
                >
                  {roles &&
                    roles.data &&
                    roles.data.data &&
                    roles.data.data.map((role) => {
                      return (
                        <option key={role.id} value={`${role.id}`}>
                          {role.name}
                        </option>
                      );
                    })}
                </Field>
              </label>
              <button
                type="submit"
                className="bg-darkblue w-24 mt-7 flex justify-center items-center hover:bg-darkbluehover text-white px-4 py-2 rounded"
              >
                {t('Save')}
                {isLoading && <LoadingCircle />}
              </button>
            </Form>
          </Formik>
        </div>
      </div>{' '}
    </>
  );
}
