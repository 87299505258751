import axios from 'axios';

export const deleteCard = async (e) => {
  const { data } = await axios.post('/PgSection/DeleteCardItem', e);
  return data;
};

export const editCard = async (e) => {
  const { data } = await axios.post('/PgSection/EditCard', e);
  return data;
};

export const editCardItem = async (e) => {
  const { data } = await axios.post('/PgSection/EditCardItem', e);
  return data;
};
