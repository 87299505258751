import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../SharedComponents/Modal';
import React, { useCallback } from 'react';
import DropZone from '../DropZone';
import ImageList from '../ImageList';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { editAsset, getFolders, getLanguages, uploadMedia } from '../ApiAssets';
import Loading from '../../../SharedComponents/Loading';
import PecnilAlt from '../../../SharedComponents/Svg/PencilAlt';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../../SharedComponents/LanguageList';

export default function EditAsset(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { id, asset, withText } = props;
  return (
    <>
      {withText ? (
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`bg-darkblue hover:bg-darkbluehover flex items-center ${
            i18n.language === 'en' ? '' : 'space-x-reverse'
          } space-x-2 text-xs transition duration-500 text-white rounded p-1`}
        >
          <PecnilAlt className="w-4 h-4" /> <span>{t('Edit')}</span>
        </button>
      ) : (
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="bg-darkblue hover:bg-darkbluehover transition duration-500 text-white rounded p-1"
        >
          <PecnilAlt className="w-5 h-5" />
        </button>
      )}

      {isOpen && <ModalContainer id={id} asset={asset} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, id, asset } = props;
  const [languageId, setLanguageId] = useState(1);

  // localState for image uploaded
  const [images, setImages] = useState([]);
  const [file, setFile] = useState();
  const [filePath, setFilePath] = useState('');
  const [uploading, setUploading] = useState(false);
  const { t, i18n } = useTranslation();
  const mediaUpload = useMutation(uploadMedia, {
    onSuccess: (e) => {
      setFilePath(e.data.path);
      setUploading(false);
    },
  });
  // onDrop function
  const onDrop = useCallback((acceptedFiles) => {
    setUploading(true);
    // loop through the accepted Files
    acceptedFiles.map((file) => {
      // Initialize FileReader browser API
      const reader = new FileReader();
      // onload callback gets called after the reader reads the file data
      reader.onload = function (e) {
        console.log(e.target);
        console.log(e);
        // add the image into the state. Since FileReader reading process is asynchronous, its better to get the latest snapshot state (i.e., prevState) and update it.
        setImages((prevState) => [{ id: 1, src: e.target.result }]);
      };
      // Read the file as Data URL (since we accept only images)
      reader.readAsDataURL(file);

      return file;
    });
    // this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too

    setFile(acceptedFiles);

    const handleServer = async () => {
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);

      try {
        await mediaUpload.mutate(formData);
      } catch (error) {
        console.log('error', error);
      }
    };
    handleServer();
  }, []);

  // useEffect to not let the Component Reload
  useEffect(() => {}, [filePath]);

  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [data]);

  // Query Data to Fetch Foldiers
  // Query to load Data
  const folders = useQuery(
    [
      'assetFolders',
      {
        pageNumber: 0,
        pageSize: 0,
        isPaging: false,
        langId: 0,
      },
    ],
    getFolders,
    {
      staleTime: 99900,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );
  // Handle Multiple Language Content in the Page
  const astAssetLngs = data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
    description: '',
    alt: '',
  }));
  //  Initial Values for Page Data
  const initialValues = {
    thumbnailUrl: 'string',
    fileUrl: filePath,
    imageDimention: 'string',
    mimeType: 'string',
    typeId: 1,
    statusId: 0,
    astFolderId: id,
    astAssetLngs: astAssetLngs,
  };
  const queryClient = useQueryClient();
  const { mutate } = useMutation(editAsset, {
    onSuccess: () => {
      queryClient.invalidateQueries(['assetFolder']);
      setIsOpen(false);
    },
  });
  const onSubmit = async (e) => {
    console.log(filePath);
    console.log(e.astFolderId);
    const newData = {
      ...e,
      fileUrl: `${filePath ? filePath : asset.fileUrl}`,
    };
    try {
      await mutate(newData);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {data && data.data && (
        <Formik onSubmit={onSubmit} initialValues={asset}>
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <Modal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onSubmit={onSubmit}
                  title={t('Upload New File')}
                  add={true}
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  <LanguageList
                    className="flex transform translate-y-1 relative z-10 mb-4"
                    languages={data?.data}
                    languageId={languageId}
                    setLanguageId={setLanguageId}
                  >
                    <hr className="border w-full absolute bottom-0 left-0 z-0" />
                  </LanguageList>
                  <div className=" focus:outline-none rounded border-dashed border-2 mb-4">
                    {/* Drag amd Drop Component */}
                    <DropZone
                      className="focus:outline-none"
                      onDrop={onDrop}
                      accept={'image/jpg,image/jpeg,image/png'}
                      images={images}
                      multiple={false}
                    />
                  </div>
                  {uploading && (
                    <div className="relative pt-1">
                      <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
                        <div
                          className={`shadow-none flex ${
                            uploading ? 'w-full' : 'w-0 '
                          } flex-col  transition-all ease-in-out duration-3000 text-center whitespace-nowrap text-white justify-center bg-orange`}
                        ></div>
                      </div>
                    </div>
                  )}
                  {/* To Display Uploaded Image */}
                  {!uploading && images.length > 0 && <ImageList images={images} />}
                  {/* Submit Form */}

                  <Field name="fileUrl" className="hidden" type="text" />
                  {values &&
                    values.astAssetLngs &&
                    values?.astAssetLngs.map((asset, index) => (
                      <FieldArray
                        key={index}
                        name="astAssetLngs"
                        render={(arrayHelpers) => (
                          <>
                            {languageId === asset.languageId && (
                              <>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700  ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Title')}
                                  </b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    type="text"
                                    name="title"
                                    name={`astAssetLngs[${index}].title`}
                                  />
                                </label>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('alt')}
                                  </b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    name={`astAssetLngs[${index}].alt`}
                                    type="text"
                                  />
                                </label>
                                <label>
                                  <b
                                    className={`font-semibold block text-gray-700 ${
                                      i18n.language === 'en' ? '' : 'text-right'
                                    }`}
                                  >
                                    {t('Description')}
                                  </b>
                                  <Field
                                    className="border rounded  w-full mb-4 p-1"
                                    name={`astAssetLngs[${index}].description`}
                                    type="text"
                                  />
                                </label>
                              </>
                            )}
                          </>
                        )}
                      />
                    ))}
                  <label>
                    <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                      {t('Folder')}
                    </b>
                    <Field className="border rounded  w-full mb-4 p-1" name="astFolderId" as="select">
                      {folders &&
                        folders.data &&
                        folders.data.data.map((e) => {
                          return <option value={parseInt(e.id)}>{e.name}</option>;
                        })}
                    </Field>
                  </label>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
