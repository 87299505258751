import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import resourcesIcon from '../../assets/img/resourcesIcon.svg';
import ChevronRight from '../Svg/ChevronRight';

export default function CmsRoutes(props) {
  const { layoutMenu, sidebarToggle, title } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`${
          isOpen ? 'bg-[#222222]' : ''
        } flex justify-between items-center  w-full duration-200 mt-4 py-2 px-6  hover:bg-[#222222]  hover:text-[#fdce00]`}
      >
        {/* {nav.icon} */}
        <div className={` flex items-center`}>
          <img alt="x" src={resourcesIcon} />
          {sidebarToggle && (
            <>
              <span className="mx-4">
                <div className="flex items-center space-x-2">
                  <b>{t(title)}</b>
                </div>
              </span>
            </>
          )}
        </div>

        <ChevronRight className={`w-5 h-5 transform ${isOpen ? '-rotate-90' : 'rotate-90'}`} />
      </button>
      {isOpen && (
        <>
          {layoutMenu.map((nav) => {
            return (
              <NavLink
                key={nav.id}
                to={nav.to}
                activeClassName="bg-[#222222] text-white border-white"
                activeStyle={{
                  borderColor: '#fff',
                }}
                className="flex items-center duration-200 mt-4 py-2 px-6 border-l-0  hover:bg-[#222222]  hover:text-[#fdce00]"
              >
                {nav.icon}
                {sidebarToggle && <span className="mx-4">{t(nav.name)}</span>}
              </NavLink>
            );
          })}
        </>
      )}
    </div>
  );
}
