import { useQuery, useQueryClient } from 'react-query';

import { Fragment, useEffect, useState } from 'react';
import DeleteForm from './DeleteForm';
import CreateForm from './CreateForm';
import Home from '../../SharedComponents/Svg/Home';
import Eye from '../../SharedComponents/Svg/Eye';
import Loading from '../../SharedComponents/Loading';
import { getForms, getLanguages } from './ApiForms';
import { DateTime } from 'luxon';
import ChevronLeft from '../../SharedComponents/Svg/ChevronLeft';
import ChevronRight from '../../SharedComponents/Svg/ChevronRight';
import EditForm from './EditForm';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TablePagination from '../../SharedComponents/TablePagination';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import TableSearch from '../../SharedComponents/TableSearch';
import i18next from 'i18next';
import ToggleFormStatus from './ToggleFormStatus';
import LanguageList from '../../SharedComponents/LanguageList';
export default function Forms() {
  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLanguage, setSearchlanguage] = useState(0);
  const onSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    setSearchlanguage(languageId);
  };
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  let initialState = {
    pageNumber: pageNumber,
    pageSize: entries,
    isPaging: true,
    langId: 0,
    isSearch: searchTerm ? true : false,
    searchFilter: {
      langId: searchLanguage,
      term: searchTerm,
    },
  };
  const { data, paginationResult, isDone, isLoading, isError, refetch } = useQuery(['forms', initialState], getForms, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
  let paginationCount = [];

  useEffect(() => {
    refetch();
  }, [entries, paginationResult]);
  if (isLoading) return <Loading />;
  if (isError) return 'error';

  for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
    paginationCount.push(i);
  }

  // to control how many rows to show in table

  const onChangeEntries = (e) => {
    setEntries(e.target.value);
  };

  const tabltHeader = [
    { title: t('title') },
    { title: t('Creation Date') },
    { title: t('Created By') },
    { title: t('Active') },
    { title: t('options') },
    { title: t('Answers'), className: 'w-64' },
  ];
  return (
    <>
      <header className="flex justify-between items-center">
        <BreadCrumbs to="forms" name={t('Forms')} />
        <div>
          <CreateForm languages={languages} />
        </div>
      </header>
      <div className="my-6">
        <h2 className="text-2xl font-bold">{t('Forms')}</h2>
      </div>
      {/* Table */}
      <div className="bg-white rounded border shadow-md ">
        <div className="flex justify-between relative items-center mb-4 p-6">
          <TableSearch onChangeEntries={onChangeEntries} entries={entries} onSearch={onSearch} />

          <div className="flex transform  relative z-10 ">
            {data && languages && (
              <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
            )}
          </div>
          <hr className="border w-full absolute top-0 left-0 z-0 mt-16" />
        </div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y-2 divide-gray-200">
                  <thead className=" text-sm font-bold text-black">
                    <tr>
                      {tabltHeader.map((e) => {
                        return (
                          <th
                            key={e.title}
                            scope="col"
                            className={`px-6 py-3 ${
                              i18n.language === 'en' ? 'text-left' : 'text-right'
                            }    uppercase  ${e.className}`}
                          >
                            {e.title}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
                    {data.data.map((form) => {
                      return (
                        <tr key={form.id} className="">
                          <td className="px-6 py-4 whitespace-nowrap">
                            {form.frmFormLngs.map((e) => {
                              return (
                                <Fragment key={e.id}>
                                  {languageId === e.languageId && (
                                    <>
                                      <div className="flex items-center space-x-2">
                                        <b>{e.title}</b>
                                      </div>
                                    </>
                                  )}
                                </Fragment>
                              );
                            })}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {DateTime.fromISO(form.createDate).toLocaleString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">{form.createdByName}</td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <ToggleFormStatus statusId={form.statusId} id={form.id} />
                          </td>
                          <td
                            className={`px-6 py-4 whitespace-nowrap flex items-center space-x-2 ${
                              i18n.language === 'en' ? '' : 'space-x-reverse'
                            }`}
                          >
                            <Link
                              to={`forms/${form.id}`}
                              className="bg-orange hover:bg-orangehover transition duration-500 text-white rounded p-1"
                            >
                              <Eye className="w-5 h-5" />
                            </Link>
                            <EditForm form={form} />
                            <DeleteForm id={form.id} />
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <Link
                              to={`/formAnswer/${form.id}`}
                              className=" flex items-center justify-center  bg-blue-400 rounded   text-white w-max px-2 h-9 text-center text-sm"
                            >
                              <p>{t('Show Requests')}</p>
                            </Link>
                          </td>

                          {/* <td className='px-6 py-4 whitespace-nowrap flex items-center space-x-2'>
                            <a
                              href={`./pages/${page.id}`}
                              className='bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out'
                            >
                              <PecnilAlt className='w-5 h-5' />
                            </a>
                            <DeleteForm id={page.id} />
                          </td> */}
                        </tr>
                      );
                    })}
                    {/* {data &&
                      data.data &&
                      data?.data?.map((page) => {
                        return (
                          <tr key={page.id} className=''>
                            <td className='px-6 py-4 whitespace-nowrap'>
                              <div className=' '>
                                {page.pgPageLngs[0]?.title}
                              </div>
                            </td>
                            <td className='px-6 py-4 whitespace-nowrap flex items-center space-x-2'>
                        
                              <a
                                href={`./pages/${page.id}`}
                                className='bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out'
                              >
                                <PecnilAlt className='w-5 h-5' />
                              </a>
                              <DeleteForm id={page.id} />
                            </td>
                          </tr>
                        );
                      })} */}
                  </tbody>
                </table>

                {/* pagination */}
                <TablePagination
                  data={data}
                  paginationCount={paginationCount}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
