import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Loading from '../../SharedComponents/Loading';
import { GetVisitorData } from './ApRequests';
import Barcode from './Barcode';
import PrintUserCard from './PrintUserCard';

export default function FormUSerData(props) {
  const { requestId, purposeOfRequest } = useParams();
  const body = {
    requestDataId: requestId,
    question1: 1030,
    question2: 1029,
  };
  const { t } = useTranslation();
  const { data, isLoading, isError } = useQuery([`GetVisitorData`, body], GetVisitorData);
  if (isLoading)
    return (
      <div
        className="relative bg-white"
        style={{
          minHeight: '500px',
        }}
      >
        <Loading />
      </div>
    );
  if (isError) return 'error';
  console.log('data', data);
  return (
    <>
      <div className="p-8">
        <span className="text-gray-500 mb-2 block">Visitor ID</span>
        <div className=" rounded border-black p-8 border-2">
          <div className="flex font-bold space-x-4 mb-2">
            <span>System ID:</span> <span>{data.data.systemId}</span>
          </div>
          <div className="flex font-bold space-x-4 mb-2">
            <span>Arabic Name:</span> <span>{data.data.arabicName}</span>
          </div>
          <div className="flex font-bold space-x-4 mb-2">
            <span>English Name:</span> <span>{data.data.englishName}</span>
          </div>
          <div>
            <span className=" font-bold mb-4 block">ID Number:</span>
            <div>
              <Barcode value={data.data.systemId} />
            </div>
          </div>
        </div>

        <div className="flex space-x-4 mt-4">
          <Link
            className="bg-move text-white rounded-md py-2 px-8"
            to={'/answers/1089/New Visitors/' + purposeOfRequest}
          >
            {t('Finish')}
          </Link>
          <PrintUserCard data={data.data} />
        </div>
      </div>
    </>
  );
}
