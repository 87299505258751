import axios from "axios";

export const deleteSlider = async (e) => {
  const { data } = await axios.post("/PgSection/DeleteSliderItem", e);
  return data;
};

export const editSlider = async (e) => {
  const { data } = await axios.post("/PgSection/EditSlider", e);
  return data;
};

export const addSliderItem = async (e) => {
  const { data } = await axios.post("/PgSection/AddSliderItem", e);
  return data;
};

export const editSliderItem = async (e) => {
  const { data } = await axios.post("/PgSection/EditSliderItem", e);
  return data;
};
