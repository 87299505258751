import axios from 'axios';

export const addCollapse = async (e) => {
  const { data } = await axios.post('/PgSection/AddCollapse', e);
  return data;
};

export const editCollapse = async (e) => {
  const { data } = await axios.post('/PgSection/EditCollapse', e);
  return data;
};

export const deleteCollapse = async (e) => {
  const { data } = await axios.post('/PgSection/DeleteCollapse', e);
  return data;
};

export const addCollapseItems = async (e) => {
  const { data } = await axios.post('/PgSection/AddCollapseItems', e);
  return data;
};

export const editCollapseItems = async (e) => {
  const { data } = await axios.post('/PgSection/EditCollapseItems', e);
  return data;
};

export const deleteCollapseItems = async (e) => {
  const { data } = await axios.post('/PgSection/DeleteCollapseItems', e);
  return data;
};
