import { useQuery } from 'react-query';
import { getFormQuestionOption } from '../Forms/ApiForms';
import DropDown from './Components/DropDown';

export default function EmployeeStatusList(props) {
  const { values, errors, setFieldValue, title, placeholder, dropData } = props;
  let employeeStatusListQueryinitialState = {
    typeId: 1016,
    paginationFilter: {
      pageNumber: 1,
      pageSize: 99999,
      isPaging: false,
      langId: 0,
    },
  };

  const employeeStatusListQuery = useQuery(
    ['formQuestionOption', employeeStatusListQueryinitialState],
    getFormQuestionOption,
    {
      staleTime: 99900,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );
  return (
    <>
      {employeeStatusListQuery.isLoading && (
        <>
          <label className="relative col-span-2 lg:col-span-1 mb-4">
            <div className="block mb-2 py-4 w-1/2 text-lg font-semibold bg-white animate-pulse"></div>
            <div className="px-4 py-6 w-full bg-white rounded-md outline-none animate-pulse"></div>
          </label>
        </>
      )}
      {employeeStatusListQuery.data && (
        <DropDown
          title={title}
          placeholder={placeholder}
          list={employeeStatusListQuery.data.data}
          loading={employeeStatusListQuery.loading}
          error={employeeStatusListQuery.error}
          values={values}
          errors={errors}
          disabled={false}
          setFieldValue={setFieldValue}
          FieldValue={values.employeestatus}
          FieldName="employeestatus"
          dropData={dropData}
        />
      )}
    </>
  );
}
